import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as MapActions from './map.actions';
import { mapStateKey } from '@sidkik/global';
import { MapProperties } from '@sidkik/db';

export const mapsFeatureKey = mapStateKey;

export interface State extends EntityState<MapProperties> {
  selectedId?: string | number;
  loaded: boolean;
  loading: boolean;
  error?: string | null;
}

export const adapter: EntityAdapter<MapProperties> =
  createEntityAdapter<MapProperties>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
});

export const reducer = createReducer(
  initialState,
  on(MapActions.loadMapsSuccess, (state, action) =>
    adapter.setAll(action.maps, { ...state, loaded: true, loading: false })
  ),
  on(MapActions.loadMapsAndSelectMapSuccess, (state, action) =>
    adapter.setAll(action.maps, {
      ...state,
      loaded: true,
      loading: false,
      selectedId: action.id,
    })
  ),
  on(MapActions.loadMapsFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error,
  })),
  on(MapActions.loadMaps, (state) => ({
    ...state,
    loaded: false,
    loading: true,
  })),
  on(MapActions.selectMap, (state, { id }) => ({
    ...state,
    selectedId: id,
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
