import { TimeSlot } from '@sidkik/global';

export enum DayOfWeek {
  Sunday = 'sun',
  Monday = 'mon',
  Tuesday = 'tue',
  Wednesday = 'wed',
  Thursday = 'thu',
  Friday = 'fri',
  Saturday = 'sat',
}

export enum BookingStatus {
  Completed = 'completed',
  Cancelled = 'cancelled',
  Scheduled = 'scheduled',
  RequiresScheduling = 'requiresScheduling',
}

export enum BookingChangeStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
  Errored = 'errored',
  Requested = 'requested',
}

export enum BookingPaymentStatus {
  Paid = 'paid',
  Scheduled = 'scheduled',
  RequiresScheduling = 'requiresScheduling',
}

export enum BookingChangeType {
  Reschedule = 'reschedule',
  Cancel = 'cancel',
  Schedule = 'schedule',
  ClearSchedule = 'clearSchedule',
  Add = 'add',
}

export interface GeneralAvailabilityDayOfWeek {
  id: string;
  productId?: string[];
  [DayOfWeek.Sunday]: TimeSlot[];
  [DayOfWeek.Monday]: TimeSlot[];
  [DayOfWeek.Tuesday]: TimeSlot[];
  [DayOfWeek.Wednesday]: TimeSlot[];
  [DayOfWeek.Thursday]: TimeSlot[];
  [DayOfWeek.Friday]: TimeSlot[];
  [DayOfWeek.Saturday]: TimeSlot[];
}

export interface AvailabilityOverrideDate {
  date: string;
  timeSlots: TimeSlot[];
  productId?: string[];
}

export interface TemporaryUnavailabilityRecurring {
  start: number;
  end: number;
  dayOfWeek: number;
  timeSlots: TimeSlot[];
  productId?: string[];
}

export interface OutOfOfficePeriod {
  start: number;
  end: number;
}

export interface BookingVirtualLocation {
  vendor: string;
  joinUrl: string;
  password: string;
  startUrl: string;
  vendorMeetingId: string;
  vendorMeetingUuid: string;
}

export interface MinimizedBookingVirtualLocation {
  vendor: string;
  joinUrl: string;
  password: string;
  vendorMeetingId: string;
}

export interface BookingPhysicalLocation {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}
