import { Injectable } from '@angular/core';
import { LibraryError, SKUError } from '@sidkik/global';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private notificationService: NotificationService) {}

  handleError(err: Error | any) {
    switch (err.name) {
      case 'FirebaseError':
        this.handleFirebaseError(err);
        break;
      case 'LibraryError': {
        this.handleLibraryError(err);
        break;
      }
      case 'ShopError': {
        this.handleShopError(err);
        break;
      }
      case 'SKUError': {
        this.handleSKUError(err);
      }
    }
  }

  private handleLibraryError(err: LibraryError) {
    this.notificationService.showError(
      err.title ?? 'Library Issue',
      err.subTitle ?? err.message
    );
  }

  private handleShopError(err: LibraryError) {
    this.notificationService.showError(
      err.title ?? 'Unable to process order',
      err.subTitle ?? err.message
    );
  }

  private handleSKUError(err: SKUError) {
    this.notificationService.showError(
      err.title ?? 'Price Issue',
      err.subTitle ?? err.message
    );
  }

  private handleFirebaseError(err: Error) {
    const title = 'Authentication Issue';
    let subTitle = '';
    switch (true) {
      case err.message.includes('EXPIRED_OOB_CODE'): {
        subTitle =
          'Your reset password link has expired. Please use forgot password to reset again.';
        break;
      }
      case err.message.includes('auth/user-not-found'): {
        subTitle =
          'We could not find user account associated with the email address or phone number.';
        break;
      }
      case err.message.includes('auth/invalid-action-code'):
        subTitle =
          'Your password reset link is no longer valid. Please request a new one.';
        break;
      case err.message.includes('auth/wrong-password'):
        subTitle = 'Invalid login credentials.';
        break;
      case err.message.includes('auth/network-request-failed'):
        subTitle = 'Please check your internet connection';
        break;
      case err.message.includes('auth/too-many-requests'):
        subTitle =
          'We have detected too many requests from your device. Take a break please!';
        break;
      case err.message.includes('auth/user-disabled'):
        subTitle =
          'Your account has been disabled. Please contact us if you need assistance.';
        break;
      case err.message.includes('auth/requires-recent-login'):
        subTitle = 'Please login again and try again!';
        break;
      case err.message.includes('auth/email-already-exists'):
        subTitle = 'Email address is already in use by an existing user.';
        break;
      case err.message.includes('auth/email-already-in-use'):
        subTitle = 'Email address is already in use. Please login instead.';
        break;
      case err.message.includes('auth/phone-number-already-exists'):
        subTitle = 'The phone number is already in use by an existing user.';
        break;
      case err.message.includes('auth/invalid-phone-number'):
        subTitle = 'The phone number is not a valid phone number!';
        break;
      case err.message.includes('auth/invalid-email'):
        subTitle = 'The email address is not a valid email address!';
        break;
      case err.message.includes('auth/cannot-delete-own-user-account'):
        subTitle = 'You cannot delete your own user account.';
        break;
      default:
        subTitle = 'Oops! Something went wrong. Try again later.';
        break;
    }
    this.notificationService.showError(title, subTitle);
  }
}
