export const ErrorMustReAuthToQuickbooks = 3000;
export const ErrorQuickbooksDisabled = 3400;

export class DefaultError extends Error {
  public title: string | undefined;
  public subTitle: string | undefined;
  public doc: any;
  public code?: number;

  constructor(message: string, title?: string, subTitle?: string) {
    super(message);
    this.title = title;
    this.subTitle = subTitle;
    this.name = 'DefaultError';
  }

  toJSON() {
    return {
      message: this.message,
      name: this.name,
      code: this.code,
      doc: this.doc,
      subTitle: this.subTitle,
      title: this.title,
    };
  }
}

export class LibraryError extends DefaultError {
  constructor(message: string, title?: string, subTitle?: string) {
    super(message, title, subTitle);
    this.name = 'LibraryError';
  }
}

export class ShopError extends DefaultError {
  constructor(message: string, title?: string, subTitle?: string) {
    super(message, title, subTitle);
    this.name = 'ShopError';
  }
}

export class MyActivitiesError extends DefaultError {
  constructor(message: string, title?: string, subTitle?: string) {
    super(message, title, subTitle);
    this.name = 'MyActivitiesError';
  }
}

export class SKUError extends DefaultError {
  constructor(message: string, title?: string, subTitle?: string) {
    super(message, title, subTitle);
    this.name = 'SKUError';
  }
}

export class QuickbooksError extends DefaultError {
  constructor(message: string, title?: string, subTitle?: string) {
    super(message, title, subTitle);
    this.name = 'QuickbooksError';
  }
}
