import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  SlideOverPanel,
  SlideOverPanelDefaultOptions,
} from './slide-over-panel';

export const defaultOptions: SlideOverPanelDefaultOptions = {
  overlapTrigger: false,
  backdropClass: 'cdk-overlay-transparent-backdrop',
  hasBackdrop: false,
};

const soAnimation = trigger('slideoverpanel', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('200ms ease-in-out', style({ transform: 'translateX(0)' })),
  ]),
  transition(':leave', [
    style({ transform: 'translateX(0)' }),
    animate('200ms ease-in-out', style({ transform: 'translateX(100%)' })),
  ]),
]);

@Component({
  selector: 'sidkik-slide-over',
  templateUrl: './slide-over.component.html',
  animations: [soAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideOverComponent implements SlideOverPanel {
  changed!: EventEmitter<any>;
  overlayPanelClass?: string | string[] | undefined;
  @Input() overlapTrigger = defaultOptions.overlapTrigger;
  @Input() backdropClass = defaultOptions.backdropClass;
  @Input() hasBackdrop = defaultOptions.hasBackdrop;
  @Input() class = '';

  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();
  // @Output() openMe = new EventEmitter<void>();

  // openSlideOver() {
  //   this.openMe.emit();
  // }
}
