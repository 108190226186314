import { Meta } from '../meta';
import { Storage, StorageProperties } from '../storage';
import { ChallengeEvent, ChallengeEventProperties } from './challenge-event';
import { EntityType } from '@sidkik/global';

export interface ChallengeProgressData {
  challenge?: string; // id of challenge
  what?: string;
  description?: string;
  subType?: string;
  events?: ChallengeEventProperties[];
  start: number;
  abandoned?: boolean;
}

export interface ChallengeProgressProperties extends StorageProperties {
  data: ChallengeProgressData;
  meta: Meta;
  id: string;
}

export class ChallengeProgress
  extends Storage
  implements ChallengeProgressProperties
{
  public override data!: ChallengeProgressData;

  constructor(options?: ChallengeProgressProperties, user?: string) {
    super(options, user, EntityType.ChallengeProgress);
    this.update(options?.data);
  }

  public update(data?: ChallengeProgressData): void {
    const {
      start = new Date().getTime(),
      challenge,
      events = [],
      abandoned,
      subType,
      what,
      description,
    } = data || {};
    this.data.start = start;
    this.data.subType = subType;
    this.data.challenge = challenge;
    this.data.events = events;
    this.data.abandoned = abandoned;
    this.data.description = description;
    this.data.what = what;
  }
}
