import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { equalityValidator, passwordComplexity } from '@sidkik/global';
import { ButtonState } from '@sidkik/ui';
import { AuthProvider } from '../../services/auth-process.service';

interface PasswordResetForm {
  passwordConfirm: FormControl<string>;
  password: FormControl<string>;
}

export interface PasswordResetAttemptEvent {
  password: string;
}

@Component({
  selector: 'sidkik-pure-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PurePasswordResetComponent implements OnInit {
  @Input() logoUrl: string | undefined = '';
  @Input() state: ButtonState | null = ButtonState.ready;

  @Output() resetClicked: EventEmitter<PasswordResetAttemptEvent> =
    new EventEmitter<PasswordResetAttemptEvent>();

  resetForm!: FormGroup<PasswordResetForm>;
  authProviders = AuthProvider;
  constructor(private formBuilder: FormBuilder) {}

  /**
   * reset handler for the password reset
   */
  reset() {
    this.resetForm.markAllAsTouched();
    if (!this.resetForm.valid) {
      return;
    }
    const event: PasswordResetAttemptEvent = {
      password: this.resetForm.controls.password.value,
    };
    this.resetClicked.emit(event);
  }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.nonNullable.group(
      {
        passwordConfirm: ['', [Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(passwordComplexity.min),
            Validators.maxLength(passwordComplexity.max),
          ],
        ],
      },
      {
        validators: [equalityValidator('password', 'passwordConfirm')],
      }
    );
  }
}
