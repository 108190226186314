import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import {
  APP_CHECK_DEBUG_TOKEN,
  APP_CONFIG,
  AppConfig,
  EntityType,
  SIDKIK_TENANT,
  firestoreEmulatorURL,
  getPath,
} from '@sidkik/global';
import {
  FirebaseApp,
  FirebaseOptions,
  getApp,
  initializeApp,
} from 'firebase/app';
import {
  CustomProvider,
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
} from 'firebase/app-check';
import {
  Firestore,
  FirestoreSettings,
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getDocs,
  initializeFirestore,
  query,
  where,
} from 'firebase/firestore';

// eslint-disable-next-line no-var
// declare var window: any;
const window = {
  // define window and localStorage so firebase auth will not error when checking if window and storage avaiable
  // firebase auth doesn't use localstorage - it uses indexeddb, so the object doesn't matter
  localStorage: { removeItem: () => true, setItem: () => true },
  // ot uses window add listener - probably for on close
  addEventListener: () => true,
};

@Injectable({
  providedIn: 'root',
})
export class FallbackDbWorkerService {
  private fs!: Firestore;
  constructor(
    @Inject(APP_CONFIG) appConfig: AppConfig,
    @Inject('APP_VERSION') appVersion: string,
    @Inject(APP_CHECK_DEBUG_TOKEN) appCheckDebugToken: boolean,
    @Inject(PLATFORM_ID) private platformId: any,
    private zone: NgZone,
    @Inject(SIDKIK_TENANT) private readonly tenantId: string
  ) {
    if (isPlatformServer(platformId) || typeof Worker == 'undefined') {
      let app: FirebaseApp;
      try {
        app = getApp(appConfig.firebase.projectId);
      } catch (e) {
        const epCoreProd: FirebaseOptions = {
          apiKey: 'AIzaSyCiaPZrnQn-j4eqqeEUvW6tNt5zixK5LdI',
          projectId: 'ep-core-prod',
        };
        initializeApp(epCoreProd);
        const smallerConfig: FirebaseOptions = {
          projectId: appConfig.firebase.projectId,
          appId: appConfig.firebase.appId,
          apiKey: appConfig.firebase.apiKey,
          // databaseURL: 'https://' + appConfig.firebase.projectId,
        };
        app = initializeApp(smallerConfig, appConfig.firebase.projectId);
        if (appConfig.recaptcha) {
          (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = appCheckDebugToken;
          const provider = new ReCaptchaEnterpriseProvider(
            appConfig.recaptcha ?? ''
          );
          initializeAppCheck(app, {
            provider,
            isTokenAutoRefreshEnabled: false,
          });
        }
      }

      let options: FirestoreSettings = {
        experimentalAutoDetectLongPolling: false,
        localCache: undefined,
        // host: firestoreEmulatorURL.hostname + ':' + firestoreEmulatorURL.port,
      };
      this.fs = initializeFirestore(app, options);

      if (appConfig.emulator) {
        // eslint-disable-next-line no-restricted-syntax
        logger.debug(
          'db:fallback-db-worker.service',
          `FALLBACK DB WEB WORKER: connecting firestore emulator at ${firestoreEmulatorURL.toString()} options:`,
          appConfig.firebase
        );

        const host = (this.fs as any)['_settings']?.host;

        const isAlreadyEmulator =
          host?.indexOf(firestoreEmulatorURL.hostname) !== -1;
        // can try to reload and fail
        if (!isAlreadyEmulator) {
          connectFirestoreEmulator(
            this.fs,
            firestoreEmulatorURL.hostname,
            +firestoreEmulatorURL.port
          );
        }
      }
    }
  }

  async getAllRemote<T>(entityType: EntityType): Promise<T[]> {
    if (!this.fs) {
      return [] as T[];
    }

    return this.zone.runOutsideAngular(async () => {
      const snapshot = await getDocs(
        query(collection(this.fs, getPath(this.tenantId, entityType)))
      );
      const entities = snapshot.docs.map((d) => d.data());
      return entities as T[];
    });
  }

  async getAllNestedRemote<T>(
    entityType: EntityType,
    parentEntityType: EntityType,
    parentEntityId: string
  ) {
    if (!this.fs) {
      return [] as T[];
    }
    return this.zone.runOutsideAngular(async () => {
      const snapshot = await getDocs(
        query(
          collection(
            this.fs,
            getPath(this.tenantId, entityType, parentEntityType, parentEntityId)
          )
        )
      );
      const entities = snapshot.docs.map((d) => d.data());
      return entities as T[];
    });
  }

  async getRemote<T>(entityType: EntityType, id: string) {
    if (!this.fs) {
      return;
    }
    return this.zone.runOutsideAngular(async () => {
      const snapshot = await getDoc(
        doc(
          this.fs,
          `${getPath(this.tenantId, entityType, undefined, undefined)}/${id}`
        )
      );
      if (!snapshot.exists()) {
        return;
      }
      const entity = snapshot.data();
      // hack to add id if doc doesnt have it
      if (entity && !entity['id']) {
        entity['id'] = id;
      }

      return entity as T;
    });
  }

  async getNestedRemote<T>(
    entityType: EntityType,
    id: string,
    parentEntityType: EntityType,
    parentEntityId: string
  ) {
    if (!this.fs) {
      return;
    }
    return this.zone.runOutsideAngular(async () => {
      const snapshot = await getDoc(
        doc(
          this.fs,
          `${getPath(
            this.tenantId,
            entityType,
            parentEntityType,
            parentEntityId
          )}/${id}`
        )
      );
      if (!snapshot.exists()) {
        return;
      }
      const entity = snapshot.data();
      // hack to add id if doc doesnt have it
      if (entity && !entity['id']) {
        entity['id'] = id;
      }
      return entity as T;
    });
  }
}
