export const SafetyAttributes: { [key: string]: string } = {
  Toxic: 'Content that is rude, disrespectful, or unreasonable.',
  Derogatory:
    'Negative or harmful comments targeting identity and/or protected attributes.',
  Violent:
    'Describes scenarios depicting violence against an individual or group, or general descriptions of gore.',
  Sexual: 'Contains references to sexual acts or other lewd content.',
  Insult:
    'Insulting, inflammatory, or negative comment towards a person or a group of people.',
  Profanity: 'Obscene or vulgar language such as cursing.',
  DeathHarmAndTragedy:
    'Human deaths, tragedies, accidents, disasters, and self-harm.',
  FirearmsAndWeapons:
    'Content that mentions knives, guns, personal weapons, and accessories such as ammunition, holsters, etc.',
  PublicSafety: 'Public Safety',
  Health:
    'Human health, including: Health conditions, diseases, and disorders Medical therapies, medication, vaccination, medical practices, and resources for healing, including support groups.',
  ReligionAndBelief:
    'Belief systems that deal with the possibility of supernatural laws and beings; religion, faith, belief, spiritual practice, churches, and places of worship. Includes astrology and the occult.',
  IllicitDrugs:
    'Recreational and illicit drugs; drug paraphernalia and cultivation, headshops, etc. Includes medicinal use of drugs typically used recreationally (e.g. marijuana).',
  WarAndConflict:
    'War, military conflicts, and major physical conflicts involving large numbers of people. Includes discussion of military services, even if not directly related to a war or conflict.',
  Finance:
    'Consumer and business financial services, such as banking, loans, credit, investing, and insurance.',
  Politics:
    'Political news and media; discussions of social, governmental, and public policy.',
  Legal:
    'Law-related content, including law firms, legal information, primary legal materials, paralegal services, legal publications and technology, expert witnesses, litigation consultants, and other legal service providers.',
};

export const SafetyAttributesKeyToName: { [key: string]: string } = {
  toxic: 'Toxic',
  derogatory: 'Derogatory',
  violent: 'Violent',
  sexual: 'Sexual',
  insult: 'Insult',
  profanity: 'Profanity',
  deathHarmAndTragedy: 'Death, Harm, and Tragedy',
  firearmsAndWeapons: 'Firearms and Weapons',
  publicSafety: 'Public Safety',
  health: 'Health',
  religionAndBelief: 'Religion and Belief',
  illicitDrugs: 'Illicit Drugs',
  warAndConflict: 'War and Conflict',
  finance: 'Finance',
  politics: 'Politics',
  legal: 'Legal',
};
