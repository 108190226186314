import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { IdTokenResult, User } from '@angular/fire/auth';
import { User as serializableUser } from './authz.models';

import * as AuthzActions from './authz.actions';
import * as AuthzFeature from './authz.reducer';
import * as AuthzSelectors from './authz.selectors';
import { DbService } from '@sidkik/db';
import { filter, take } from 'rxjs';

// @Injectable({
//   providedIn: 'root',
// })
@Injectable()
export class AuthzFacade {
  loggedIn$ = this.store.pipe(select(AuthzSelectors.getLoggedIn));
  jwt$ = this.store.pipe(select(AuthzSelectors.getJwt));
  level$ = this.store.pipe(select(AuthzSelectors.getLevel));
  role$ = this.store.pipe(select(AuthzSelectors.getRole));
  user$ = this.store.pipe(select(AuthzSelectors.getUser));
  userId$ = this.store.pipe(select(AuthzSelectors.getUserId));
  permissions$ = this.store.pipe(select(AuthzSelectors.getPermissions));
  me$ = this.store.pipe(select(AuthzSelectors.getMe));
  meLoading$ = this.store.pipe(select(AuthzSelectors.getMeLoading));
  profilePhotoUrl$ = this.store.pipe(select(AuthzSelectors.getProfilePhotoUrl));

  constructor(private readonly store: Store, private dbService: DbService) {
  }

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  public init() {
    // this.store.dispatch(AuthzActions.initAuthz());
  }

  loginSuccess(user: User, idToken: IdTokenResult, navigate = true) {
    this.dbService.setUser(this.getUserFromToken(idToken));
    // idtoken and user are objects that need to be changed to serializable for ngrx
    this.store.dispatch(
      AuthzActions.loginSuccess({
        user: user.toJSON() as serializableUser,
        idToken,
        navigate,
      })
    );
  }

  getUserFromToken(idToken: IdTokenResult) {
    if (!idToken) return 'nobody';
    const { claims } = idToken;
    if (!claims) return 'nobody';
    if (!claims['c_id']) return 'nobody';
    return idToken?.claims['c_id']?.toString() ?? '';
  }

  tokenRefreshed(user: User, idToken: IdTokenResult) {
    this.dbService.setUser(this.getUserFromToken(idToken));
    // idtoken and user are objects that need to be changed to serializable for ngrx
    this.store.dispatch(
      AuthzActions.reloadTokenSuccess({
        user: user.toJSON() as serializableUser,
        idToken,
      })
    );
  }

  async tidyOnSignout() {
    await this.dbService.tidyOnSignout();
  }

  loadMe(attempt?: number) {
    this.loggedIn$
      .pipe(
        filter((loggedIn) => loggedIn === true),
        take(1)
      )
      .subscribe((loggedIn) => {
        this.meLoading$.pipe(take(1)).subscribe((loading) => {
          if (loading) {
            return;
          }
          attempt = attempt ? attempt : 1;
          this.store.dispatch(AuthzActions.loadMe({ attempt }));
        });
      });
  }

  logoutSuccess() {
    this.store.dispatch(AuthzActions.logoutSuccess());
  }
}
