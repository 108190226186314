import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

export enum ButtonSize {
  medium = 'medium',
  small = 'small',
  large = 'large',
}

export enum ButtonState {
  ready = 'ready',
  processing = 'processing',
  errored = 'errored',
  succeeded = 'succeeded',
}

export const defaultButtonStateSuccessTimeout = 1000;
export const defaultButtonStateErrorTimeout = 3000;

@Component({
  selector: 'sidkik-process-button',
  templateUrl: './process-button.component.html',
  styleUrls: ['./process-button.component.scss'],
  // host: {
  //   class: 'w-full',
  // },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessButtonComponent {
  @Input() fullWidth = false;
  @Input() size = ButtonSize.medium;
  @Input() state: ButtonState | null = ButtonState.ready;
  @Input() processingText = 'Processing...';
  @Input() errorText = 'Error';
  @Input() successText = 'Success';

  states = ButtonState;
}
