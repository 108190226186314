import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ButtonState } from '@sidkik/ui';
import { AuthProvider } from '../../services/auth-process.service';

interface RegisterForm {
  name: FormControl<string>;
  email: FormControl<string>;
  password: FormControl<string>;
  passwordConfirm: FormControl<string>;
}

export interface RegisterAttemptEvent {
  name: string;
  email: string;
  password: string;
}

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};

@Component({
  selector: 'sidkik-pure-register',
  templateUrl: './register.component.html',
})
export class PureRegisterComponent implements OnInit {
  @Input() logoUrl: string | undefined = '';
  @Input() state: ButtonState | null = ButtonState.ready;
  @Input() allowThirdPartyProviders: boolean | null = false;
  // @Input() providers: AuthProvider[] | AuthProvider = AuthProvider.ALL; //  google, facebook, twitter, github as array or all as one single string
  @Input() registrationEnabled = true;
  @Input() messageOnAuthSuccess = '';
  @Input() messageOnAuthError = '';
  @Input() source = '';

  @Output() registerClicked: EventEmitter<RegisterAttemptEvent> =
    new EventEmitter<RegisterAttemptEvent>();

  registerForm!: FormGroup<RegisterForm>;
  authProviders = AuthProvider;
  constructor(private formBuilder: FormBuilder) {}

  /**
   * register handler for the email authentication
   */
  register() {
    this.registerForm.markAllAsTouched();
    if (!this.registerForm.valid) {
      return;
    }
    const event: RegisterAttemptEvent = {
      name: this.registerForm.controls.name.value,
      email: this.registerForm.controls.email.value,
      password: this.registerForm.controls.password.value,
    };
    this.registerClicked.emit(event);
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.nonNullable.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(26),
        ],
      ],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
    });
  }
}
