import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTHZ_FEATURE_KEY, AuthzState } from './authz.reducer';

export const getAuthzState =
  createFeatureSelector<AuthzState>(AUTHZ_FEATURE_KEY);

export const getLoggedIn = createSelector(
  getAuthzState,
  (state: AuthzState) => state.loggedIn
);

export const getUser = createSelector(
  getAuthzState,
  (state: AuthzState) => state.user
);

export const getIdToken = createSelector(
  getAuthzState,
  (state: AuthzState) => state.idToken
);

export const getPermissions = createSelector(
  getAuthzState,
  (state: AuthzState) => state.permissions
);

export const getMe = createSelector(getAuthzState, (state: AuthzState) =>
  state.meLoaded ? state.me : undefined
);

export const getRole = createSelector(getIdToken, (idToken) =>
  idToken ? (idToken.claims['r'] as string).split(',') : []
);

export const getLevel = createSelector(getIdToken, (idToken) =>
  idToken ? idToken.claims['l'] : -1
);

export const getJwt = createSelector(
  getAuthzState,
  (state: AuthzState) => state.idToken?.token
);

export const getProfilePhotoUrl = createSelector(
  getAuthzState,
  (state: AuthzState) => state.user?.photoURL
);

export const getUserId = createSelector(
  getAuthzState,
  (state: AuthzState) => state.userId
);

export const getAffiliateId = createSelector(
  getAuthzState,
  (state: AuthzState) => state.affiliateId
);

export const getMeLoading = createSelector(
  getAuthzState,
  (state: AuthzState) => state.meLoading
);
