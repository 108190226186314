import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { Integrations } from '../integrations';
import { EntityType } from '@sidkik/global';

export enum SubscriptionChangeType {
  Cancel = 'cancel',
  Reinstate = 'reinstate',
  Pause = 'pause',
  Resume = 'resume',
}

export enum SubscriptionChangeStatus {
  Requested = 'requested',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Errored = 'errored',
}

export interface SubscriptionChangeData {
  customerId: string;
  subscriptionId: string;
  changeType: SubscriptionChangeType;
  status: SubscriptionChangeStatus;
  issue?: string;
}

export interface SubscriptionChangeProperties extends StorageProperties {
  data: SubscriptionChangeData;
  meta: Meta;
  id: string;
  integrations: Integrations;
}

export class SubscriptionChange
  extends Storage
  implements SubscriptionChangeProperties
{
  public override data!: SubscriptionChangeData;

  constructor(options?: SubscriptionChangeProperties, user?: string) {
    super(options, user, EntityType.SubscriptionChange);
    this.update(options?.data);
  }

  public update(data?: SubscriptionChangeData): void {
    this.data = { ...data } as unknown as SubscriptionChangeData;
  }
}

export class AdminSubscriptionChange
  extends Storage
  implements SubscriptionChangeProperties
{
  public override data!: SubscriptionChangeData;

  constructor(options?: SubscriptionChangeProperties, user?: string) {
    super(options, user, EntityType.AdminSubscriptionChange);
    this.update(options?.data);
  }

  public update(data?: SubscriptionChangeData): void {
    this.data = { ...data } as unknown as SubscriptionChangeData;
  }
}
