import { EntityType } from '../entities';

export const initializeDBType = '[db] initialize db';
export const initializeDBSuccessType = '[db] initialize db success';
export const initializeDBFailureType = '[db] initialize db failure';

export const monitorDocumentSuccessType = '[db] monitor document success';
export const monitorDocumentFailureType = '[db] monitor document failure';
export const workerLoggedInType = '[db] worker logged in';

export interface MonitorDocumentSuccess {
  entityType: EntityType;
  id: string;
  pathToMonitor: string;
  valuesToFind: any[];
  lastUpdate: number;
  parentEntityId?: string;
  parentEntityType?: EntityType;
  data: any;
}

export interface MonitorDocumentFailure {
  entityType: EntityType;
  id: string;
  pathToMonitor: string;
  valuesToFind: any[];
  lastUpdate: number;
  parentEntityId?: string;
  parentEntityType?: EntityType;
  error: any;
}
