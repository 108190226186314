import { AppCheckToken, CustomProviderOptions } from 'firebase/app-check';

const turnstileIFrameId = 'turnstile-check';
const turnstileClassName = 'cf-turnstile';

export class CloudflareProviderOptions implements CustomProviderOptions {
  private turnstileToken: string = '';
  private turnstileElement!: HTMLIFrameElement;
  private turnstileModalHolder!: HTMLElement;
  private tokenExpireTimeMillis: number = 0;
  private token: AppCheckToken | null = null;
  private ready = false;
  private refreshing: Promise<void> | null = null;
  private tokenResolve: any;
  private tokenExchangeUrl;
  private iFrameTurnstileUrl;

  constructor(projectId: string, env: string) {
    switch (env) {
      case 'local':
        this.iFrameTurnstileUrl = 'https://local-turnstile.sidkik.app';
        break;
      case 'test':
        this.iFrameTurnstileUrl = 'https://test-turnstile.sidkik.app';
        break;
      case 'prod':
        this.iFrameTurnstileUrl = 'https://turnstile.sidkik.app';
        break;
      default:
        this.iFrameTurnstileUrl = 'https://turnstile.sidkik.app';
    }

    this.tokenExchangeUrl = `https://us-central1-${projectId}.cloudfunctions.net/ext-cloudflare-turnstile-app-check-provider-tokenExchange`;
    this.refreshing = this.waitForToken();
    window.addEventListener('message', (event) => {
      if (event.origin !== this.iFrameTurnstileUrl) {
        return;
      }
      if (event.data.type === 'cf.turnstile.token') {
        logger.info(
          'turnstile:app-check',
          'received turnstile token',
          event.data.token
        );
        this.turnstileToken = event.data.token;
        if (event.data.token !== '' && this.turnstileToken !== undefined) {
          this.hideTurnstileForInteraction();
        }
        return this.tokenResolve(true);
      }
      if (event.data.type === 'cf.turnstile.ready') {
        if (localStorage.getItem('ROARR_LOG') === 'true') {
          this.sendMessage({ type: 'log.enabled' });
        }
        return;
      }
      if (event.data.type === 'cf.turnstile.interaction-required') {
        logger.info('turnstile:app-check', 'interaction required');
        return this.showTurnstileForInteraction();
      }
      if (event.data.type === 'cf.turnstile.interaction-completed') {
        logger.info('turnstile:app-check', 'interaction completed');
        return this.hideTurnstileForInteraction();
      }
    });
    this.injectTurnstile();
  }

  private injectTurnstile() {
    const body: HTMLElement = document.body;
    this.turnstileModalHolder = this.makeModalHolderForIFrame();
    this.hideModal();
    this.turnstileElement = this.makeIFrame();
    const card = this.makeCard();
    const title = this.makeCardTitle();
    const closeButton = this.makeCloseButton();
    card.appendChild(closeButton);
    card.appendChild(title);
    card.appendChild(this.turnstileElement);
    this.turnstileModalHolder.appendChild(card);
    logger.debug('turnstile:app-check', 'adding turnstileElement');
    body.appendChild(this.turnstileModalHolder);
  }

  private waitForToken(): Promise<void> {
    return new Promise((resolve) => {
      this.tokenResolve = resolve;
    });
  }

  private showTurnstileForInteraction() {
    this.showModal();
  }

  private hideTurnstileForInteraction() {
    this.hideModal();
  }

  showModal() {
    this.turnstileModalHolder.style.width = '100%';
    this.turnstileModalHolder.style.height = '100%';
    this.turnstileModalHolder.style.zIndex = '9999';
    this.turnstileModalHolder.style.overflow = 'auto';
  }

  hideModal() {
    this.turnstileModalHolder.style.width = '0';
    this.turnstileModalHolder.style.height = '0';
    this.turnstileModalHolder.style.zIndex = '0';
    this.turnstileModalHolder.style.overflow = 'hidden';
  }

  private makeModalHolderForIFrame() {
    const modalHolder = document.createElement('div');
    modalHolder.id = 'cf-turnstile-modal-holder';
    modalHolder.classList.add(
      'tw-flex',
      'tw-justify-center',
      'tw-items-center'
    );
    modalHolder.style.position = 'fixed';
    modalHolder.style.top = '0';
    modalHolder.style.left = '0';
    modalHolder.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    return modalHolder;
  }

  private makeCard() {
    const card = document.createElement('div');
    card.classList.add(
      'tw-relative',
      'tw-transform',
      'tw-overflow-hidden',
      'tw-rounded-lg',
      'tw-bg-white',
      'tw-px-4',
      'tw-pb-4',
      'tw-pt-5',
      'tw-text-left',
      'tw-shadow-xl',
      'tw-transition-all',
      'sm:tw-my-8',
      'sm:tw-w-full',
      'sm:tw-max-w-lg',
      'sm:tw-p-6'
    );
    return card;
  }

  private makeCardTitle() {
    const title = document.createElement('h1');
    title.classList.add('tw-text-lg', 'tw-font-bold');
    title.innerText = 'Quick check to ensure you are still active.';
    return title;
  }

  private makeCloseButton() {
    const closeButtonWrapper = document.createElement('div');
    closeButtonWrapper.classList.add(
      'tw-absolute',
      'tw-top-0',
      'tw-right-0',
      'tw-pr-4',
      'tw-pt-4',
      'sm:tw-block'
    );
    const closeButton = document.createElement('button');
    closeButton.classList.add(
      'tw-rounded-md',
      'tw-bg-white',
      'tw-text-gray-400',
      'hover:tw-text-gray-500',
      'focus:tw-outline-none',
      'focus:tw-ring-2',
      'focus:tw-ring-primary-500',
      'focus:tw-ring-offset-2'
    );
    closeButton.innerHTML = `<span class="tw-sr-only">Close</span><svg class="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>`;
    closeButton.addEventListener('click', () => {
      this.hideModal();
    });
    closeButtonWrapper.appendChild(closeButton);
    return closeButtonWrapper;
  }

  private makeIFrame() {
    const iframe = document.createElement('iframe');
    iframe.id = turnstileIFrameId;
    iframe.className = turnstileClassName;
    // add width and height to make sure it is visible and only this size of the content
    iframe.style.width = '350px';
    iframe.style.height = '90px';
    //  hide scrollbars
    iframe.style.overflow = 'hidden';
    iframe.style.border = 'none';
    iframe.src = this.iFrameTurnstileUrl ?? '';
    return iframe;
  }

  async getToken(): Promise<{
    readonly token: string;
    readonly expireTimeMillis: number;
  }> {
    return this.exchangeForAppCheckToken(true);
  }

  async getLimitedUseToken(): Promise<{
    readonly token: string;
    readonly expireTimeMillis: number;
  }> {
    return this.exchangeForAppCheckToken(true);
  }

  private async exchangeForAppCheckToken(
    limitedUse: boolean
  ): Promise<Readonly<AppCheckToken>> {
    logger.debug('turnstile:app-check', 'calling exchange', limitedUse);

    if (this.token !== null && this.tokenExpireTimeMillis > Date.now()) {
      logger.debug(
        'turnstile:app-check',
        'returning cached token, expires at',
        this.tokenExpireTimeMillis
      );
      return this.token;
    }

    logger.debug('turnstile:app-check', 'fetching token');

    if (this.ready) {
      this.refreshing = this.waitForToken();
      this.sendMessage({ type: 'cf.turnstile.refresh' });
    }
    logger.debug('turnstile:app-check', 'waiting for token');
    await this.refreshing;
    logger.debug('turnstile:app-check', 'token received');

    if (!this.ready) {
      logger.debug('turnstile:app-check', 'turnstile ready with first token');
      this.ready = true;
    }

    logger.debug(
      'turnstile:app-check',
      'exchanging token:',
      this.turnstileToken
    );

    if (this.turnstileToken === '' || !this.turnstileToken) {
      logger.error('turnstile:app-check', 'No Turnstile token');
      throw new Error('No Turnstile token');
    }

    const result = await fetch(this.tokenExchangeUrl, {
      method: 'POST',
      body: JSON.stringify({
        turnstileToken: this.turnstileToken,
        limitedUse,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const appCheckToken: AppCheckToken = await result.json();
    if (appCheckToken.token === '') {
      logger.error('turnstile:app-check', 'Invalid Turnstile token');
      throw new Error('Invalid Turnstile token');
    }

    this.token = appCheckToken;
    this.tokenExpireTimeMillis = appCheckToken.expireTimeMillis;

    // get the turnstile iframe and send message to reset
    this.sendMessage({
      type: 'cf.turnstile.reset',
    });

    logger.debug(
      'turnstile:app-check',
      'returning token, expires at',
      this.tokenExpireTimeMillis
    );

    return appCheckToken;
  }

  private sendMessage(message: any) {
    if (!!this.turnstileElement) {
      logger.info(
        'turnstile:app-check',
        'sending message to turnstile',
        message
      );
      return this.turnstileElement.contentWindow?.postMessage(
        message,
        this.iFrameTurnstileUrl ?? ''
      );
    }
    logger.error('turnstile:app-check', 'unable to send message to turnstile');
  }
}
