import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class FaviconService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public setFaviconByUrl(faviconUrl: string): void {
    if (!this.document) {
      return;
    }

    // requires favicon to have id of "favicon"
    const linkElement: HTMLLinkElement = this.document.getElementById(
      'favicon'
    ) as HTMLLinkElement;

    linkElement.type = 'image/x-icon';
    linkElement.rel = 'icon';
    linkElement.href = faviconUrl;
  }

  private updateFavicon(favicon: string): void {
    this.setFaviconByUrl(favicon);
  }
}
