import { Injectable } from '@angular/core';
import { errorToast, infoToast } from '../components/toast/toast-config';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastService: ToastService) {}

  showError(title: string, subTitle: string) {
    this.toastService.show(errorToast(title, subTitle));
  }

  showInfo(title: string, subTitle: string) {
    this.toastService.show(infoToast(title, subTitle));
  }
}
