import { Meta, Trace } from '.';
import { Integrations } from './integrations';
import {
  EntityType,
  IdService,
  IntegrationStatus,
  UtilsService,
} from '@sidkik/global';

export interface StorageProperties {
  id: string;
  data: any;
  meta: Meta;
  trace?: Trace;
  integrations: Integrations;
  deleteFlag?: boolean;
  mergeFlag?: boolean;
  tenant?: string;
}

export class Storage implements StorageProperties {
  public id: string;
  public data: any;
  public meta: Meta;
  public integrations: Integrations;
  public trace?: Trace;
  public tenant?: string;

  protected syncChangeFields: string[] = [];
  private trackSync = false;

  constructor(
    options?: StorageProperties,
    private user?: string,
    objectType?: EntityType
  ) {
    this.meta = { ...options?.meta, objectType } as unknown as Meta;
    this.data = { ...options?.data };
    this.integrations = { ...options?.integrations } as unknown as Integrations;
    this.trace = { ...options?.trace };
    this.id = options?.id ?? '';
    this.updateMeta();
    this.updateId();
  }

  public updateMeta() {
    const updatableMeta: any = {};
    updatableMeta.created = this.meta?.created || new Date().getTime();
    updatableMeta.createdBy = this.meta?.createdBy || this.user;
    updatableMeta.updated = new Date().getTime();
    updatableMeta.updatedBy = this.user;
    updatableMeta.objectType = this.meta?.objectType;

    this.meta = { ...updatableMeta };
  }

  public updateId() {
    this.id = this.id || this.generateId();
  }

  public get type(): EntityType {
    return this.meta.objectType;
  }

  private compareChanges(originalData: any, dataToCompare: any): string[] {
    const keys = Object.keys(originalData);
    const changedFields: string[] = [];
    keys.forEach((key) => {
      if (typeof originalData[key] == 'object') {
        if (typeof dataToCompare[key] == 'object') {
          const originalSubKeys = Object.keys(originalData[key]);
          const dataToCompareSubKeys = Object.keys(dataToCompare[key]);
          if (areArraysEqualSets(originalSubKeys, dataToCompareSubKeys)) {
            const subChanges = this.compareChanges(
              originalData[key],
              dataToCompare[key]
            );
            if (subChanges.length > 0) changedFields.push(key);
          } else {
            changedFields.push(key);
          }
        } else {
          changedFields.push(key);
        }
      } else {
        originalData[key] !== dataToCompare[key] && changedFields.push(key);
      }
    });
    return changedFields;
  }

  public forceSync() {
    this.trackSync = true;
  }

  /**
   * check if need to sync
   *
   * This function may be called more than once, but if the prior call sets it to sync, it will not be overwritten on subsequent calls
   *
   * @param dataToCompare
   */
  public checkIfSync(dataToCompare: any) {
    if (Object.keys(this.integrations).length < 1)
      return (this.trackSync = true);
    const changedFields = this.compareChanges(this.data, dataToCompare);
    changedFields.forEach(
      (key) => this.syncChangeFields.includes(key) && (this.trackSync = true)
    );
    return;
  }

  public toStorage<Type>(skipUpdate = false): Type {
    if (!skipUpdate) {
      this.setUpdated();
    }

    const trace = omitUndefinedProps(this.trace);
    const integrations = Object.assign(
      this.integrations || {},
      this.trackSync
        ? {
            state: {
              status: IntegrationStatus.resync,
              lastSync: this.integrations?.state?.updated ?? 0, // add lastSync - intercepted by outbound remote
            },
          }
        : {}
    );

    const serializable = {
      id: this.id,
      data: omitUndefinedProps(this.data),
      meta: omitUndefinedProps(this.meta),
    } as any;

    if (!UtilsService.isEmpty(trace)) {
      serializable['trace'] = trace;
    }

    if (!UtilsService.isEmpty(integrations)) {
      serializable['integrations'] = integrations;
    }

    return deepCopy(serializable) as Type;
  }

  private setUpdated() {
    this.meta.updated = new Date().getTime();
    this.meta.updatedBy = this.user ?? 'n/a';
  }

  private generateId(): string {
    return IdService.generateId(this.meta.objectType);
  }
}

export const omitUndefinedProps = (obj: any, level = 0) => {
  // eslint-disable-next-line no-param-reassign
  const newObject: any = {};
  Object.keys(obj).forEach((key) => {
    if (isObject(obj[key]) && level <= 1) {
      newObject[key] = { ...omitUndefinedProps(obj[key], level + 1) };
    } else {
      if (obj[key] !== undefined && obj[key] !== null)
        newObject[key] = obj[key];
    }
  });

  return newObject;
};

const isObject = (itemToTest: any) =>
  !Array.isArray(itemToTest) &&
  itemToTest !== undefined &&
  itemToTest !== null &&
  !(typeof itemToTest === 'string' || itemToTest instanceof String) &&
  Object.keys(itemToTest).length > 0;

const areArraysEqualSets = (a1: any[], a2: any[]) => {
  const superSet: any = {};
  for (let i = 0; i < a1.length; i++) {
    const e = a1[i] + typeof a1[i];
    superSet[e] = 1;
  }

  for (let i = 0; i < a2.length; i++) {
    const e = a2[i] + typeof a2[i];
    if (!superSet[e]) {
      return false;
    }
    superSet[e] = 2;
  }

  for (const e in superSet) {
    if (superSet[e] === 1) {
      return false;
    }
  }

  return true;
};

export function deepCopy<T>(value: T): T {
  return deepExtend(undefined, value) as T;
}

export function deepExtend(target: unknown, source: unknown): unknown {
  if (!(source instanceof Object)) {
    return source;
  }

  switch (source.constructor) {
    case Date:
      // Treat Dates like scalars; if the target date object had any child
      // properties - they will be lost!
      // eslint-disable-next-line no-case-declarations
      const dateValue = source as Date;
      return new Date(dateValue.getTime());

    case Object:
      if (target === undefined) {
        target = {};
      }
      break;
    case Array:
      // Always copy the array source and overwrite the target.
      target = [];
      break;

    default:
      // Not a plain Object - treat it as a scalar.
      return source;
  }

  for (const prop in source) {
    // use isValidKey to guard against prototype pollution. See https://snyk.io/vuln/SNYK-JS-LODASH-450202
    // eslint-disable-next-line no-prototype-builtins
    if (!source.hasOwnProperty(prop) || !isValidKey(prop)) {
      continue;
    }
    (target as Record<string, unknown>)[prop] = deepExtend(
      (target as Record<string, unknown>)[prop],
      (source as Record<string, unknown>)[prop]
    );
  }

  return target;
}

function isValidKey(key: string): boolean {
  return key !== '__proto__';
}
