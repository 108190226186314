import { Component, Input } from '@angular/core';

export interface TrailItem {
  name: string;
  location?: string;
}
export interface BreadcrumbTrail {
  items: TrailItem[];
}

@Component({
  selector: 'sidkik-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  public home: TrailItem = {
    name: 'Home',
    location: '/',
  };

  @Input() trail: BreadcrumbTrail = { items: [] };
  @Input() includeHome = true;
}
