<button type="submit
" [class.btn-primary]="state !== states.errored" [class.btn-error]="state === states.errored"
  [class.tw-w-full]="fullWidth" [class.tw-w-auto]="!fullWidth" [disabled]="state === states.processing">
  <svg [class.tw-hidden]="state !== states.processing" class="tw-animate-spin tw-h-5 tw-w-5 tw-mr-3 "
    viewBox="0 0 24 24">
    <circle class="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="tw-opacity-75" fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
    </path>
  </svg>
  <span [class.tw-hidden]="state !== states.processing">{{processingText}}</span>
  <span [class.tw-hidden]="state !== states.succeeded">{{successText}}</span>
  <span [class.tw-hidden]="state !== states.errored">{{errorText}}</span>
  <div [class.tw-hidden]="state !== states.ready">
    <ng-content></ng-content>
  </div>
</button>