/** Order */

export const loadOrderType = '[my-account/order] load order';
export const loadOrderSuccessType = '[my-account/order] load order success';
export const loadOrderFailureType = '[my-account/order] load order failure';
export const selectOrderType = '[my-account/order] select order';

/** Subscription */

export const loadSubscriptionType =
  '[my-account/subscription] load subscription';
export const loadSubscriptionSuccessType =
  '[my-account/subscription] load subscription success';
export const loadSubscriptionFailureType =
  '[my-account/subscription] load subscription failure';
export const selectSubscriptionType =
  '[my-account/subscription] select subscription';

export const loadCustomerSubscriptionChangeType =
  '[my-account/subscription-change] load subscription-change';
export const loadCustomerSubscriptionChangeSuccessType =
  '[my-account/subscription-change] load subscription-change success';
export const loadCustomerSubscriptionChangeFailureType =
  '[my-account/subscription-change] load subscription-change failure';
export const selectCustomerSubscriptionChangeType =
  '[my-account/subscription-change] select subscription-change';
export const addCustomerSubscriptionChangeType =
  '[my-account/subscription-change] add subscription-change';
export const addCustomerSubscriptionChangeSuccessType =
  '[my-account/subscription-change] add subscription-change success';
export const addCustomerSubscriptionChangeFailureType =
  '[my-account/subscription-change] add subscription-change failure';
export const deleteCustomerSubscriptionChangeType =
  '[my-account/subscription-change] delete subscription-change';
export const deleteCustomerSubscriptionChangeSuccessType =
  '[my-account/subscription-change] delete subscription-change success';
export const deleteCustomerSubscriptionChangeFailureType =
  '[my-account/subscription-change] delete subscription-change failure';
