import { IntegrationStatus } from '../integrations';

export const IntegrationStatusFinally = [
  IntegrationStatus.complete,
  IntegrationStatus.error,
];

export const IntegrationStatusPendingOrFinally = [
  IntegrationStatus.complete,
  IntegrationStatus.error,
  IntegrationStatus.pending,
];

export const QuickbooksConnected = [true];
