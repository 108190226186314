import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, combineReducers } from '@ngrx/store';
import { clientLayoutStateKey } from '@sidkik/global';

import * as fromMap from './map/map.reducer';

export const CLIENT_LAYOUT_FEATURE_KEY = clientLayoutStateKey;

export interface ClientLayoutState extends EntityState<unknown> {
  [fromMap.mapsFeatureKey]: fromMap.State;
}

export interface ClientLayoutPartialState {
  readonly [CLIENT_LAYOUT_FEATURE_KEY]: ClientLayoutState;
}

export const clientLayoutAdapter: EntityAdapter<unknown> =
  createEntityAdapter<unknown>();

export const initialRendererState: ClientLayoutState =
  clientLayoutAdapter.getInitialState({
    [fromMap.mapsFeatureKey]: fromMap.adapter.getInitialState(
      fromMap.initialState
    ),
  });

const reducers = combineReducers({
  [fromMap.mapsFeatureKey]: fromMap.reducer,
});

export function clientLayoutReducer(
  state: ClientLayoutState | undefined,
  action: Action
) {
  return reducers(state, action);
}
