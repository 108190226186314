import { Injectable, Inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AppConfig } from '@sidkik/global';
import { Subscription, interval } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  checkIt!: Subscription;
  available!: Subscription;

  constructor(private updates: SwUpdate) {}

  init(appConfig: AppConfig) {
    logger.info(
      'app:app-update.service.ts',
      'update enabled:',
      this.updates.isEnabled,
      ' update interval:',
      appConfig.updateCheckInterval
    );

    if (appConfig.updateCheckInterval > 0 && this.updates.isEnabled) {
      this.checkIt = interval(appConfig.updateCheckInterval).subscribe(() => {
        this.updates
          .checkForUpdate()
          .then(() =>
            logger.debug('app:app-update.service.ts', 'update check fired')
          )
          .catch((error) =>
            logger.error(
              'app:app-update.service.ts',
              'error on update check',
              error
            )
          );
      });

      this.available = this.updates.versionUpdates
        .pipe(
          tap((evt) =>
            logger.trace('app:app-update.service.ts', 'update event', evt)
          ),
          filter(
            (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
          ),
          map((evt) => ({
            type: 'UPDATE_AVAILABLE',
            current: evt.currentVersion,
            available: evt.latestVersion,
          }))
        )
        .subscribe((event) => {
          logger.info('app:app-update.service.ts', 'update available', event);
          this.updates.activateUpdate().then(() => document.location.reload());
        });
    }
  }
}
