import { ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { CONFIG_TOKEN, DBConfig } from './services/indexed-db';
import { IndexedDBService } from './services/indexed-db.service';

@NgModule({
  imports: [CommonModule],
})
export class IndexedDBModule {
  static forFeature(dbConfig: DBConfig): ModuleWithProviders<IndexedDBModule> {
    return {
      ngModule: IndexedDBModule,
      providers: [
        { provide: CONFIG_TOKEN, useValue: dbConfig },
        {
          provide: IndexedDBService,
          useFactory: (dbConfig: DBConfig, platformId: any) =>
            new IndexedDBService(dbConfig, platformId),
          deps: [CONFIG_TOKEN, PLATFORM_ID],
        },
      ],
    };
  }
}
