import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';
import { ModerationScore } from './comment';

export enum ThreadStatus {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
  PublishRequested = 'publish requested',
  PublishRejected = 'publish rejected',
  Cordoned = 'cordoned',
  Rejected = 'rejected',
}

export interface ThreadData {
  title: string;
  content: string;
  archived: boolean;
  space: string;
  status?: ThreadStatus;
  statusInfo?: string;
  author?: string;
  authorAvatar?: string;
  commentCount?: number;
  clapCount?: number;
  score?: ModerationScore;
}

export interface ThreadProperties extends StorageProperties {
  data: ThreadData;
  meta: Meta;
  id: string;
}

export class Thread extends Storage implements ThreadProperties {
  public override data!: ThreadData;

  constructor(options?: ThreadProperties, user?: string) {
    super(options, user, EntityType.Thread);
    this.update(options?.data);
  }

  public update(data?: ThreadData): void {
    this.data = { ...data } as unknown as ThreadData;
  }
}

export class DraftThread extends Storage implements ThreadProperties {
  public override data!: ThreadData;

  constructor(options?: ThreadProperties, user?: string) {
    super(options, user, EntityType.DraftThread);
    this.update(options?.data);
  }

  public update(data?: ThreadData): void {
    this.data = { ...data } as unknown as ThreadData;
  }
}
