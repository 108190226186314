import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientLayoutFacade } from '../../+state/client-layout.facade';

@Component({
  selector: 'sidkik-user-info',
  template: ` <sidkik-pure-user-info
    [userName]="userName$ | async"
    [userPhoto]="userPhoto$ | async"
    [userEmail]="userEmail$ | async"
    [mobileLayout]="mobileLayout"
  ></sidkik-pure-user-info>`,
})
export class UserInfoComponent implements OnInit {
  @Input() mobileLayout = false;
  userName$!: Observable<string>;
  userPhoto$!: Observable<string>;
  userEmail$!: Observable<string>;

  constructor(private layoutFacade: ClientLayoutFacade) {}

  ngOnInit(): void {
    this.userName$ = this.layoutFacade.user$.pipe(
      map((user) => user.displayName ?? '')
    );
    this.userPhoto$ = this.layoutFacade.user$.pipe(
      map((user) => {
        if (user.providerData[0]?.photoURL) {
          return `${user.providerData[0]?.photoURL}&p=asset_list_thumb`;
        }
        return '';
      })
    );
    this.userEmail$ = this.layoutFacade.user$.pipe(
      map((user) => user.email ?? '')
    );
  }
}
