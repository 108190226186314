import { animate, style, transition, trigger } from '@angular/animations';
import { ListboxValueChangeEvent } from '@angular/cdk/listbox';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { ComboBoxPanelDefaultOptions } from './combo-box-panel';
import { ComboBoxPositionX, ComboBoxPositionY } from './combo-box-positions';
import { ComboBoxOption } from '@sidkik/global';
import { ComboType } from './combo-box.component';

export const defaultOptions: ComboBoxPanelDefaultOptions = {
  overlapTrigger: false,
  xPosition: 'after',
  yPosition: 'below',
  backdropClass: 'cdk-overlay-transparent-backdrop',
  hasBackdrop: false,
};

const cbAnimation = trigger('comboboxpanel', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(.95)' }),
    animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'scale(1)' }),
    animate('100ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
  ]),
]);

@Component({
  selector: 'sidkik-combo-box-panel',
  templateUrl: './combo-box-panel.component.html',
  animations: [cbAnimation],
})
export class ComboBoxPanelComponent {
  lbValue$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  optionValues: ComboBoxOption[] | null | undefined;
  lastOptions: ComboBoxOption[] | null | undefined;

  @Input() overlapTrigger = defaultOptions.overlapTrigger;
  @Input() xPosition: ComboBoxPositionX = defaultOptions.xPosition;
  @Input() yPosition: ComboBoxPositionY = defaultOptions.yPosition;
  @Input() backdropClass = defaultOptions.backdropClass;
  @Input() hasBackdrop = defaultOptions.hasBackdrop;
  @Input()
  set options(val: ComboBoxOption[] | null | undefined) {
    val = val ?? [];
    this.options$.next(val);
  }

  @Input() multiSelect = false;
  @Input()
  set value(val: any) {
    this.val$.next(val);
  }
  @Input() comboType: string = ComboType.button;

  @Output() closed = new EventEmitter<void>();
  @Output() changed = new EventEmitter<any[]>();
  @Output() filterBlurred = new EventEmitter<void>();
  @Output() filterChanged = new EventEmitter<any>();

  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
  @ViewChild('filter') filterField!: ElementRef;

  isOpen = false;
  options$: BehaviorSubject<ComboBoxOption[] | null | undefined> =
    new BehaviorSubject<ComboBoxOption[] | null | undefined>(undefined);
  val$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  vCheckSub: Subscription;

  comboTypes = ComboType;

  constructor() {
    // check val against list to avoid issue with listbox val not found
    this.vCheckSub = combineLatest([this.options$, this.val$]).subscribe(
      ([opts, val]) => {
        this.optionValues = opts;
        let found = false;
        if (opts && opts.length > 0) {
          if (Array.isArray(val)) {
            if (!this.multiSelect) {
              if (val.length > 0) {
                const hit = opts.find((o) => this.comparer(val[0], o));
                if (hit) {
                  found = true;
                }
              }
            }
            this.lbValue$.next(val);
          } else {
            const hit = opts.find((o) => this.comparer(val, o));
            if (hit) {
              found = true;
            }
          }
        }
        if (found) {
          this.lbValue$.next(val);
        } else {
          // if multiselect, it should not clear the value
          // needed for single select; otherwise, the prev value will be displayed
          if (!this.multiSelect) {
            this.lbValue$.next(undefined);
          }
        }
      }
    );
  }

  inputFilterBlurred() {
    if (this.filterField?.nativeElement) {
      this.filterField.nativeElement.value = '';
    }
    this.filterBlurred.emit();
  }

  inputFilterRequest(evt: any): void {
    this.filterChanged.emit(evt);
  }

  comparer(val: any, option: any): boolean {
    const isObject = (objLike: any) =>
      objLike && typeof objLike === 'object' && objLike.constructor === Object;

    const valIsObject = isObject(val);
    const optionIsObject = isObject(option);
    if (valIsObject && val['id'] && optionIsObject && option['id']) {
      return val['id'] === option['id'];
    }
    if (valIsObject && val['value'] && optionIsObject && option['value']) {
      return val['value'] === option['value'];
    }
    if (valIsObject && val['value'] && optionIsObject && option['value']) {
      return val['value'] === option['value'];
    }
    if (optionIsObject && option['id']) {
      return val === option['id'];
    }
    if (optionIsObject && option['value']) {
      return val === option['value'];
    }

    return val === option;
  }

  comparerMultiValue(val: any[], option: any): boolean {
    return val === option;
  }

  public valueChanged(evt: ListboxValueChangeEvent<any>) {
    this.lbValue$.next(evt.value);
    this.changed.emit(evt.value as any);
  }
}
