import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sidkik-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
})
export class ActionComponent {
  action;
  constructor(private activatedActivated: ActivatedRoute) {
    this.action = this.activatedActivated.snapshot.queryParams['mode'];
  }
}
