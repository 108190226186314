import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppConfig,
  APP_CONFIG,
  AuthzConfig,
  AUTHZ_CONFIG,
} from '@sidkik/global';
import { ButtonState, ErrorService } from '@sidkik/ui';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AuthProcessService } from '../../services/auth-process.service';
import { PasswordResetAttemptEvent } from './pure-password-reset.component';

@Component({
  selector: 'sidkik-password-reset',
  template: `
    <sidkik-pure-password-reset
      [logoUrl]="logoUrl"
      (resetClicked)="reset($event)"
      [state]="state$ | async"
    >
    </sidkik-pure-password-reset>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordResetComponent {
  @Input() logoUrl = this.appConfig.branding?.logo;

  state$: BehaviorSubject<ButtonState> = new BehaviorSubject<ButtonState>(
    ButtonState.ready
  );
  action;
  oobCode;
  apiKey;
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(AUTHZ_CONFIG) readonly authzConfig: AuthzConfig,
    private authProcess: AuthProcessService,
    private activatedActivated: ActivatedRoute,
    private errorService: ErrorService,
    private router: Router
  ) {
    this.action = this.activatedActivated.snapshot.queryParams['mode'];
    this.oobCode = this.activatedActivated.snapshot.queryParams['oobCode'];
    this.apiKey = this.activatedActivated.snapshot.queryParams['apiKey'];
  }

  async reset(resetAttempt: PasswordResetAttemptEvent) {
    this.state$.next(ButtonState.processing);
    try {
      await this.authProcess.confirmPasswordReset(
        this.oobCode,
        resetAttempt.password
      );
    } catch (err: any) {
      this.errorService.handleError(err);
      // place user where they can try again
      if (
        err.message?.includes('EXPIRED_OOB_CODE') ||
        err.message?.includes('auth/invalid-action-code')
      ) {
        this.router.navigate(['/auth/forgot']);
      }
    }
    this.state$.next(ButtonState.ready);
  }
}
