<ng-template cdkConnectedOverlayWidth="100%">
  <div class="tw-flex tw-flex-col tw-w-full">
    <div *ngIf="comboType === comboTypes.lookup" class="tw-relative tw-rounded-md tw-shadow-sm tw-mb-0">
      <div class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
        <svg class="tw-h-5 tw-w-5 tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
        </svg>
      </div>
      <input autocomplete="off" *ngIf="comboType === comboTypes.lookup" (blur)="inputFilterBlurred()" #filter
        type="text"
        class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1.5 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-primary-600 sm:tw-text-sm sm:tw-leading-6"
        (input)="inputFilterRequest($event)">
    </div>
    <ul *ngIf="comboType !== comboTypes.buttonPlus" cdkScrollable @comboboxpanel cdkListbox
      [cdkListboxCompareWith]="comparer" [cdkListboxValue]="lbValue$ |async" [cdkListboxMultiple]="multiSelect"
      (cdkListboxValueChange)="valueChanged($event)" aria-labelledby=""
      [class.tw-mt-2]="comboType !== comboTypes.lookup" [class.tw-mt-0]="comboType === comboTypes.lookup"
      class="tw-relative tw-right-0  tw-w-[100%] tw-overflow-auto  tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
      <li *ngFor="let option of optionValues" [cdkOption]="option.value" [cdkOptionDisabled]="option.filtered"
        class="sidkik-option" [class.tw-hidden]="option.filtered"
        [class.tw-pointer-events-none]="option.value === 'NO_VALUE_MATCH'">
        <span class="sidkik-option-label"> {{option.label}}</span>
        <span class="sidkik-option-selected-icon">
          <svg class="tw-h-5 tw-w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
            aria-hidden="true">
            <path fill-rule="evenodd"
              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
              clip-rule="evenodd" />
          </svg>
        </span>
      </li>
    </ul>
    <ul *ngIf="comboType === comboTypes.buttonPlus" cdkScrollable @comboboxpanel cdkListbox
      [cdkListboxCompareWith]="comparer" [cdkListboxValue]="lbValue$ |async" [cdkListboxMultiple]="multiSelect"
      (cdkListboxValueChange)="valueChanged($event)" aria-labelledby=""
      class="tw-relative tw-right-0 tw-mt-2 tw-w-[100%] tw-overflow-auto tw-divide-y tw-divide-gray-200  tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
      <li *ngFor="let option of optionValues" [cdkOption]="option.value" [cdkOptionDisabled]="option.filtered"
        class="sidkik-option-plus" [class.tw-hidden]="option.filtered"
        [class.tw-pointer-events-none]="option.value === 'NO_VALUE_MATCH'">
        <div class="flex flex-col">
          <div class="tw-flex tw-justify-between">
            <p class="sidkik-option-label-plus">{{option.label}}</p>
            <span class="sidkik-option-selected-icon-plus">
              <svg class="tw-h-5 tw-w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd" />
              </svg>
            </span>
          </div>
          <p class="sidkik-option-description-plus">{{option.description}}</p>
        </div>
      </li>
    </ul>
  </div>
</ng-template>