export const ErrorsToStringMap = new Map([
  ['required', 'this field is required'],
  ['email', 'valid email required'],
  ['minlength', 'must be at least __requiredLength__ characters long'],
  ['maxlength', 'must not be more than __requiredLength__ characters long'],
  ['equalityValidator', 'items do not match'],
  ['invalidCredential', 'provided credentials are not valid'],
  ['passwordsNotMatching', 'provided passwords do not match'],
  ['pattern', 'provided input is not valid'],
  ['slugExists', 'provided slug already exists'],
  ['percentOffRequired', 'percent off is required and cannot be 0'],
  ['amountOffRequired', 'discount amount is required and cannot be 0'],
  ['durationTimesRequired', 'times cannot be 0'],
  ['appliesToRequired', 'must select at least one product'],
  ['validUntilRequired', 'please select a valid date'],
  ['maxRedemptionsRequired', 'max redemptions is required and cannot be 0'],
  ['minOrderAmountRequired', 'min order amount is required and cannot be 0'],
  [
    'invalidPromoCode',
    'A code may not contain any special characters or spaces.',
  ],
  [
    'duplicatePromoCode',
    'Code already exists. Must be unique across all coupons.',
  ],
  ['validUntilInThePast', 'Please pick a date in the future. '],
  ['filterMissing', 'Please select a filter'],
  ['coursePrefilterMissing', 'Please select a course'],
  ['challengePrefilterMissing', 'Please select a challenge'],
  ['formPrefilterMissing', 'Please select a form'],
  ['conditionalRequired', 'Please select a conditional for the filter'],
  ['valueRequired', 'Please select a value for the filter'],
  ['durationRequired', 'Please select a duration greater than 0'],
  ['chargingStrategyRequired', 'Please select a charging strategy'],
  [
    'chargingStrategyUpfrontRequired',
    'This requires an upfront payment [multiple sessions]',
  ],
  ['cancellationPeriodRequired', 'Please select a cancellation period'],
  ['quantityRequired', 'Please select a quantity'],
]);
