/* eslint-disable no-prototype-builtins */
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  SimpleChange,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import vimeo from '@vimeo/player';
import { race, Subject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
// import { WatchEvent } from '../asset-renderer/asset-renderer.component';

@Component({
  selector: 'sidkik-video-vimeo',
  templateUrl: './video-vimeo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoVimeoComponent implements OnInit, OnDestroy {
  @Input() vimeoId: string | undefined;
  @Input() vimeoEmbedUrl: string | undefined;
  @Input() options: any;
  @Output() play: EventEmitter<any> = new EventEmitter();
  @Output() playing: EventEmitter<any> = new EventEmitter();
  @Output() pause: EventEmitter<any> = new EventEmitter();
  @Output() progress: EventEmitter<any> = new EventEmitter();
  @Output() bufferend: EventEmitter<any> = new EventEmitter();
  @Output() bufferstart: EventEmitter<any> = new EventEmitter();
  @Output() ended: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();
  @Output() loaded: EventEmitter<any> = new EventEmitter();
  @Output() playbackratechange: EventEmitter<any> = new EventEmitter();
  @Output() seeked: EventEmitter<any> = new EventEmitter();
  @Output() seeking: EventEmitter<any> = new EventEmitter();
  @Output() timeupdate: EventEmitter<any> = new EventEmitter();
  @Output() volumechange: EventEmitter<any> = new EventEmitter();
  @Output() cuechange: EventEmitter<any> = new EventEmitter();
  @Output() cuepoint: EventEmitter<any> = new EventEmitter();
  @Output() qualitychange: EventEmitter<any> = new EventEmitter();
  @Output() camerachange: EventEmitter<any> = new EventEmitter();
  @Output() resize: EventEmitter<any> = new EventEmitter();
  @Output() fullscreenchange: EventEmitter<any> = new EventEmitter();
  // @Output() watch: EventEmitter<WatchEvent> = new EventEmitter();
  @Output() autoPlayBlocked: EventEmitter<any> = new EventEmitter();

  @ViewChild('vimeo_player', { static: true })
  vimeo!: ElementRef<HTMLIFrameElement>;

  debouncerTU$: Subject<any> = new Subject<any>();
  interrupt$: Subject<any> = new Subject<any>();

  validVimeoOptions = [
    'thumbnail_small',
    'thumbnail_medium',
    'thumbnail_large',
  ];
  player!: vimeo;
  url: any;

  start: any;
  current: any;
  duration: any;
  playInitiated = false;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    // this.raceRecursive();
  }

  // raceRecursive() {
  //   race(this.debouncerTU$.pipe(debounceTime(5000)), this.interrupt$)
  //     .pipe(take(1)) // force it to complete
  //     .subscribe(
  //       () => {
  //         if (this.start !== undefined && this.current !== undefined) {
  //           this.watch.emit({
  //             start: this.start,
  //             stop: this.current,
  //             externalId: this.vimeoId,
  //             duration: this.duration,
  //           });
  //         }
  //         this.start = undefined;
  //         this.current = undefined;
  //       }, // srv call here
  //       (err) => console.error('race err', err),
  //       () => this.raceRecursive() // reset it once complete
  //     );
  // }

  ngOnChanges(changes: { vimeoId: SimpleChange; options: SimpleChange }) {
    if (changes.vimeoId && this.vimeoId) {
      if (this.start !== undefined && this.current !== undefined) {
        // this.watch.emit({
        //   start: this.start,
        //   stop: this.current,
        //   externalId: this.vimeoId,
        //   duration: this.duration,
        // });
      }
      if (this.vimeoId?.startsWith('http')) {
        const parts = this.vimeoId.split('/');
        this.vimeoId = `${parts[parts.length - 2]}`;
      }
      // if loaded change by api to allow tracking
      if (this.player) {
        this.player
          .loadVideo(this.vimeoEmbedUrl ?? '')
          // .then((id) => console.log('loaded video', id))
          .catch((e: any) => console.log('error loading', e));
      } else {
        // clean the url
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.vimeoEmbedUrl + this.parseQueryOptions(this.options) // vimeo embed has ? so parse should start with &
        );
      }
    }
  }

  ngOnDestroy() {
    if (this.player) this.player.destroy();
  }

  ngAfterViewInit() {
    this.setupEvents();
  }

  setupEvents() {
    if (this.player) this.player.destroy();
    this.start = undefined;
    this.current = undefined;
    this.duration = undefined;
    this.playInitiated = false;
    this.player = new vimeo(this.vimeo.nativeElement);
    this.player.on('play', (evt) => {
      this.start = evt.seconds;
      this.duration = evt.duration;
      this.play.emit(evt);
      this.playInitiated = true;
    });
    this.player.on('playing', (evt) => {
      this.start = evt.seconds;
      this.playing.emit(evt);
    });
    this.player.on('pause', (evt) => {
      this.interrupt$.next(undefined);
      this.pause.emit(evt);
      // if blocked - will just pause without play
      if (!this.playInitiated) {
        this.autoPlayBlocked.emit();
      }
    });
    this.player.on('progress', (evt) => this.progress.emit(evt));
    this.player.on('bufferend', (evt) => this.bufferend.emit(evt));
    this.player.on('bufferstart', (evt) => this.bufferstart.emit(evt));
    this.player.on('ended', (evt) => this.ended.emit(evt));
    this.player.on('error', (evt) => this.error.emit(evt));
    this.player.on('loaded', (evt) => this.loaded.emit(evt));
    this.player.on('playbackratechange', (evt) =>
      this.playbackratechange.emit(evt)
    );
    this.player.on('seeked', (evt) => {
      this.interrupt$.next(undefined);
      this.seeked.emit(evt);
    });
    this.player.on('seeking', (evt) => {
      this.interrupt$.next(undefined);
      this.seeking.emit(evt);
    });
    this.player.on('timeupdate', (evt) => {
      this.current = evt.seconds;
      this.debouncerTU$.next(evt);
    });
    this.player.on('volumechange', (evt) => this.volumechange.emit(evt));
    this.player.on('cuechange', (evt) => this.cuechange.emit(evt));
    this.player.on('cuepoint', (evt) => this.cuepoint.emit(evt));
    this.player.on('qualitychange', (evt) => this.qualitychange.emit(evt));
    this.player.on('camerachange', (evt) => this.camerachange.emit(evt));
    this.player.on('resize', (evt) => this.resize.emit(evt));
    this.player.on('fullscreenchange', (evt) =>
      this.fullscreenchange.emit(evt)
    );
  }

  parseQueryOptions(options: any): string {
    let query = '';

    if (options && options.hasOwnProperty('query')) {
      query = '&' + this.serializeQuery(options.query);
    }

    return query;
  }

  serializeQuery(query: any): string {
    const queryString: any = [];

    for (const p in query) {
      if (query.hasOwnProperty(p)) {
        queryString.push(
          encodeURIComponent(p) + '=' + encodeURIComponent(query[p])
        );
      }
    }

    return queryString.join('&');
  }
}
