import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  AppConfig,
  APP_CONFIG,
  ANALYTICS_IS_SUPPORTED,
} from '../models/config';
import {
  Analytics,
  logEvent,
  setUserId,
  setUserProperties,
} from '@angular/fire/analytics';
import { isPlatformServer } from '@angular/common';
import {
  CompletedCourse,
  CompletedSection,
  CourseEvent,
  EventMessages,
  StartedCourse,
  StartedSection,
  UndoCompletedSection,
} from '../events';
import { GlobalFacade } from '../+state/global.facade';
import { take } from 'rxjs';
import {
  AddedChallengeActivity,
  ChallengeEvent,
  ChallengeSentiments,
  EditedChallengeActivity,
  RemovedChallengeActivity,
  StartedChallenge,
} from '../events/challenge';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  customerId: string | undefined;
  eventsWorker: Worker | undefined;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject('APP_VERSION') private appVersion: string,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(ANALYTICS_IS_SUPPORTED) private isSupported: boolean,
    private globalFacade: GlobalFacade,
    private analytics: Analytics
  ) {
    if (window.Worker) {
      this.eventsWorker = new Worker(
        new URL('./worker/events.worker', import.meta.url)
      );
      this.eventsWorker.postMessage({
        type: EventMessages.Config,
        payload: { appConfig: this.appConfig },
      });
    }
  }

  private okToTrack(): boolean {
    return this.isSupported && !isPlatformServer(this.platformId);
  }

  public trackScreenView(screen: string, path: string): void {
    if (!this.okToTrack()) return;
    logEvent(this.analytics, 'screen_view' as any, {
      app_version: this.appVersion,
      screen_name: screen,
      firebase_screen: screen,
      page_path: path,
    });
  }

  public viewItem(item: any): void {
    if (!this.okToTrack()) return;
    if (!!item && !!item.data && !!item.data.sku && !!item.data.sku[0]) {
      logEvent(this.analytics, 'view_item' as any, {
        app_version: this.appVersion,
        currency: 'USD',
        value: this.convertToUSD(item.data.sku[0].data.price),
        items: [
          {
            item_id: item.data.product.id,
            item_name: item.data.product.data.name,
            price: this.convertToUSD(item.data.sku[0].data.price),
          },
        ],
      });
    }
  }

  public addToCart(item: any): void {
    if (!this.okToTrack()) return;
    logEvent(this.analytics, 'add_to_cart' as any, {
      app_version: this.appVersion,
      currency: 'USD',
      value: this.convertToUSD(item.data.sku.data.price),
      items: [
        {
          item_id: item.data.product.id,
          item_name: item.data.product.data.name,
          price: this.convertToUSD(item.data.sku.data.price),
        },
      ],
    });
  }

  public removeFromCart(item: any): void {
    if (!this.okToTrack()) return;
    logEvent(this.analytics, 'remove_from_cart' as any, {
      app_version: this.appVersion,
      currency: 'USD',
      value: this.convertToUSD(item.data.sku.data.price),
      items: [
        {
          item_id: item.data.product.id,
          item_name: item.data.product.data.name,
          price: this.convertToUSD(item.data.sku.data.price),
        },
      ],
    });
  }

  public viewCart(items: any[]): void {
    if (!this.okToTrack()) return;
    logEvent(this.analytics, 'view_cart' as any, {
      app_version: this.appVersion,
      currency: 'USD',
      value: items.reduce(
        (acc, cur) => acc + this.convertToUSD(cur.data.sku.data.price),
        0
      ),
      items: items.map((item) => ({
        item_id: item.data.product.id,
        item_name: item.data.product.data.name,
        price: this.convertToUSD(item.data.sku.data.price),
      })),
    });
  }

  public beginCheckout(items: any[]): void {
    if (!this.okToTrack()) return;
    logEvent(this.analytics, 'begin_checkout' as any, {
      app_version: this.appVersion,
      currency: 'USD',
      value: items.reduce(
        (acc, cur) => acc + this.convertToUSD(cur.data.sku.data.price),
        0
      ),
      items: items.map((item) => ({
        item_id: item.data.product.id,
        item_name: item.data.product.data.name,
        price: this.convertToUSD(item.data.sku.data.price),
      })),
    });
  }

  public addPaymentInfo(items: any[], total: number): void {
    if (!this.okToTrack()) return;
    logEvent(this.analytics, 'add_payment_info' as any, {
      app_version: this.appVersion,
      currency: 'USD',
      value: this.convertToUSD(total),
      items: items.map((item) => ({
        item_id: item.data.product.id,
        item_name: item.data.product.data.name,
        price: this.convertToUSD(item.data.sku.data.price),
      })),
    });
  }

  public purchase(
    items: any[],
    total: number,
    coupon?: string | undefined
  ): void {
    if (!this.okToTrack()) return;
    logEvent(this.analytics, 'purchase' as any, {
      app_version: this.appVersion,
      coupon,
      currency: 'USD',
      value: this.convertToUSD(total),
      items: items.map((item) => ({
        item_id: item.data.product.id,
        item_name: item.data.product.data.name,
        price: this.convertToUSD(item.data.sku.data.price),
      })),
    });
  }

  public setUser(uid: string, email?: string): void {
    this.customerId = uid;
    if (!this.okToTrack()) return;
    setUserId(this.analytics, uid);
    setUserProperties(this.analytics, {
      app_version: this.appVersion,
      // sid_email: email,
    });
  }

  public trackLogin(): void {
    if (!this.okToTrack()) return;
    logEvent(this.analytics, 'login' as any, {
      app_version: this.appVersion,
    });
  }

  public trackCourseStart(courseId: string): void {
    const payload: StartedCourse = {
      action: CourseEvent.StartedCourse,
      created: Date.now(),
      tenant: this.appConfig.firebase.projectId,
      customer: this.customerId!,
      payload: {
        courseId,
      },
    };
    this.sendEvent(payload);
  }

  public trackCourseCompletion(courseId: string): void {
    const payload: CompletedCourse = {
      action: CourseEvent.CompletedCourse,
      created: Date.now(),
      tenant: this.appConfig.firebase.projectId,
      customer: this.customerId!,
      payload: {
        courseId,
      },
    };
    this.sendEvent(payload);
  }

  public trackCourseSectionStart(
    courseId: string,
    sectionId: string,
    percentageStarted: number
  ): void {
    const payload: StartedSection = {
      action: CourseEvent.StartedSection,
      created: Date.now(),
      tenant: this.appConfig.firebase.projectId,
      customer: this.customerId!,
      payload: {
        courseId,
        sectionId,
        percentageStarted,
      },
    };
    this.sendEvent(payload);
  }

  public trackCourseSectionCompletion(
    courseId: string,
    sectionId: string,
    percentageCompleted: number
  ): void {
    const payload: CompletedSection = {
      action: CourseEvent.CompletedSection,
      created: Date.now(),
      tenant: this.appConfig.firebase.projectId,
      customer: this.customerId!,
      payload: {
        courseId,
        sectionId,
        percentageCompleted: Math.floor(percentageCompleted),
      },
    };
    this.sendEvent(payload);
  }

  public trackCourseSectionCompletionUndo(
    courseId: string,
    sectionId: string,
    percentageCompleted: number
  ): void {
    const payload: UndoCompletedSection = {
      action: CourseEvent.UndoCompletedSection,
      created: Date.now(),
      tenant: this.appConfig.firebase.projectId,
      customer: this.customerId!,
      payload: {
        courseId,
        sectionId,
        percentageCompleted: Math.floor(percentageCompleted),
      },
    };
    this.sendEvent(payload);
  }

  public trackChallengeStart(
    challengeId: string,
    challengeProgressId: string,
    numberOfActivities: number
  ): void {
    const payload: StartedChallenge = {
      action: ChallengeEvent.StartedChallenge,
      created: Date.now(),
      tenant: this.appConfig.firebase.projectId,
      customer: this.customerId!,
      payload: {
        challengeId,
        numberOfActivities,
        challengeProgressId,
      },
    };
    this.sendEvent(payload);
  }

  public trackAddChallengeActivity(
    challengeId: string,
    challengeProgressId: string,
    activityId: string,
    numberOfActivities: number,
    when: number,
    sentiments: ChallengeSentiments[],
    percentageCompleted: number
  ): void {
    const payload: AddedChallengeActivity = {
      action: ChallengeEvent.AddedChallengeActivity,
      created: Date.now(),
      tenant: this.appConfig.firebase.projectId,
      customer: this.customerId!,
      payload: {
        challengeId,
        numberOfActivities,
        when,
        sentiments,
        activityId,
        challengeProgressId,
        percentageCompleted,
      },
    };
    this.sendEvent(payload);
  }

  public trackEditChallengeActivity(
    challengeId: string,
    challengeProgressId: string,
    activityId: string,
    numberOfActivities: number,
    when: number,
    sentiments: ChallengeSentiments[],
    percentageCompleted: number
  ): void {
    const payload: EditedChallengeActivity = {
      action: ChallengeEvent.EditedChallengeActivity,
      created: Date.now(),
      tenant: this.appConfig.firebase.projectId,
      customer: this.customerId!,
      payload: {
        challengeId,
        numberOfActivities,
        when,
        sentiments,
        activityId,
        challengeProgressId,
        percentageCompleted,
      },
    };
    this.sendEvent(payload);
  }

  public trackRemoveChallengeActivity(
    challengeId: string,
    challengeProgressId: string,
    activityId: string,
    numberOfActivities: number,
    percentageCompleted: number
  ): void {
    const payload: RemovedChallengeActivity = {
      action: ChallengeEvent.RemovedChallengeActivity,
      created: Date.now(),
      tenant: this.appConfig.firebase.projectId,
      customer: this.customerId!,
      payload: {
        challengeId,
        numberOfActivities,
        activityId,
        challengeProgressId,
        percentageCompleted,
      },
    };
    this.sendEvent(payload);
  }

  private sendEvent(payload: any): void {
    if (this.eventsWorker) {
      this.globalFacade.jwt$.pipe(take(1)).subscribe((jwt) =>
        this.eventsWorker!.postMessage({
          type: EventMessages.Event,
          payload: { payload, jwt },
        })
      );
    }
  }

  private convertToUSD(amount: number) {
    return amount * 0.01;
  }
}
