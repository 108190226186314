import { BaseEventPayload } from './base';

export enum CourseEvent {
  StartedCourse = 'started_course',
  CompletedCourse = 'completed_course',
  StartedSection = 'started_section',
  CompletedSection = 'completed_section',
  UndoCompletedSection = 'undo_completed_section',
}

export interface StartedCourse extends BaseEventPayload {
  payload: {
    courseId: string;
  };
}

export interface CompletedCourse extends BaseEventPayload {
  payload: {
    courseId: string;
  };
}

export interface StartedSection extends BaseEventPayload {
  payload: {
    courseId: string;
    sectionId: string;
    percentageStarted: number;
  };
}

export interface CompletedSection extends BaseEventPayload {
  payload: {
    courseId: string;
    sectionId: string;
    percentageCompleted: number;
  };
}

export interface UndoCompletedSection extends BaseEventPayload {
  payload: {
    courseId: string;
    sectionId: string;
    percentageCompleted: number;
  };
}
