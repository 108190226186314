import { InjectionToken } from '@angular/core';

export const IS_PLATFORM_ENABLED = new InjectionToken<boolean>(
  'sidkik.platform'
);

export const APP_CHECK_DEBUG_TOKEN = new InjectionToken<boolean>(
  'app.check.debug.token'
);

export const LOGGER_ENABLED = new InjectionToken<any>('sidkik.logger.enabled');
export const LOGGER_FILTER = new InjectionToken<any>('sidkik.logger.filter');

export const MOCK_ENABLED = new InjectionToken<any>('sidkik.mock');
