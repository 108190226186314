import { Injectable } from '@angular/core';
import * as htmlToImage from 'html-to-image';

@Injectable({
  providedIn: 'root',
})
export class CaptureService {
  async captureElementAsPngBlob(element: HTMLElement): Promise<Blob | null> {
    // let fontEmbedCss = '';
    // try {
    //   // think this does nothing
    //   fontEmbedCss = await htmlToImage.getFontEmbedCSS(element, {
    //     skipFonts: true,
    //   });
    // } catch (e) {
    //   /* empty */
    // }
    return htmlToImage.toBlob(element, {
      skipFonts: true,
      cacheBust: true,
      imagePlaceholder: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
      fetchRequestInit: {
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization'
        }
      },
      // fontEmbedCSS: fontEmbedCss,
    });
  }
}
