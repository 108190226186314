import { Injectable } from '@angular/core';
import { IndexedDBService } from '@sidkik/indexed-db';
import { Observable, lastValueFrom } from 'rxjs';
import { EntityType } from '@sidkik/global';
import { Document } from '../db.config';

@Injectable()
export class LocalStorageService {
  constructor(private db: IndexedDBService) {}

  upsert(doc: Document): Observable<any> {
    return this.db.update(doc.meta.objectType, doc);
  }

  delete(doc: Document): Observable<any> {
    logger.debug('sidkik-db:local-storage:delete', 'delete', doc);
    return this.db.delete(doc.meta.objectType, doc.id);
  }

  getAll<T>(entityType: EntityType): Observable<T[]> {
    return this.db.getAll<T>(entityType);
  }

  getAllByIndex<T>(
    entityType: EntityType,
    index: string,
    matcher: string
  ): Observable<T[]> {
    return this.db.getAllByIndex<T>(
      entityType,
      index,
      IDBKeyRange.only(matcher)
    );
  }

  get<T>(entityType: EntityType, id: string): Observable<T> {
    return this.db.getByID<T>(entityType, id);
  }

  async clearEntries() {
    const clearables = [
      EntityType.Customer,
      EntityType.OrderRequest,
      EntityType.MinimizedOrder,
      EntityType.Course,
      EntityType.Challenge,
      EntityType.CourseProgress,
      EntityType.ChallengeProgress,
      EntityType.Comment,
      EntityType.DraftThread,
      EntityType.CordonedComment,
      EntityType.UserCordonedComment,
      EntityType.UserCommentModerationMap,
      EntityType.UserThreadModerationMap,
      EntityType.UserCordonedThread,
      EntityType.UserAsset,
      EntityType.Affiliate,
      EntityType.MinimizedBooking,
    ];
    for await (const item of clearables) {
      await lastValueFrom(this.db.clear(item));
    }
  }

  /**
   * Clears all entries from the database - used to debug issues
   */
  async clearAllEntries() {
    const clearables = [
      EntityType.Asset,
      EntityType.Page,
      EntityType.Map,
      EntityType.Post,
      EntityType.Tag,
      EntityType.ChallengeProgress,
      EntityType.Content,
      EntityType.Configuration,
      EntityType.Product,
      EntityType.SKU,
      EntityType.Customer,
      EntityType.OrderRequest,
      EntityType.MinimizedOrder,
      EntityType.Course,
      EntityType.Challenge,
      EntityType.CourseProgress,
      EntityType.QuickbooksConfiguration,
      EntityType.CartItem,
      EntityType.OrderRequest,
      EntityType.MinimizedOrder,
      EntityType.Comment,
      EntityType.DraftThread,
      EntityType.Comment,
      EntityType.Thread,
      EntityType.Space,
      EntityType.CordonedComment,
      EntityType.UserCordonedComment,
      EntityType.UserCommentModerationMap,
      EntityType.UserAsset,
      EntityType.Affiliate,
      EntityType.Flow,
      EntityType.Form,
      EntityType.User,
      EntityType.Booking,
      EntityType.MinimizedBooking,
      EntityType.Coupon,
      EntityType.PromoCode,
    ];
    for await (const item of clearables) {
      await lastValueFrom(this.db.clear(item));
    }
  }

  async startOver() {
    await lastValueFrom(this.db.deleteDatabase());
  }
}
