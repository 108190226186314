import { Integrations } from '../integrations';
import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export interface UserAssetData {
  name?: string;
  bucket?: string;
  token?: string;
  key?: string;
  uploadedBytes: number;
  size: number;
  totalSize?: number;
  type: string;
  url: string;
}

export interface UserAssetProperties extends StorageProperties {
  data: UserAssetData;
  meta: Meta;
  integrations: Integrations;
  id: string;
}

export class UserAsset extends Storage implements UserAssetProperties {
  public override data!: UserAssetData;
  constructor(options?: UserAssetProperties, user?: string) {
    super(options, user, EntityType.UserAsset);
    this.update(options?.data);
  }

  public update(data?: UserAssetData): UserAsset {
    this.data = { ...this.data, ...data };
    return this;
  }
}
