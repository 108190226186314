<img *ngIf="imageUrl !== ''" [class.tw-h-10]="size === sizes.medium" [class.tw-w-10]="size === sizes.medium"
  [class.tw-h-12]="size === sizes.large" [class.tw-w-12]="size === sizes.large" [class.tw-h-8]="size === sizes.small"
  [class.tw-w-8]="size === sizes.small" class="tw-inline-block tw-rounded-full tw-object-cover tw-object-center"
  [src]="imageUrl" alt="">

<span *ngIf="name !== '' && imageUrl === ''" [class.tw-h-10]="size === sizes.medium"
  [class.tw-w-10]="size === sizes.medium" [class.tw-h-12]="size === sizes.large" [class.tw-w-12]="size === sizes.large"
  [class.tw-h-8]="size === sizes.small" [class.tw-w-8]="size === sizes.small"
  class="tw-inline-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-500">
  <span class="tw-font-medium tw-leading-none tw-text-white">{{initials}}</span>
</span>

<span *ngIf="name === '' && imageUrl === ''" [class.tw-h-10]="size === sizes.medium"
  [class.tw-w-10]="size === sizes.medium" [class.tw-h-12]="size === sizes.large" [class.tw-w-12]="size === sizes.large"
  [class.tw-h-8]="size === sizes.small" [class.tw-w-8]="size === sizes.small"
  class="tw-inline-block  tw-overflow-hidden tw-rounded-full tw-bg-gray-100">
  <svg class="tw-h-full tw-w-full tw-text-gray-300" fill="currentColor" viewBox="0 0 24 24">
    <path
      d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
  </svg>
</span>