<div class="tw-min-h-full tw-flex tw-flex-col tw-justify-center tw-py-12 sm:tw-px-6 lg:tw-px-8">
  <div class="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md">
    <img class="tw-mx-auto tw-h-12 tw-w-auto tw-cursor-pointer" [src]="logoUrl" alt="Workflow" [routerLink]="['/']">
    <h2 class="tw-mt-6 tw-text-center tw-text-3xl tw-tracking-tight tw-font-bold tw-text-gray-900">Reset your
      password</h2>
  </div>

  <div class="tw-mt-8 sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md">
    <div class="tw-bg-white tw-py-8 tw-px-4 tw-shadow sm:tw-rounded-lg sm:tw-px-10">
      <form class="tw-space-y-6" [formGroup]="resetForm" name="resetForm" novalidate>
        <div>
          <label for="password" class="input-label"> Password
          </label>
          <div class="tw-mt-1">
            <input id="password" formControlName="password" name="password" type="password" autocomplete="password"
              required class="input">
            <div class="tw-h-1 tw-my-1 tw-px-3">
              <!-- <sidkik-password-strength *ngIf="resetForm.controls.password.value"
                [password]="resetForm.controls.password.value"></sidkik-password-strength> -->
            </div>
            <sidkik-input-error [touched]="resetForm.controls.password.touched"
              [dirty]="resetForm.controls.password.dirty" [errors]="resetForm.controls.password.errors">
            </sidkik-input-error>
          </div>
        </div>

        <div>
          <label for="passwordConfirm" class="input-label"> Confirm Password </label>
          <div class="tw-mt-1">
            <input id="passwordConfirm" formControlName="passwordConfirm" name="passwordConfirm" type="password"
              autocomplete="password" required class="input">
            <sidkik-input-error [dirty]="resetForm.controls.passwordConfirm.dirty"
              [touched]="resetForm.controls.passwordConfirm.touched"
              [errors]="resetForm.controls.passwordConfirm.errors"></sidkik-input-error>
          </div>
        </div>

        <div>
          <sidkik-process-button [state]="state" (click)="reset()" [fullWidth]="true"
            processingText="Resetting password..." type="submit">Reset password
          </sidkik-process-button>
        </div>
      </form>


    </div>
  </div>