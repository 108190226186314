import { Sentiment } from './sentiment';
import { Storage, StorageProperties } from '../storage';
import { EntityType } from '@sidkik/global';

export interface ChallengeEventData {
  when: number;
  note?: string;
  sentiments?: Sentiment[];
}

export interface ChallengeEventProperties extends StorageProperties {
  data: ChallengeEventData;
  id: string;
}

export class ChallengeEvent
  extends Storage
  implements ChallengeEventProperties
{
  public override data!: ChallengeEventData;

  constructor(options?: ChallengeEventProperties, user?: string) {
    super(options, user, EntityType.ChallengeEvent);
    if (options?.data) {
      this.update(options.data);
    }
  }

  public update(data: ChallengeEventData): void {
    this.data = { ...data };
  }
}
