import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ButtonState } from '@sidkik/ui';
import { AuthProvider } from '../../services/auth-process.service';

interface LoginForm {
  email: FormControl<string>;
  password: FormControl<string>;
}

export interface LoginAttemptEvent {
  email: string;
  password: string;
}

@Component({
  selector: 'sidkik-pure-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class PureLoginComponent implements OnInit {
  @Input() logoUrl: string | undefined = '';
  @Input() state: ButtonState | null = ButtonState.ready;
  @Input() allowThirdPartyProviders: boolean | null = false;
  // @Input() providers: AuthProvider[] | AuthProvider = AuthProvider.ALL; //  google, facebook, twitter, github as array or all as one single string
  @Input() registrationEnabled = true;
  @Input() messageOnAuthSuccess = '';
  @Input() messageOnAuthError = '';
  @Input() source: string | undefined = '';

  @Output() loginClicked: EventEmitter<LoginAttemptEvent> =
    new EventEmitter<LoginAttemptEvent>();

  loginForm!: FormGroup<LoginForm>;
  authProviders = AuthProvider;
  constructor(private formBuilder: FormBuilder) {}

  /**
   * login handler for the email authentication
   */
  login() {
    this.loginForm.markAllAsTouched();
    if (!this.loginForm.valid) {
      return;
    }
    const event: LoginAttemptEvent = {
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value,
    };
    this.loginClicked.emit(event);
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.nonNullable.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }
}
