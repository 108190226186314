import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { Product, ProductProperties, SKU, SKUProperties } from '../catalog';
import { Coupon } from './coupon';
import { EntityType } from '@sidkik/global';
import { CartItemProperties } from './cart-item';
import { PromoCode } from './promo-code';

export interface CartData {
  items: CartItemProperties[];
  coupon?: Coupon;
  promoCode?: PromoCode;
}

export interface CartProperties extends StorageProperties {
  data: CartData;
  meta: Meta;
  id: string;
}

export class Cart extends Storage implements CartProperties {
  public override data!: CartData;

  constructor(options?: CartProperties, user?: string) {
    super(options, user, EntityType.Cart);
    this.update(options?.data);
  }

  public update(data?: CartData): void {
    const { items } = data || {};
    this.data.items = items || [];
  }
}
