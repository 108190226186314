import { Component, Input, OnInit } from '@angular/core';
export enum AvatarSize {
  medium = 'medium',
  small = 'small',
  large = 'large',
}
@Component({
  selector: 'sidkik-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() imageUrl = '';
  @Input() name = '';
  @Input() size: AvatarSize = AvatarSize.medium;
  sizes = AvatarSize;
  initials = '';

  ngOnInit() {
    if (this.name !== '' && this.name !== null) {
      const allNames = this.name.trim().split(' ');
      this.initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, '');
    }
  }
}
