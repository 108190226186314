import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  authzStateKey,
  cartStateKey,
  globalStateKey,
  shopStateKey,
} from '@sidkik/global';
import { clientLayoutAdapter } from './client-layout.reducer';

export const getGlobalState = createFeatureSelector<any>(globalStateKey);
export const getAuthzState = createFeatureSelector<any>(authzStateKey);
export const getShopState = createFeatureSelector<any>(shopStateKey);

const { selectAll, selectEntities } = clientLayoutAdapter.getSelectors();

export const getCartState = createSelector(getShopState, (state: any) =>
  state ? state[cartStateKey] : null
);

export const getGlobalBreadcrumbTrail = createSelector(
  getGlobalState,
  (state: any) => state.breadcrumbTrail
);

export const getAuthzUser = createSelector(
  getAuthzState,
  (state: any) => state.user
);

export const getCartItems = createSelector(getCartState, (state: any): any[] =>
  state ? selectAll(state) : []
);
