import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthzFacade } from '../../+state/authz.facade';
import { AuthProcessService } from '../../services/auth-process.service';

@Component({
  selector: 'sidkik-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private authProcessService: AuthProcessService,
    private router: Router,
    private authzFacade: AuthzFacade
  ) {}

  ngOnInit(): void {
    this.authProcessService.signOut().then(() => {
      this.authzFacade.tidyOnSignout();
    });
  }
}
