import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { delay, merge, Observable, Subscription, tap } from 'rxjs';
import { SlideOverPanel } from './slide-over-panel';
import { SlideOverPanelComponent } from './slide-over-panel.component';

@Directive({
  selector: '[sidkikSlideOverTrigger],[sidkik-slide-over-trigger]',
  exportAs: 'slideSelectorTrigger',
})
export class SlideOverTriggerDirective implements OnDestroy {
  public isSlideOverOpen = false;
  private overlayRef!: OverlayRef;
  private slideSelectorClosingActionsSub = Subscription.EMPTY;
  private slideSelectorOutsideEventsActionsSub = Subscription.EMPTY;
  private sopChangedSubscription!: Subscription;

  @Input('sidkikSlideOverTrigger')
  public slideSelectorPanel!: SlideOverPanel;
  @Input() closeOnOutsideMouse = false;
  @Output() closing = new EventEmitter<void>();
  @Output() opening = new EventEmitter<void>();
  @ViewChild(SlideOverPanelComponent) cb!: SlideOverPanelComponent;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef
  ) {}

  // ngAfterViewInit(): void {}

  toggleSlideOver(): void {
    this.isSlideOverOpen ? this.destroySlideOver() : this.openSlideOver();
  }

  openSlideOver(): void {
    this.isSlideOverOpen = true;
    this.overlayRef = this.overlay.create({
      hasBackdrop: this.slideSelectorPanel.hasBackdrop,
      backdropClass: this.slideSelectorPanel.backdropClass,
      panelClass: 'cdk-overlay-slide-out-pane',
      // height: '10%',
      // width: '10%',
      positionStrategy: this.overlay
        .position()
        .global()
        .top('0px')
        .right('0px'),
    });
    const templatePortal = new TemplatePortal(
      this.slideSelectorPanel.templateRef,
      this.viewContainerRef
    );
    this.overlayRef.attach(templatePortal);

    this.slideSelectorOutsideEventsActionsSub = this.overlayRef
      .outsidePointerEvents()
      .pipe(delay(50)) // allow the toggle to fire before the mouse event if the mouseevent is from the trigger
      .subscribe(() => {
        if (this.closeOnOutsideMouse) this.destroySlideOver();
      });
    this.slideSelectorClosingActionsSub =
      this.slideSelectorClosingActions().subscribe((data) => {
        this.destroySlideOver();
      });
  }

  private slideSelectorClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    const slideSelectorClosed = this.slideSelectorPanel.closed;

    return merge(backdropClick$, detachment$, slideSelectorClosed);
  }

  private destroySlideOver(): void {
    this.closing.emit();
    if (!this.overlayRef || !this.isSlideOverOpen) {
      return;
    }
    this.slideSelectorClosingActionsSub.unsubscribe();
    this.isSlideOverOpen = false;
    this.overlayRef.detach();
  }

  ngOnDestroy(): void {
    this.closing.emit();
    this.slideSelectorClosingActionsSub.unsubscribe();
    this.slideSelectorOutsideEventsActionsSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    if (this.sopChangedSubscription) {
      this.sopChangedSubscription.unsubscribe();
    }
  }
}
