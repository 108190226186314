import { NgModule } from '@angular/core';
import { IndexedDBModule } from '@sidkik/indexed-db';
import { dbConfig } from './db.config';
import { LocalStorageService } from './services/local-storage.service';

@NgModule({
  imports: [IndexedDBModule.forFeature(dbConfig)],
  providers: [LocalStorageService],
})
export class StorageModule {}
