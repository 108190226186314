import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { TabComponent } from './tab.component';

@Component({
  selector: 'sidkik-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent implements AfterContentInit {
  @Input() vertical = false;
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

  selectedTab!: TabComponent;

  constructor(
    private route: ActivatedRoute,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  ngAfterContentInit() {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      let tabToSelect = this.tabs.first;
      if (params['tab']) {
        tabToSelect =
          this.tabs.find((t) => t.title === params['tab']) ?? tabToSelect;
      }
      this.selectTab(tabToSelect);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selected() {}

  selectFirstIfNoneSelected() {
    if (!this.selectedTab) {
      this.selectFirstTab();
    }
  }

  selectFirstTab() {
    this.selectTab(this.tabs.first);
  }

  selectTab(tab: TabComponent | any) {
    if (!tab) {
      return;
    }
    this.selectedTab = tab;
    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => {
      tab.active = false;
      tab.markForCheck();
    });

    // activate the tab the user has clicked on.
    tab.active = true;
    tab.markForCheck();
  }
}
