import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AssetProperties } from '@sidkik/db';
import {
  AppConfig,
  APP_CONFIG,
  AssetService as GlobalAssetService,
  AssetSize,
} from '@sidkik/global';
import { BehaviorSubject } from 'rxjs';

enum AssetType {
  image = 'image',
  pdf = 'pdf',
  video = 'video',
  vimeo = 'vimeo',
  unknown = 'unknown',
}

const sizeList = [
  AssetSize.small,
  AssetSize.medium,
  AssetSize.large,
  AssetSize.xlarge,
  AssetSize.xxlarge,
];
const sizeToWidth = (size: AssetSize) => {
  switch (size) {
    case AssetSize.small:
      return '250';
    case AssetSize.medium:
      return '480';
    case AssetSize.large:
      return '768';
    case AssetSize.xlarge:
      return '1024';
    case AssetSize.xxlarge:
      return '2400';
    default:
      return '480';
  }
};

const sizeToSizesAttr = (size: AssetSize) => {
  switch (size) {
    case AssetSize.small:
      return '(max-width: 320px) 250px';
    case AssetSize.medium:
      return '(max-width: 480px) 480px';
    case AssetSize.large:
      return '(max-width: 768px) 768px';
    case AssetSize.xlarge:
      return '(max-width: 1024px) 1024px';
    case AssetSize.xxlarge:
      return '100vw';
    default:
      return '(max-width: 480px) 480px';
  }
};

const sizeToFactor = (size: AssetSize) => {
  switch (size) {
    case AssetSize.small:
      return '1x';
    case AssetSize.medium:
      return '2x';
    case AssetSize.large:
      return '3x';
    default:
      return '1x';
  }
};

@Component({
  selector: 'sidkik-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss'],
})
export class AssetComponent implements OnInit, OnChanges {
  @Input() asset: AssetProperties | undefined;
  @Input() thumbOnly = false;
  @Input() isBackground = false;
  @Input() isResponsive = false;
  @Input() preset = '';
  @Input() class!: string;
  @Input() size: AssetSize | string = AssetSize.medium;
  @Input() customRender = '';

  loaderParams$: BehaviorSubject<any | undefined> = new BehaviorSubject<
    any | undefined
  >(undefined);

  src$: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);

  sizes = AssetSize;
  assetTypes = AssetType;
  initials = '';

  videoOptions = {
    query: { title: false, portrait: false, byline: false, responsive: true },
  };

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private globalAssetService: GlobalAssetService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { asset } = changes;
    if (asset) {
      const { currentValue, previousValue } = asset;
      if (currentValue?.id !== previousValue?.id && previousValue) {
        this.loaderParams$.next(undefined);
        setTimeout(() => {
          this.src$.next(this.getNGSrc());
          this.processLoaderParams();
        });
      } else {
        this.processLoaderParams();
        this.src$.next(this.getNGSrc());
      }
    }
  }

  ngOnInit(): void {
    this.processLoaderParams();
  }

  handleMissing(): void {
    console.log('replace missing image');
    this.src$.next('missing.svg');
    // const img: HTMLImageElement = (this.regularImage as unknown as ElementRef)
    //   .nativeElement;
    // // set the src to data url that is light grey
    // const dataSrc = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect fill='%23f0f0f0' width='1' height='1'/%3E%3C/svg%3E`;
    // // img.src = dataSrc;
    // console.log('missing image', img.src);
  }

  processLoaderParams(): void {
    if (!this.asset || this.asset === null || !this.asset.data) {
      return;
    }

    const params: any = {
      token: this.asset.data.token,
    };

    if (this.preset !== '') {
      params['preset'] = this.preset;
    }

    console.log('updating the image loader params', params);

    // check if the last update is the same as this update
    const lastParams = this.loaderParams$.getValue();
    if (lastParams && lastParams.token === params.token) {
      return;
    }

    this.loaderParams$.next(params);
  }

  get assetType(): AssetType {
    const type = this.asset?.data?.type ?? '';
    switch (true) {
      case type.indexOf('image') !== -1:
        return AssetType.image;
      case type.indexOf('application') !== -1:
        return AssetType.pdf;
      case type.indexOf('video') !== -1: {
        if (this.asset?.integrations?.vimeo) {
          return AssetType.vimeo;
        }
        return AssetType.video;
      }
      default:
        return AssetType.unknown;
    }
  }

  getPDF(asset: AssetProperties): string {
    return '';
  }

  getVideo(asset: AssetProperties): string {
    return '';
  }

  getNGSrc(): string {
    if (!this.asset || this.asset === null || !this.asset.data) {
      return '';
    }
    // if no token, then it is a public asset - like shared starter logo images
    if (!this.asset.data.token) {
      return this.asset.data.url;
    }
    return this.asset.data.key ?? '';
  }

  getVideoThumbnail(): string {
    const videoThumb = this.asset?.data?.thumbnails?.find(
      (thumb) => thumb.thumbnailSize === AssetSize.large
    );
    if (!videoThumb) {
      return '';
    }
    let url = this.globalAssetService.getAssetImageURL(
      videoThumb.key ?? '',
      videoThumb.token ?? ''
    );
    if (this.preset !== '') {
      url += `&p=${this.preset}`;
    }
    if (this.customRender !== '') {
      url += `${this.customRender}`;
    }
    return url;
  }

  getImageURL(): string {
    if (!this.asset) {
      return '';
    }
    // conversion asset from before tenancy
    if (
      !this.asset.data.token &&
      this.asset.data.thumbnails &&
      this.asset.data.thumbnails.length
    ) {
      const thumb = this.asset?.data?.thumbnails?.find(
        (thumb) => thumb.thumbnailSize === AssetSize.large
      );
      if (!thumb) {
        return '';
      }
      let url = this.globalAssetService.getAssetImageURL(
        thumb.key ?? '',
        thumb.token ?? ''
      );
      if (this.preset !== '') {
        url += `&p=${this.preset}`;
      }
      if (this.customRender !== '') {
        url += `${this.customRender}`;
      }
      return url;
    }
    // if no token, then it is a public asset - like shared starter logo images
    if (!this.asset.data.token) {
      return this.asset.data.url;
    }
    let url = this.globalAssetService.getAssetImageURL(
      this.asset?.data.key ?? '',
      this.asset?.data.token ?? ''
    );
    if (this.preset !== '') {
      url += `&p=${this.preset}`;
    }
    if (this.customRender !== '') {
      url += `${this.customRender}`;
    }

    return url;
  }

  getImageSizes(): string {
    if (this.isResponsive) {
      return '(max-width: 600px) 480px,(max-width: 800px) 600px, (max-width: 1200px) 800px, 100vw';
    }

    return '';
  }

  getImageSrcSet(): string {
    const customRender = this.customRender !== '' ? this.customRender : '';
    if (this.isResponsive) {
      const imgs = sizeList.map(
        (t) =>
          `${this.globalAssetService.getAssetImageURL(
            this.asset?.data.key ?? '',
            this.asset?.data.token ?? ''
          )}${customRender}&gravity=auto&w=${sizeToWidth(t)} ${sizeToWidth(t)}w`
      );
      return imgs.join(',');
    }
    return '';
  }

  getVideoBackgroundStyle(): string {
    const videoThumb = this.asset?.data?.thumbnails?.find(
      (thumb) => thumb.thumbnailSize === AssetSize.large
    );
    if (!videoThumb) {
      return '';
    }
    const style = [];
    const preset = this.preset !== '' ? `&p=${this.preset}` : '';
    const customRender = this.customRender !== '' ? this.customRender : '';
    const factored = sizeList.map(
      (t) =>
        `url("${this.globalAssetService.getAssetImageURL(
          videoThumb.key ?? '',
          videoThumb.token ?? ''
        )}")${preset}${customRender}&gravity=smart&w=${sizeToWidth(
          t
        )} ${sizeToFactor(t)}`
    );

    style.push(`background-image: url("${this.getVideoThumbnail()}")`);
    if (this.isResponsive) {
      style.push(`background-image: image-set(${factored.join(',')})`);
      style.push(`background-image: -webkit-image-set(${factored.join(',')})`);
    }

    return style.join(';');
  }

  getBackgroundStyle(): string {
    const style = [];
    const preset = this.preset !== '' ? `&p=${this.preset}` : '';
    const customRender = this.customRender !== '' ? this.customRender : '';
    const factored = sizeList.map(
      (t) =>
        `url("${this.globalAssetService.getAssetImageURL(
          this.asset?.data.key ?? '',
          this.asset?.data.token ?? ''
        )}")${preset}${customRender}&gravity=smart&w=${sizeToWidth(
          t
        )} ${sizeToFactor(t)}`
    );

    style.push(`background-image: url("${this.getImageURL()}")`);
    if (this.isResponsive) {
      style.push(`background-image: image-set(${factored.join(',')})`);
      style.push(`background-image: -webkit-image-set(${factored.join(',')})`);
    }
    // explicit width not supported yet
    // style.push(`background-image: image-set(${explicit.join(',')})`);

    return style.join(';');
  }

  getImageAlt(): string {
    return '';
  }
}
