import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'sidkik-tab',
  template: `
    <div [hidden]="!active" class="tw-mt-2 tw-w-full tw-h-full">
      <ng-content></ng-content>
    </div>
  `,
})
export class TabComponent {
  @Input() title = '';
  @Input() active = false;
  @Input() infoBadgeCount = -1;
  @Input() warnBadgeCount = -1;
  @Input() errorBadgeCount = -1;

  constructor(private changeDetectionRef: ChangeDetectorRef) {}

  // force a change detection
  public markForCheck() {
    this.changeDetectionRef.markForCheck();
  }
}
