import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';

export const toastyEnter = trigger('toasty-enter', [
  // transition('void => false', []),
  transition(':enter', [
    style({ transform: 'translateX(20rem)', opacity: 0 }),
    animate(
      '300ms ease-out',
      style({ transform: 'translateX(0)', opacity: 1 })
    ),
  ]),
]);

export const toastyLeave = trigger('toasty-leave', [
  state('closing', style({ transform: 'translateX(0)', opacity: 1 })),
  transition('void => *', []),
  transition('* => closing', [
    animate(
      '100ms ease-in',
      style({ transform: 'translateX(20rem)', opacity: 0 })
    ),
  ]),
]);
