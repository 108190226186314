import { Component, OnInit } from '@angular/core';
import { DbService } from '@sidkik/db';

@Component({
  selector: 'sidkik-flush',
  template: `<div>
    Attempting to flush... and reload (may take up to a minute)
  </div>`,
})
export class FlushComponent implements OnInit {
  constructor(private dbService: DbService) {}

  async ngOnInit() {
    await this.dbService.debugStartOver();
  }
}
