export const loadInfoType = '[affiliate/info] load info';
export const loadInfoSuccessType = '[affiliate/info] load info success';
export const loadInfoFailureType = '[affiliate/info] load info failure';

/** AffiliateSale */

export const loadAffiliateSalesByAffiliateType =
  '[affiliate/sale] load sales by affiliate';
export const loadAffiliateSalesByAffiliateSuccessType =
  '[affiliate/sale] load sales by affiliate success';
export const loadAffiliateSalesByAffiliateFailureType =
  '[affiliate/sale] load sales by affiliate failure';

export const updateAffiliateSaleType = '[affiliate/sale] update sale';
export const updateAffiliateSaleProgressType =
  '[affiliate/sale] update sale progress';
export const updateAffiliateSaleSuccessType =
  '[affiliate/sale] update sale success';
export const updateAffiliateSaleFailureType =
  '[affiliate/sale] update sale failure';

export const deleteAffiliateSaleType = '[affiliate/sale] delete sale';
export const deleteAffiliateSaleSuccessType =
  '[affiliate/sale] delete sale success';
export const deleteAffiliateSaleFailureType =
  '[affiliate/sale] delete sale failure';

export const loadAffiliateSalesAndSelectAffiliateSaleType =
  '[affiliate/sale] load sales and select sale';
export const loadAffiliateSalesAndSelectAffiliateSaleSuccessType =
  '[affiliate/sale] load sales and select sale success';
export const loadAffiliateSalesAndSelectAffiliateSaleFailureType =
  '[affiliate/sale] load sales and select sale failure';

export const selectAffiliateSaleType = '[affiliate/sale] select sale';

export const updateAffiliateSaleIntegrationsType =
  '[affiliate/sale] update sale integrations';
export const updateAffiliateSaleIntegrationsSuccessType =
  '[affiliate/sale] update sale integrations success';
export const updateAffiliateSaleIntegrationsFailureType =
  '[affiliate/sale] update sale integrations failure';

/** AffiliatePayout */

export const loadAffiliatePayoutsByAffiliateType =
  '[affiliate/payout] load payouts by affiliate';
export const loadAffiliatePayoutsByAffiliateSuccessType =
  '[affiliate/payout] load payouts by affiliate success';
export const loadAffiliatePayoutsByAffiliateFailureType =
  '[affiliate/payout] load payouts by affiliate failure';

export const updateAffiliatePayoutType = '[affiliate/payout] update payout';
export const updateAffiliatePayoutLocalOnlyType =
  '[affiliate/payout] update payout local only';
export const updateAffiliatePayoutProgressType =
  '[affiliate/payout] update payout progress';
export const updateAffiliatePayoutSuccessType =
  '[affiliate/payout] update payout success';
export const updateAffiliatePayoutFailureType =
  '[affiliate/payout] update payout failure';

export const deleteAffiliatePayoutType = '[affiliate/payout] delete payout';
export const deleteAffiliatePayoutSuccessType =
  '[affiliate/payout] delete payout success';
export const deleteAffiliatePayoutFailureType =
  '[affiliate/payout] delete payout failure';

export const loadAffiliatePayoutsAndSelectAffiliatePayoutType =
  '[affiliate/payout] load payouts and select payout';
export const loadAffiliatePayoutsAndSelectAffiliatePayoutSuccessType =
  '[affiliate/payout] load payouts and select payout success';
export const loadAffiliatePayoutsAndSelectAffiliatePayoutFailureType =
  '[affiliate/payout] load payouts and select payout failure';

export const selectAffiliatePayoutType = '[affiliate/payout] select payout';

export const updateAffiliatePayoutIntegrationsType =
  '[affiliate/payout] update payout integrations';
export const updateAffiliatePayoutIntegrationsSuccessType =
  '[affiliate/payout] update payout integrations success';
export const updateAffiliatePayoutIntegrationsFailureType =
  '[affiliate/payout] update payout integrations failure';
