import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function equalityValidator(
  firstControlName: string,
  secondControlName: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // debugger;
    const firstControl = control.get(firstControlName);
    const secondControl = control.get(secondControlName);
    if (secondControl?.errors && !secondControl.errors['equalityValidator']) {
      return null;
    }
    if (firstControl?.value !== secondControl?.value) {
      secondControl?.setErrors({ equalityValidator: true });
    }
    return null;
  };
}
