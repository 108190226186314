import { OverlayRef } from '@angular/cdk/overlay';

export class ToastReference {
  constructor(private readonly overlay: OverlayRef) {}

  close() {
    this.overlay.dispose();
  }

  isVisible() {
    return this.overlay?.overlayElement !== null;
  }

  getPosition() {
    return this.overlay?.overlayElement?.getBoundingClientRect();
  }
}
