import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { DBStateKey } from '@sidkik/global';

import * as DBActions from './db.actions';
import { DBEntity } from './db.models';

export const DB_FEATURE_KEY = DBStateKey;

export interface DBState extends EntityState<DBEntity> {
  initialized: boolean; // has the db been initialized for authenticated activity
  error?: string | null; // last known error (if any)
}

export interface DBPartialState {
  readonly [DB_FEATURE_KEY]: DBState;
}

export const dbAdapter: EntityAdapter<DBEntity> =
  createEntityAdapter<DBEntity>();

export const initialDBState: DBState = dbAdapter.getInitialState({
  // set initial required properties
  initialized: false,
});

const reducer = createReducer(
  initialDBState,
  on(DBActions.initializeDB, (state) => ({
    ...state,
    initialized: false,
    error: null,
  })),
  on(DBActions.initializeDBSuccess, (state) => ({
    ...state,
    initialized: true,
    error: null,
  })),
  on(DBActions.initializeDBFailure, (state, { error }) => ({
    ...state,
    initialized: false,
    error,
  }))
);

export function dbReducer(state: DBState | undefined, action: Action) {
  return reducer(state, action);
}
