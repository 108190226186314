import { DOCUMENT } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Inject,
} from '@angular/core';

@Component({
  selector: 'sidkik-tracker',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerComponent {
  @Input() linkedInInsightsId: string | undefined;
  @Input() fbPixelId: string | undefined;
  @Input() activeCampaignId: string | undefined;
  @Input() clarityId: string | undefined;
  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    if (this.linkedInInsightsId && this.linkedInInsightsId !== '') {
      const script = `
      _linkedin_partner_id = "__LINKEDIN_INSIGHTS_ID__";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      (function(l) {
      if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
      window.lintrk.q=[]}
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);})(window.lintrk);
      `;
      const noScript = `
      <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=__LINKEDIN_INSIGHTS_ID__&fmt=gif" />
      `;
      const scriptElement = this.document.createElement('script');
      scriptElement.innerHTML = script.replace(
        '__LINKEDIN_INSIGHTS_ID__',
        this.linkedInInsightsId
      );
      scriptElement.type = 'text/javascript';
      this.document.body.appendChild(scriptElement);

      const noScriptElement = this.document.createElement('noscript');
      noScriptElement.innerHTML = noScript.replace(
        '__LINKEDIN_INSIGHTS_ID__',
        this.linkedInInsightsId
      );
      this.document.body.appendChild(noScriptElement);
      logger.trace('ui:tracker:linkedin', 'injected:', this.linkedInInsightsId);
    }

    if (this.fbPixelId && this.fbPixelId !== '') {
      const script = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '__FB_PIXEL_ID__'); 
      fbq('track', 'PageView');
      `;
      const noScript = `
      <img height="1" width="1" src="https://www.facebook.com/tr?id=__FB_PIXEL_ID__&ev=PageView&noscript=1"/>
      `;
      const scriptElement = this.document.createElement('script');
      scriptElement.innerHTML = script.replace(
        '__FB_PIXEL_ID__',
        this.fbPixelId
      );
      scriptElement.type = 'text/javascript';
      this.document.body.appendChild(scriptElement);

      const noScriptElement = this.document.createElement('noscript');
      noScriptElement.innerHTML = noScript.replace(
        '__FB_PIXEL_ID__',
        this.fbPixelId
      );
      this.document.body.appendChild(noScriptElement);
      logger.trace('ui:tracker:fb', 'injected:', this.fbPixelId);
    }

    if (this.activeCampaignId && this.activeCampaignId !== '') {
      const script = `
      (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
      vgo('setAccount', '__ACTIVE_CAMPAIGN_ID__');
      vgo('setTrackByDefault', true);

      vgo('process');
    `;
      const scriptElement = this.document.createElement('script');
      scriptElement.innerHTML = script.replace(
        '__ACTIVE_CAMPAIGN_ID__',
        this.activeCampaignId
      );
      scriptElement.type = 'text/javascript';
      this.document.body.appendChild(scriptElement);
      logger.trace('ui:tracker:ac', 'injected:', this.activeCampaignId);
    }

    if (this.clarityId && this.clarityId !== '') {
      const script = `
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "__CLARITY_ID__");
    `;
      const scriptElement = this.document.createElement('script');
      scriptElement.innerHTML = script.replace(
        '__CLARITY_ID__',
        this.clarityId
      );
      scriptElement.type = 'text/javascript';
      this.document.body.appendChild(scriptElement);
      logger.trace('ui:tracker:clarity', 'injected:', this.clarityId);
    }
  }
}
