import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authzStateKey } from '@sidkik/global';
import { DB_FEATURE_KEY, DBState } from './db.reducer';

// Lookup the 'DB' feature state managed by NgRx
export const getDBState = createFeatureSelector<DBState>(DB_FEATURE_KEY);
export const getAuthzState = createFeatureSelector<any>(authzStateKey);
export const getDBInitialized = createSelector(
  getDBState,
  (state: DBState) => state.initialized
);

export const getDBError = createSelector(
  getDBState,
  (state: DBState) => state.error
);

export const getLoggedIn = createSelector(
  getAuthzState,
  (state: any) => state.loggedIn
);

export const getLoggedInUser = createSelector(
  getAuthzState,
  (state: any) => state.user
);
