import { StorageProperties, Storage } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';
import { BookingChangeStatus, BookingChangeType } from './common';
import { AppointmentRequest } from '../shop';

export interface BookingChangeData {
  bookingId: string;
  customerId: string;
  appointmentRequest?: AppointmentRequest;
  changeType: BookingChangeType;
  status: BookingChangeStatus;
  issue?: string;
  productId?: string;
}

export interface BookingChangeProperties extends StorageProperties {
  data: BookingChangeData;
  meta: Meta;
  id: string;
  tenant?: string;
}

export class BookingChange extends Storage implements BookingChangeProperties {
  public override data!: BookingChangeData;

  constructor(options?: BookingChangeProperties, user?: string) {
    super(options, user, EntityType.BookingChange);
    this.update(options?.data);
  }

  public update(data?: BookingChangeData): void {
    this.data = {
      ...(this.data ?? {}),
      ...data,
    } as unknown as BookingChangeData;
  }
}

export class AdminBookingChange
  extends Storage
  implements BookingChangeProperties
{
  public override data!: BookingChangeData;

  constructor(options?: BookingChangeProperties, user?: string) {
    super(options, user, EntityType.AdminBookingChange);
    this.update(options?.data);
  }

  public update(data?: BookingChangeData): void {
    this.data = {
      ...(this.data ?? {}),
      ...data,
    } as unknown as BookingChangeData;
  }
}
