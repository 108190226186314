import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalFacade } from '../+state/global.facade';
import { Title } from '@angular/platform-browser';
import { APP_CONFIG, AppConfig } from '../models/config';

export interface TrailItem {
  name: string;
  location?: string;
}

export const urlPartToTrailMap = new Map<string, TrailItem>([
  ['auth', { name: 'Authentication', location: '/auth' }],
  ['community', { name: 'Community', location: '/community' }],
  ['people', { name: 'People', location: '/people' }],
  ['customers', { name: 'Customers' }],
  ['integrations', { name: 'Integrations', location: '/integrations' }],
  ['analytics', { name: 'Analytics', location: '/analytics' }],
  ['library', { name: 'Library', location: '/library' }],
  ['pages', { name: 'Pages' }],
  ['spaces', { name: 'Spaces' }],
  ['courses', { name: 'Courses' }],
  ['quickbooks', { name: 'Quickbooks' }],
  ['vimeo', { name: 'Vimeo' }],
  ['stripe', { name: 'Stripe' }],
  ['active-campaign', { name: 'ActiveCampaign' }],
  ['my-activities', { name: 'My Activities' }],
  ['config', { name: 'Config' }],
  ['assets', { name: 'Assets' }],
  ['edit', { name: 'Edit' }],
  ['list', { name: 'All' }],
]);

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  constructor(
    private router: Router,
    private globalFacade: GlobalFacade,
    private titleService: Title,
    @Inject('ADMIN_TOOL') readonly isAdminTool: boolean,
    @Inject(APP_CONFIG) readonly appConfig: AppConfig
  ) {
    this.init();
  }

  init() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((d) => {
        const trail = this.convertPartsToTrail(d as NavigationEnd);
        this.globalFacade.addBreadCrumbs(trail);
        if (this.isAdminTool) {
          this.titleService.setTitle(
            'Sidkik ' + (d as NavigationEnd).url.split('/').join(' | ')
          );
        } else {
          this.titleService.setTitle(
            this.appConfig?.branding?.siteName +
              ' - ' +
              trail.map((t) => t.name ?? '').join(' | ')
          );
        }
      });
  }

  convertPartsToTrail(e: NavigationEnd): TrailItem[] {
    const items: TrailItem[] = [];
    const parts = e.urlAfterRedirects.split('/');
    for (let i = 0; i < parts.length; i++) {
      const p = parts[i];
      if (p != '') {
        let trail = urlPartToTrailMap.get(p);
        if (trail) {
          if (!trail.location) {
            trail = { ...trail, location: parts.slice(0, i + 1).join('/') };
          }
          items.push(trail);
        }
      }
    }
    // remove url from last known part
    if (items.length > 1 && items[items.length - 1]) {
      delete items[items.length - 1].location;
    }

    return items;
  }
}
