import { AppConfig, SpanTypes, TraceService } from '@sidkik/global';

const template = `
  <div>
    <div class="tw-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
      <div class="tw-text-2xl tw-font-bold">Unable to load at this time</div>
      <div class="tw-text-xl tw-mb-5">Your browser has been identified as possibly malicious</div>
      <div class="tw-max-w-[50vw] tw-text-center">
        <p>If this is the first time accessing this domain or a newly created Sidkik Platform, this is typically resolved within 60 seconds. We will automatically reload the page within 30 seconds to attempt resolution.</p>
      </div>
    </div>
  </div>
`;

const templateFailedReload = `
  <div>
    <div class="tw-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center">
      <div class="tw-text-2xl tw-font-bold">Unable to load at this time</div>
      <div class="tw-text-xl tw-mb-5">Your browser has been identified as possibly malicious</div>
      <div class="tw-max-w-[50vw] tw-text-center">
        <p class="tw-mb-4">We have reloaded several times and are unable to verify your browser at this time. Please contact Sidkik support for further assitance.</p>
        <p class="tw-mb-4">You can also try disabling any privacy blockers to see if you are able to access the site. We apologize for any inconvenience.</p>
        <p>This error has been logged and we will investigate further.</p>
      </div>
    </div>
  </div>
`;

export const appCheckError = (
  doc: Document,
  ts: TraceService,
  appConfig: AppConfig
) => {
  const path = doc.location.pathname;
  const search = doc.location.search;
  // parse reload value from search
  const reloadMatch = search.match(/reload=(\d+)/);
  let reloadAttempt = 0;
  if (reloadMatch) {
    const reload = parseInt(reloadMatch[1]);
    reloadAttempt = reload;
    if (reload > 5) {
      const appCheckError = convertTemplateToElement(templateFailedReload);
      doc.body.appendChild(appCheckError);
      if (ts) {
        const err = new Error(
          `app check failed to load for domain: ${doc.location.hostname} after ${reload} attempts. Tenant: ${appConfig.firebase.tenantId} Project: ${appConfig.firebase.projectId}`
        );
        ts.startSpan(SpanTypes.errorReporter);
        ts.endSpan(SpanTypes.errorReporter, err);
      }
      return;
    }
  }

  const appCheckError = convertTemplateToElement(template);
  doc.body.appendChild(appCheckError);
  setTimeout(() => {
    reloadAttempt++;
    doc.location.href = doc.location.pathname + '?reload=' + reloadAttempt;
  }, 30000);
};

function convertTemplateToElement(template: string): HTMLElement {
  const parser = new DOMParser();
  const parsed = parser.parseFromString(template, 'text/html');
  return parsed.body.firstChild as HTMLElement;
}
