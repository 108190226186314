<div @toasty-enter [@toasty-leave]="closing" (@toasty-leave.done)="onFadeFinished($event)" aria-live="assertive"
  class="tw-pointer-events-none tw-w-[24rem] tw-inset-0 tw-flex tw-items-end tw-px-2 tw-py-2 sm:tw-items-start sm:tw-p-2">
  <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-space-y-4 sm:tw-items-end">
    <div
      class="tw-pointer-events-auto tw-w-full tw-max-w-sm tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
      <div class="tw-p-4">
        <div class="tw-flex tw-items-start">
          <div class="tw-flex-shrink-0">
            <div class="tw-h-6 tw-w-6">
              <sidkik-icon class="tw-h-6 tw-w-6 tw-text-info-400" svgIcon="info_circle"></sidkik-icon>
            </div>
          </div>
          <div class="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
            <p class="tw-text-sm tw-font-medium tw-text-info-700">{{ toastData.title }}</p>
            <p class="tw-mt-1 tw-text-sm tw-text-gray-500">{{ toastData.subTitle }}</p>
          </div>
          <div class="tw-ml-4 tw-flex tw-flex-shrink-0">
            <button (click)="close()" type="button"
              class="tw-inline-flex tw-rounded-md tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2">
              <span class="tw-sr-only">Close</span>
              <!-- Heroicon name: mini/x-mark -->
              <svg class="tw-h-5 tw-w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                aria-hidden="true">
                <path
                  d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>