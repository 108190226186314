import { Pipe, PipeTransform } from '@angular/core';
import { ComboBoxOption } from '../components/combo-box/combo-box.component';

@Pipe({
  name: 'optionLookupLabel',
})
export class OptionLookupLabelPipe implements PipeTransform {
  transform(
    value: any,
    lookups: ComboBoxOption[] | null | undefined,
    defaultPlaceholder: string | undefined
  ): string {
    // if incoming array, convert to string
    if (Array.isArray(value)) {
      value = value[0];
    }

    return (
      lookups?.find((lu) => {
        const isObject = (objLike: any) =>
          objLike &&
          typeof objLike === 'object' &&
          objLike.constructor === Object;

        const v1IsObject = isObject(value);
        const v2IsObject = isObject(lu.value);
        if (v1IsObject) {
          return value['id'] === lu.value['id'];
        }
        if (v2IsObject) {
          return value === lu.value['id'];
        }
        return lu.value === value;
      })?.label ??
      defaultPlaceholder ??
      'Please select'
    );
  }
}
