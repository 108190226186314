import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';
import { PageLayout } from './page-layout';
import { AssetProperties } from './asset';

export interface TagData {
  title: string;
  slug: string;
  isDefault?: boolean;
  useDefault?: boolean;
  content?: PageLayout;
  overrideImage?: boolean;
  image?: AssetProperties;
  allowOverflow?: boolean;
}

export interface TagProperties extends StorageProperties {
  data: TagData;
  meta: Meta;
  id: string;
}

export class Tag extends Storage implements TagProperties {
  public override data!: TagData;

  constructor(options?: TagProperties, user?: string) {
    super(options, user, EntityType.Tag);
    this.update(options?.data);
  }

  public update(data?: TagData): void {
    this.data = { ...data } as unknown as TagData;
    this.data.content = this.data.content || {};
  }
}
