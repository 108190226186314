import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MapId, mapStateKey, clientLayoutStateKey } from '@sidkik/global';
import { ClientLayoutState } from '../client-layout.reducer';
import { State, selectAll, selectEntities } from './map.reducer';

export const getClientLayoutState =
  createFeatureSelector<ClientLayoutState>(clientLayoutStateKey);

export const getState = createSelector(
  getClientLayoutState,
  (state: ClientLayoutState) => state[mapStateKey]
);

export const getMapLoaded = createSelector(getState, (state: State) => {
  return state?.loaded;
});

export const getMapLoading = createSelector(getState, (state: State) => {
  return state?.loading;
});

export const getMapError = createSelector(
  getState,
  (state: State) => state.error
);

export const getAllMaps = createSelector(getState, (state: State) => {
  return selectAll(state);
});

export const getMapEntities = createSelector(getState, (state: State) =>
  selectEntities(state)
);

export const getSelectedId = createSelector(
  getState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getMapEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getNavMap = createSelector(
  getMapEntities,
  (entities) => entities[MapId.navMap]
);
