import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { RouterModule } from '@angular/router';
import { AuthzModule } from '@sidkik/authz';
import { UIModule } from '@sidkik/ui';
import { EffectsModule } from '@ngrx/effects';
import * as fromLayout from './+state/client-layout.reducer';
import { StoreModule } from '@ngrx/store';
import { PureUserInfoComponent } from './components/user-info/pure-user-info.component';
import { ContainerComponent } from './components/container/container.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { ClientLayoutFacade } from './+state/client-layout.facade';
import { MapEffects } from './+state/map/map.effects';
import { MapFacade } from './+state/map/map.facade';
import { LetDirective } from '@ngrx/component';

const pureComponents = [PureUserInfoComponent];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(
      fromLayout.CLIENT_LAYOUT_FEATURE_KEY,
      fromLayout.clientLayoutReducer
    ),
    EffectsModule.forFeature([MapEffects]),
    UIModule,
    AuthzModule,
    CdkScrollableModule,
    LetDirective,
  ],
  declarations: [ContainerComponent, UserInfoComponent, ...pureComponents],
  exports: [ContainerComponent],
  providers: [ClientLayoutFacade, MapFacade],
})
export class ClientLayoutModule {}
