import { Observable, Subscriber } from 'rxjs';

import { InjectionToken } from '@angular/core';
import { IdService } from '@sidkik/global';

export interface DBConfig {
  name: string;
  version: number;
  objectStoresMeta: ObjectStoreMeta[];
  migrationFactory?: () => {
    [key: number]: (db: IDBDatabase, transaction: IDBTransaction) => void;
  };
}

export interface ObjectStoreMeta {
  store: string;
  storeConfig: {
    keyPath: string | string[];
    autoIncrement: boolean;
    [key: string]: any;
  };
  storeSchema: ObjectStoreSchema[];
}

export interface ObjectStoreSchema {
  name: string;
  keypath: string | string[];
  options: { unique: boolean; [key: string]: any };
}

export interface IndexDetails {
  indexName: string;
  order: string;
}

export interface RequestEvent<T> extends Event {
  target: RequestEventTarget<T>;
}

export interface RequestEventTarget<T> extends EventTarget {
  result: T | T[];
}

export enum DBMode {
  readonly = 'readonly',
  readwrite = 'readwrite',
}

export type Key =
  | string
  | number
  | Date
  | ArrayBufferView
  | ArrayBuffer
  | IDBValidKey
  | IDBKeyRange;

export type WithID = { id: number };

export const CONFIG_TOKEN = new InjectionToken<DBConfig>('indexed-db-config');

export function openDatabase(
  indexedDB: IDBFactory,
  dbName: string,
  version?: number,
  upgradeCallback?: (a: Event, b: IDBDatabase) => void
): Promise<IDBDatabase> {
  return new Promise<IDBDatabase>((resolve, reject) => {
    if (!indexedDB) {
      reject('IndexedDB not available');
    }
    const request = indexedDB.open(dbName, version);
    let db: IDBDatabase;
    request.onsuccess = (event: Event) => {
      db = request.result;
      resolve(db);
    };
    request.onerror = (event: Event) => {
      reject(`IndexedDB error: ${request.error}`);
    };
    if (typeof upgradeCallback === 'function') {
      request.onupgradeneeded = (event: Event) => {
        upgradeCallback(event, db);
      };
    }
  });
}

export function CreateObjectStore(
  indexedDB: IDBFactory,
  dbName: string,
  version: number,
  storeSchemas: ObjectStoreMeta[],
  migrationFactory?: () => {
    [key: number]: (db: IDBDatabase, transaction: IDBTransaction) => void;
  }
): void {
  if (!indexedDB) {
    return;
  }
  const request: IDBOpenDBRequest = indexedDB.open(dbName, version);

  request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
    const database: IDBDatabase = (event.target as any).result;
    const transaction: IDBTransaction = (event.target as any).transaction;

    storeSchemas.forEach((storeSchema: ObjectStoreMeta) => {
      if (!database.objectStoreNames.contains(storeSchema.store)) {
        const objectStore = database.createObjectStore(
          storeSchema.store,
          storeSchema.storeConfig
        );
        storeSchema.storeSchema.forEach((schema: ObjectStoreSchema) => {
          objectStore.createIndex(schema.name, schema.keypath, schema.options);
        });
      } else {
        // check for key changes

        const objectStore = transaction.objectStore(storeSchema.store);
        const indexList = Array.from(objectStore.indexNames);

        // modify or remove
        indexList.forEach((name: string) => {
          const idx = objectStore.index(name);
          let foundIt = false;
          storeSchema.storeSchema.forEach((schema) => {
            // if exists
            if (idx.name === schema.name) {
              foundIt = true;
              if (
                idx.keyPath !== schema.keypath ||
                idx.unique !== schema.options.unique
              ) {
                // got a change - delete the existing and recreate
                objectStore.deleteIndex(name);
                objectStore.createIndex(
                  schema.name,
                  schema.keypath,
                  schema.options
                );
              }
              return;
            }
          });
          if (!foundIt) {
            // delete it
            objectStore.deleteIndex(name);
          }
        });

        storeSchema.storeSchema.forEach((schema) => {
          // not in list - add index
          if (!indexList.includes(schema.name)) {
            objectStore.createIndex(
              schema.name,
              schema.keypath,
              schema.options
            );
          }
        });
      }
    });

    const storeMigrations = migrationFactory && migrationFactory();
    if (storeMigrations) {
      if (request.transaction !== null) {
        const transaction = request.transaction;
        Object.keys(storeMigrations)
          .map((k) => parseInt(k, 10))
          .filter((v) => v > event.oldVersion)
          .sort((a, b) => a - b)
          .forEach((v) => {
            storeMigrations[v](database, transaction);
          });
      }
    }

    database.close();
  };

  request.onsuccess = (e: any) => {
    e.target.result.close();
  };
}

export function DeleteObjectStore(
  dbName: string,
  version: number,
  storeName: string
): Observable<boolean> {
  if (!dbName || !version || !storeName) {
    throw Error('Params: "dbName", "version", "storeName" are mandatory.');
  }

  return new Observable<boolean>((obs: Subscriber<boolean>) => {
    try {
      const newVersion = version + 1;
      const request: IDBOpenDBRequest = indexedDB.open(dbName, newVersion);
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const database: IDBDatabase = (event.target as any).result;

        database.deleteObjectStore(storeName);
        database.close();
        obs.next(true);
        obs.complete();
      };

      request.onerror = (e) => obs.error(e);
    } catch (error) {
      obs.error(error);
    }
  });
}

export interface Options {
  storeName: string;
  dbMode: IDBTransactionMode;
  error: (e: Event) => any;
  complete?: (e: Event) => any;
  abort?: any;
}

export function validateStoreName(db: IDBDatabase, storeName: string): boolean {
  return db.objectStoreNames.contains(storeName);
}

export function validateBeforeTransaction(
  db: IDBDatabase,
  storeName: string,
  reject: (message: string) => void
): void {
  if (!db) {
    reject(
      'You need to use the openDatabase function to create a database before you query it!'
    );
  }
  if (!validateStoreName(db, storeName)) {
    reject(`objectStore does not exists: ${storeName}`);
  }
}

export function createTransaction(
  db: IDBDatabase,
  options: Options
): IDBTransaction {
  const trans: IDBTransaction = db.transaction(
    options.storeName,
    options.dbMode
  );
  trans.onerror = options.error;
  trans.onabort = options.abort;
  return trans;
}

export function optionsGenerator(
  type: any,
  storeName: any,
  reject: (reason?: any) => void,
  resolve?: (e: any) => void
): Options {
  return {
    storeName,
    dbMode: type,
    error: (e: Event) => {
      reject(e);
    },
    abort: (e: Event) => {
      reject(e);
    },
  };
}
