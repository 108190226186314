import { SectionProgress } from './section-progress';
import { Meta } from '../meta';
import { Storage, StorageProperties } from '../storage';
import { EntityType } from '@sidkik/global';

export interface CourseProgressData {
  course?: string;
  sections?: SectionProgress[];
  lastSection?: string;
  lastModule?: string;
  start: number;
}

export interface CourseProgressProperties extends StorageProperties {
  data: CourseProgressData;
  meta: Meta;
  id: string;
}

export class CourseProgress
  extends Storage
  implements CourseProgressProperties
{
  public override data!: CourseProgressData;

  constructor(options?: CourseProgressProperties, user?: string) {
    super(options, user, EntityType.CourseProgress);
    this.update(options?.data);
  }

  public update(data?: CourseProgressData): void {
    const {
      lastSection,
      lastModule,
      start = new Date().getTime(),
      course,
      sections = [],
    } = data || {};
    this.data.lastSection = lastSection;
    this.data.lastModule = lastModule;
    this.data.start = start;
    this.data.sections = sections;
    this.data.course = course;
  }
}
