import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, Inject } from '@angular/core';
import {
  TOAST_CONFIG_TOKEN,
  ToastConfig,
  ToastData,
  ToastType,
} from '../components/toast/toast-config';
import { ToastReference } from '../components/toast/toast-reference';
import {
  ErrorToastComponent,
  InfoToastComponent,
  WarnToastComponent,
} from '../components/toast';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private lastToast!: ToastReference;

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) {}

  show(data: ToastData) {
    const overlayRef = this.overlay.create({
      panelClass: '',
      positionStrategy: this.getPositionStrategy(),
    });
    // overlayRef.hostElement.classList.add('');

    const toastRef = new ToastReference(overlayRef);
    this.lastToast = toastRef;

    const injector = this.getInjector(data, toastRef, this.parentInjector);
    let toastPortal: ComponentPortal<any>;
    switch (data.type) {
      case ToastType.error:
        toastPortal = new ComponentPortal(ErrorToastComponent, null, injector);
        break;
      case ToastType.warning:
        toastPortal = new ComponentPortal(WarnToastComponent, null, injector);
        break;
      case ToastType.info:
      default:
        toastPortal = new ComponentPortal(InfoToastComponent, null, injector);
        break;
    }
    overlayRef.attach(toastPortal);

    return toastRef;
  }

  getPositionStrategy() {
    return this.overlay
      .position()
      .global()
      .top(this.getPosition())
      .right('20px');
  }

  getPosition() {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    const position = lastToastIsVisible
      ? this.lastToast.getPosition().bottom
      : this.toastConfig?.position?.top;

    return (position ?? 0 + 0) + 'px';
  }

  getInjector(data: ToastData, toastRef: ToastReference, parent: Injector) {
    const tokens = new WeakMap();

    tokens.set(ToastData, data);
    tokens.set(ToastReference, toastRef);

    return Injector.create({
      parent,
      providers: [
        {
          provide: ToastData,
          useValue: data,
        },
        {
          provide: ToastReference,
          useValue: toastRef,
        },
      ],
    });
  }
}
