<nav class="tw-flex tw-items-center tw-justify-between tw-bg-transparent tw-px-4 tw-py-3 sm:tw-px-6"
  aria-label="Pagination">
  <div class="tw-hidden sm:tw-block">
    <p class="tw-text-sm tw-text-gray-700">
      Showing
      <span class="tw-font-medium">{{startItemIndex}}</span>
      to
      <span class="tw-font-medium">{{endItemIndex}}</span>
      of
      <span class="tw-font-medium">{{length}}</span>
      results
    </p>
  </div>
  <div class="tw-flex tw-flex-1 tw-justify-between sm:tw-justify-end">
    <button (click)="previousPage()" [disabled]="_previousButtonsDisabled()"
      [class.tw-text-gray-200]="_previousButtonsDisabled()" [class.tw-text-gray-700]="!_previousButtonsDisabled()"
      class="tw-relative tw-inline-flex tw-items-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50">Previous</button>
    <button (click)="nextPage()" [disabled]="_nextButtonsDisabled()" [class.tw-text-gray-200]="_nextButtonsDisabled()"
      [class.tw-text-gray-700]="!_nextButtonsDisabled()"
      class="tw-relative tw-ml-3 tw-inline-flex tw-items-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50">Next</button>
  </div>
</nav>