import { trigger, transition, style, animate } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AssetProperties } from '@sidkik/db';
import {
  APP_CONFIG,
  AppConfig,
  AssetSize,
  AssetService as GlobalAssetService,
} from '@sidkik/global';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { MediaSelectorPanelDefaultOptions } from './media-selector-panel';

export const defaultOptions: MediaSelectorPanelDefaultOptions = {
  overlapTrigger: false,
  backdropClass: 'cdk-overlay-transparent-backdrop',
  hasBackdrop: true,
};

const cbAnimation = trigger('comboboxpanel', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(.95)' }),
    animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'scale(1)' }),
    animate('100ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
  ]),
]);

@Component({
  selector: 'sidkik-media-selector-panel',
  templateUrl: './media-selector-panel.component.html',
  animations: [cbAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaSelectorPanelComponent implements OnInit, OnDestroy {
  @Input() overlapTrigger = defaultOptions.overlapTrigger;
  @Input() backdropClass = defaultOptions.backdropClass;
  @Input() hasBackdrop = defaultOptions.hasBackdrop;
  @Input()
  set assets(assets: AssetProperties[] | null | undefined) {
    this.assets$.next(assets);
  }
  @Input()
  set filterType(val: string) {
    this.filterType$.next(val);
  }
  @Input()
  set filterName(val: string) {
    this.filterName$.next(val);
  }
  @Input()
  set filterTags(val: string[]) {
    this.filterTags$.next(val);
  }
  @Input()
  set value(val: any) {
    this.assetValue = val;
  }

  @Output() closed = new EventEmitter<void>();
  @Output() changed = new EventEmitter<AssetProperties>();

  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;

  filteredAssets$: BehaviorSubject<AssetProperties[] | null | undefined> =
    new BehaviorSubject<AssetProperties[] | null | undefined>(undefined);

  assetValue: any;
  assets$: BehaviorSubject<AssetProperties[] | null | undefined> =
    new BehaviorSubject<AssetProperties[] | null | undefined>(undefined);
  filterType$: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);
  filterName$: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);
  filterTags$: BehaviorSubject<string[] | undefined> = new BehaviorSubject<
    string[] | undefined
  >(undefined);

  filteredSubscription!: Subscription;
  visibleFilterSubscription!: Subscription;
  visibleFilter!: string;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private globalAssetService: GlobalAssetService
  ) {}

  ngOnDestroy(): void {
    if (this.filteredSubscription) this.filteredSubscription.unsubscribe();
    if (this.visibleFilterSubscription)
      this.visibleFilterSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.visibleFilterSubscription = this.filterName$.subscribe((filter) => {
      if (filter != '.*' && filter) {
        this.visibleFilter = filter;
      } else {
        this.visibleFilter = '';
      }
    });
    this.filteredSubscription = combineLatest([
      this.assets$,
      this.filterType$,
      this.filterName$,
      this.filterTags$,
    ]).subscribe({
      next: ([assets, filterType, filterName, filterTags]) => {
        if (assets) {
          let filtered: AssetProperties[] = [...assets];

          if (filterType) {
            const regex = new RegExp(filterType, 'i');
            filtered = filtered.filter((a) => regex.test(a.data?.type));
          }

          if (filterName) {
            const regex = new RegExp(filterName, 'i');
            filtered = filtered.filter((a) => regex.test(a.data?.name ?? ''));
          }

          if (
            filterTags &&
            Array.isArray(filterTags) &&
            filterTags.length > 0
          ) {
            filtered = filtered.filter((a) =>
              a.data?.linkedCourses?.filter((lc) => filterTags.includes(lc))
            );
          }

          this.filteredAssets$.next(filtered);
          return;
        }
        this.filteredAssets$.next([]);
        return;
      },
    });
  }

  inputFilterRequest(evt: any): void {
    const filter = (evt?.target as any).value ?? '';
    this.filterRequest(filter);
  }

  filterRequest(val: string) {
    val = val ?? '';
    this.filterName$.next(val);
  }

  getThumbnail(asset: AssetProperties): string {
    if (asset.data.type.indexOf('image') !== -1) {
      return this.getImageThumbnail(asset);
    }
    if (asset.data.type.indexOf('application') !== -1) {
      return this.getPDFThumbnail(asset);
    }
    if (asset.data.type.indexOf('video') !== -1) {
      return this.getVideoThumbnail(asset);
    }
    return '';
  }

  getPDFThumbnail(asset: AssetProperties): string {
    return '';
  }

  getVideoThumbnail(asset: AssetProperties): string {
    return this.getThumbnailURL(asset, AssetSize.large);
  }

  getImageThumbnail(asset: AssetProperties): string {
    return this.getThumbnailURL(asset, AssetSize.small);
  }

  getThumbnailURL(asset: AssetProperties, size: AssetSize): string {
    if (asset?.data?.thumbnails) {
      const thumb = asset.data.thumbnails.find((t) => t.thumbnailSize === size);
      return this.globalAssetService.getAssetImageURL(
        thumb?.key ?? '',
        thumb?.token ?? ''
      );
    }
    return '';
  }

  select(asset: AssetProperties) {
    this.assetValue = asset;
    this.changed.emit(asset);
  }
}
