import { EntityType } from '@sidkik/global';
import { DBConfig } from '@sidkik/indexed-db';
import {
  AccountingConfigProperties,
  AddonConfigProperties,
  AffiliatePayoutProperties,
  AffiliateProperties,
  AffiliateSaleProperties,
  AssetProperties,
  BookingProperties,
  CampaignProperties,
  CampaignRunProperties,
  CartItemProperties,
  ChallengeProperties,
  CommentProperties,
  ContentProperties,
  CouponProperties,
  CourseProgressProperties,
  CourseProperties,
  CustomerProperties,
  EmailProperties,
  EmailTestProperties,
  FlowProperties,
  FormProperties,
  MapProperties,
  MinimizedBookingProperties,
  NetworkingConfigProperties,
  OrderProperties,
  OrderRequestProperties,
  PageProperties,
  ProductProperties,
  PromoCodeProperties,
  QuickbooksConfigProperties,
  SchedulingConfigProperties,
  SKUProperties,
  SpaceProperties,
  SubmittalProperties,
  TagProperties,
  ThemeConfigProperties,
  ThreadProperties,
  UserAvailabilityGlobalProperties,
  UserProperties,
  UserRequestProperties,
  WorkflowProperties,
} from './models';
import { PostProperties } from './models/library/post';
import { CustomerListProperties } from './models/customers/list';
import { CustomerTagProperties } from './models/customers/tag';
import { SegmentProperties } from './models/customers/segment';
import { BookingChangeProperties } from './models/scheduling/booking-change';
import { SubscriptionProperties } from './models/shop/subscription';

export type Document =
  | AssetProperties
  | PageProperties
  | CourseProperties
  | ChallengeProperties
  | CustomerProperties
  | ProductProperties
  | SKUProperties
  | QuickbooksConfigProperties
  | ContentProperties
  | CartItemProperties
  | OrderRequestProperties
  | CourseProgressProperties
  | ChallengeProperties
  | ThemeConfigProperties
  | AddonConfigProperties
  | NetworkingConfigProperties
  | PostProperties
  | MapProperties
  | TagProperties
  | OrderProperties
  | SpaceProperties
  | ThreadProperties
  | CommentProperties
  | AffiliateProperties
  | CouponProperties
  | PromoCodeProperties
  | AffiliateSaleProperties
  | AffiliatePayoutProperties
  | AccountingConfigProperties
  | EmailProperties
  | EmailTestProperties
  | FormProperties
  | SubmittalProperties
  | WorkflowProperties
  | CustomerListProperties
  | CustomerTagProperties
  | CampaignProperties
  | CampaignRunProperties
  | FlowProperties
  | SegmentProperties
  | UserAvailabilityGlobalProperties
  | UserProperties
  | UserRequestProperties
  | BookingProperties
  | MinimizedBookingProperties
  | BookingChangeProperties
  | SchedulingConfigProperties
  | SubscriptionProperties;

export const PendingWrites = 'PendingWrites';
// Ahead of time compiles requires an exported function for factories
export function migrationFactory() {
  return {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    1: () => {},
  };
}

// nested indexes should be parent entity type lowered plus 'ByUpdated' e.g courseByUpdated

export const dbConfig: DBConfig = {
  name: 'sidkik',
  version: 77,
  objectStoresMeta: [
    {
      store: PendingWrites,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [],
    },
    {
      store: EntityType.UserRequest,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.User,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.UserAvailabilityGlobal,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.RefundRequest,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.AdminSubscriptionChange,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.SubscriptionChange,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.AdminBookingChange,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.BookingChange,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Booking,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'customerId',
          keypath: 'data.customerId',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.MinimizedBooking,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.MinimizedSubscription,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.UserAvailabilityGlobal,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.CampaignRun,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'campaign',
          keypath: 'data.campaign',
          options: { unique: false },
        },
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'campaignByUpdated',
          keypath: ['data.campaign', 'meta.updated'],
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Segment,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Flow,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Campaign,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.CustomerList,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.CustomerTag,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Workflow,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Email,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Submittal,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Form,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Coupon,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.PromoCode,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Affiliate,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.EmailTest,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'email',
          keypath: 'data.templateId',
          options: { unique: false },
        },
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'emailByUpdated',
          keypath: ['data.templateId', 'meta.updated'],
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.AffiliateSale,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'affiliate',
          keypath: 'data.affiliateId',
          options: { unique: false },
        },
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'affiliateByUpdated',
          keypath: ['data.affiliateId', 'meta.updated'],
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.AffiliatePayout,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'affiliate',
          keypath: 'data.affiliateId',
          options: { unique: false },
        },
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'affiliateByUpdated',
          keypath: ['data.affiliateId', 'meta.updated'],
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Asset,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Page,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Map,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Post,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Tag,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Course,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.CX,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.CourseProgress,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.ChallengeProgress,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Content,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'course',
          keypath: 'data.course',
          options: { unique: false },
        },
        {
          name: 'challenge',
          keypath: 'data.challenge',
          options: { unique: false },
        },
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'courseByUpdated',
          keypath: ['data.course', 'meta.updated'],
          options: { unique: false },
        },
        {
          name: 'challengeByUpdated',
          keypath: ['data.challenge', 'meta.updated'],
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Challenge,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Customer,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Configuration,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Product,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.SKU,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.QuickbooksConfiguration,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.CartItem,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.OrderRequest,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.MinimizedOrder,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'parentId',
          keypath: 'parentId',
          options: { unique: false },
        },
        {
          name: 'parentIdByUpdated',
          keypath: ['parentId', 'meta.updated'],
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Order,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'customerId',
          keypath: 'data.customerId',
          options: { unique: false },
        },
        {
          name: 'customerByUpdated',
          keypath: ['data.customerId', 'meta.updated'],
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Subscription,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'customerId',
          keypath: 'data.customerId',
          options: { unique: false },
        },
        {
          name: 'customerByUpdated',
          keypath: ['data.customerId', 'meta.updated'],
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Refund,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'customerId',
          keypath: 'data.customerId',
          options: { unique: false },
        },
        {
          name: 'customerByUpdated',
          keypath: ['data.customerId', 'meta.updated'],
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Space,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Thread,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'space',
          keypath: 'data.space',
          options: { unique: false },
        },
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'spaceByUpdated',
          keypath: ['data.space', 'meta.updated'],
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.DraftThread,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.Comment,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
        {
          name: 'thread',
          keypath: 'data.thread',
          options: { unique: false },
        },
        {
          name: 'threadByUpdated',
          keypath: ['data.thread', 'meta.updated'],
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.UserAsset,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.CordonedComment,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.UserCordonedComment,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.UserCommentModerationMap,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.CordonedThread,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.UserCordonedThread,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
    {
      store: EntityType.UserThreadModerationMap,
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        {
          name: 'updated',
          keypath: 'meta.updated',
          options: { unique: false },
        },
      ],
    },
  ],
  migrationFactory,
};
