export interface BaseEventPayload {
  action: string;
  created: number;
  tenant: string;
  customer: string;
  payload: any;
}

export enum EventMessages {
  Event = 'event',
  Config = 'config',
}
