import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { toastyEnter, toastyLeave } from './toast-animation';
import {
  ToastData,
  TOAST_CONFIG_TOKEN,
  ToastConfig,
  ToastInfoData,
} from './toast-config';
import { ToastReference } from './toast-reference';

@Component({
  selector: 'sidkik-error-toast',
  templateUrl: './error-toast.component.html',
  animations: [toastyEnter, toastyLeave],
})
export class ErrorToastComponent implements OnInit, OnDestroy {
  private intervalId!: number;
  toastData: ToastInfoData;
  iconType!: string;
  closing = '*';

  constructor(
    readonly data: ToastData,
    readonly ref: ToastReference,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) {
    this.toastData = data.templateContext ?? {};
  }

  ngOnInit() {
    this.intervalId = setTimeout(
      () => this.close(),
      10000
    ) as unknown as number;
  }

  ngOnDestroy() {
    clearTimeout(this.intervalId);
  }

  close() {
    this.closing = 'closing';
  }

  onFadeFinished(evt: any) {
    const { toState } = evt;
    const isClosing = toState === 'closing';

    if (isClosing) {
      this.ref.close();
    }
  }
}
