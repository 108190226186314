import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Optional,
} from '@angular/core';
import { SortDirection } from './sort-direction';
import { SortState } from './sort-state';
import { SortableDirective } from './sortable.directive';

@Component({
  selector: 'sidkik-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
})
export class SortComponent implements OnInit {
  @Input()
  set start(value: SortDirection) {
    this.direction = value;
    if (this.initialized) {
      this.changeSort(value);
    }
  }

  @Input() field = '';

  @Output() sortChange: EventEmitter<SortState> = new EventEmitter();
  @Output() register: EventEmitter<SortState> = new EventEmitter();

  direction: SortDirection = this.start;
  initialized = false;

  constructor(@Optional() public _sort: SortableDirective) {}

  ngOnInit() {
    this._sort?.register(this);
    this.initialized = true;
  }

  clearSort(): void {
    this.direction = '';
  }

  changeSort(direction?: SortDirection) {
    // only go next if not explicitly set
    if (!direction) {
      this.next();
    }
    this.sortChange.emit({ direction: this.direction, field: this.field });
  }

  next() {
    switch (this.direction) {
      case 'asc':
        this.direction = 'desc';
        break;
      case 'desc':
        this.direction = '';
        break;
      default:
        this.direction = 'asc';
        break;
    }
  }
}
