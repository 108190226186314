import { Injectable } from '@angular/core';
import { customAlphabet } from 'nanoid';
import { EntityToPrefixMap, EntityType } from '../constants/entities/entities';

// dynamic length of 10 ~ 49 years for 1% chance of collision - https://zelark.github.io/nano-id-cc/
const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuv', 14);

@Injectable({
  providedIn: 'root',
})
export class IdService {
  public static generateId(
    entityType: EntityType = 'unk' as EntityType
  ): string {
    const dynamic = nanoid();
    const prefix = EntityToPrefixMap.get(entityType) ?? 'unk';
    return `${prefix}_${dynamic}`;
  }
}
