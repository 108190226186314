import { Component, Input } from '@angular/core';
import { TrailItem } from './breadcrumb.component';

@Component({
  selector: 'sidkik-breadcrumb-item',
  template: `
    <li class="">
      <div class="tw-flex tw-items-center">
        <svg
          *ngIf="!isFirst"
          class="page-header-top-half-breadcrumb-list-home"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
            clip-rule="evenodd"
          />
        </svg>
        <div
          *ngIf="trailItem?.location !== undefined"
          [routerLink]="trailItem.location"
          class="page-header-top-half-breadcrumb-list-item-clickable"
          [class.tw-ml-4]="!isFirst"
        >
          {{ trailItem.name }}
        </div>

        <div
          *ngIf="trailItem?.location === undefined"
          [class.tw-ml-4]="!isFirst"
          class="page-header-top-half-breadcrumb-list-item-not-clickable"
        >
          {{ trailItem.name }}
        </div>
      </div>
    </li>
  `,
})
export class BreadcrumbItemComponent {
  @Input() trailItem!: TrailItem;
  @Input() isFirst = false;
  @Input() index!: number;
}
