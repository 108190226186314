export enum OrderStatus {
  Created = 'created',
  Processing = 'processing',
  Errored = 'error',
  Paid = 'paid',
  PartiallyPaid = 'partially_paid',
  Cancelled = 'cancelled',
  PaymentDue = 'payment_due',
}

export enum ChargeStatus {
  Pending = 'pending',
  Paid = 'paid',
  Refunded = 'refunded',
  PartialRefunded = 'partial_refunded',
  Failed = 'failed',
  Errored = 'errored',
  Cancelled = 'cancelled',
}

export enum SubscriptionStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Paused = 'paused',
  PaymentDue = 'payment_due',
  Expired = 'expired',
}

export enum RefundStatus {
  Requested = 'requested',
  Succeeded = 'succeeded',
  Failed = 'failed',
  Cancelled = 'cancelled',
  Pending = 'pending',
}
