export enum ProductType {
  SERVICE = 'service',
  GOOD = 'good',
}

export enum ProductSubType {
  Course = 'Course',
  Challenge = 'Challenge',
  Bundle = 'Bundle',
  Membership = 'Membership',
  Platform = 'Platform',
  PlatformTrial = 'PlatformTrial',
  Session = 'Session',
}

export type ProductSubTypeKey = keyof typeof ProductSubType;

export const PRODUCT_SUB_TYPE_KEYS = new Map<ProductSubType, ProductSubTypeKey>(
  Object.entries(ProductSubType).map(([key, value]: [any, any]) => {
    return [value, key];
  })
);
