<ng-container [ngSwitch]="assetType">
  <ng-container *ngSwitchCase="assetTypes.video" class="tw-aspect-[16/9] tw-object-cover">
    <!-- <sidkik-video-vimeo *ngIf="!thumbOnly" [vimeoId]="asset?.integrations?.vimeo?.id"
      [options]="videoOptions"></sidkik-video-vimeo> -->
    <img *ngIf="!isBackground && thumbOnly" [src]="getVideoThumbnail()" [class]="class" />
    <div *ngIf="isBackground  && thumbOnly" [sidkikBackground]="getVideoBackgroundStyle()" [class]="class"></div>
  </ng-container>
  <ng-container *ngSwitchCase="assetTypes.vimeo">
    <div *ngIf="!thumbOnly" class="tw-aspect-[16/9] tw-object-cover">
      <sidkik-video-vimeo [vimeoId]="asset?.integrations?.vimeo?.id"
        [vimeoEmbedUrl]="asset?.integrations?.vimeo?.secondaryId" [options]="videoOptions"></sidkik-video-vimeo>
    </div>
    <img *ngIf="!isBackground && thumbOnly" [src]="getVideoThumbnail()" [class]="class" />
    <div *ngIf="isBackground  && thumbOnly" [sidkikBackground]="getVideoBackgroundStyle()" [class]="class"></div>
  </ng-container>
  <ng-container *ngSwitchCase="assetTypes.image">
    @if(loaderParams$ | async; as loaderParams ) {
    @if(src$ | async; as src) {
    @if(preset) {
    <img [ngSrc]="src" #presetImage fill placeholder [placeholderConfig]="{blur: false}" disableOptimizedSrcset
      [loaderParams]="loaderParams" (error)="handleMissing()" [class]="class" />
    } @else {
    <img [ngSrc]="src" #regularImage fill placeholder [placeholderConfig]="{blur: false}" [loaderParams]="loaderParams"
      (error)="handleMissing()" [class]="class" />
    }
    }
    }
  </ng-container>
  <ng-container *ngSwitchCase="assetTypes.pdf">
    <div [class]="class">
      <svg xmlns="http://www.w3.org/2000/svg" class="tw-text-gray-700" fill="none" viewBox="0 0 24 24"
        stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
      </svg>
    </div>
  </ng-container>
</ng-container>