import { StorageProperties, Storage } from '../storage';
import { Meta } from '../meta';
import { Integrations } from '../integrations';
import { EntityType } from '@sidkik/global';

export enum SKUType {
  OneTime = 'one_time',
  Recurring = 'recurring',
}

export enum RecurringPeriod {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export interface SKUData {
  active: boolean;
  attributes?: any;
  metadata?: any;
  price: number;
  product: string;
  type?: SKUType;
  recurring?: {
    period: RecurringPeriod;
    interval: number;
  };
}

export interface SKUProperties extends StorageProperties {
  data: SKUData;
  meta: Meta;
  id: string;
  integrations: Integrations;
}

export class SKU extends Storage implements SKUProperties {
  public override data!: SKUData;

  constructor(options?: SKUProperties, user?: string) {
    super(options, user, EntityType.SKU);
    this.syncChangeFields = ['active', 'price'];
    this.update(options?.data);
  }

  public update(data?: SKUData, trackChanges = false): void {
    if (trackChanges) {
      super.checkIfSync(data);
    }

    this.data = { ...data } as unknown as SKUData;
    const { active = true, type = SKUType.OneTime } = data || {};
    this.data.active = active;
    this.data.type = type;
  }
}
