import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch, navigation } from '@ngrx/router-store/data-persistence';
import { DbService, MapProperties } from '@sidkik/db';
import { EntityType } from '@sidkik/global';
import { map, withLatestFrom } from 'rxjs';

import * as MapActions from './map.actions';
import { MapFacade } from './map.facade';
import { ContainerComponent } from '../../components/container/container.component';

@Injectable()
export class MapEffects {
  /** Navigation Actions */
  navigateToList$ = createEffect(() =>
    this.actions$.pipe(
      withLatestFrom(this.mapFacade.loaded$, this.mapFacade.loading$),
      navigation(ContainerComponent, {
        run: (
          route: ActivatedRouteSnapshot,
          loaded: boolean,
          loading: boolean
        ) => {
          if (!loaded && !loading) {
            return MapActions.loadMaps();
          }
          return MapActions.noop();
        },
        onError(route: ActivatedRouteSnapshot, error: any) {
          return null;
        },
      })
    )
  );

  /** Fetch Actions */
  loadMaps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.loadMaps),
      fetch({
        id: (a: ReturnType<typeof MapActions.loadMaps>) => a.type,
        run: () => {
          return this.dbService
            .getAllEntities<MapProperties>(EntityType.Map)
            .pipe(map((docs) => MapActions.loadMapsSuccess({ maps: docs })));
        },
        onError: (_, error) => {
          return null;
        },
      })
    )
  );

  /** Persistance Actions */

  /** General Actions */

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly mapFacade: MapFacade,
    private readonly dbService: DbService
  ) {}
}
