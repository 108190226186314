export const lookupNoneSelected = { value: '', label: 'None Selected' };

export enum CustomerType {
  Subscriber = 'Subscriber',
  Member = 'Member',
}

export enum PageType {
  Landing = 'Landing',
  Home = 'Home',
  Site = 'Site',
  PrivacyPolicy = 'PrivacyPolicy',
  TermsOfUse = 'TermsOfUse',
}

export enum NavigationType {
  Minimal = 'Minimal',
  Full = 'Full',
  None = 'None',
}

export enum StaticSlug {
  PrivacyPolicy = 'privacy-policy',
  TermsOfUse = 'terms-of-use',
}

export enum AffiliatePayoutType {
  Stripe = 'Stripe',
  Offline = 'Offline',
}
