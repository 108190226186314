import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export interface ModerationScore {
  passed: boolean;
  results: {
    [key: string]: number;
  };
  settings: {
    [key: string]: number;
  };
}

export interface Report {
  customer: string;
  reason: string;
  reported: number;
}

export enum CommentStatus {
  InReview = 'in review',
  Cordoned = 'cordoned',
  Available = 'available',
  Rejected = 'rejected',
  Reported = 'reported',
  Ignored = 'ignored', // when a comment is reported but the moderator decides to ignore it
}

export interface CommentData {
  parent: string;
  content: string;
  thread: string;
  space: string;
  commenter?: string;
  commenterAvatar?: string;
  status?: CommentStatus;
  statusInfo?: string;
  clapCount?: number;
  score?: ModerationScore;
  reports?: Report[];
}

export interface CommentProperties extends StorageProperties {
  data: CommentData;
  meta: Meta;
  id: string;
}

export class Comment extends Storage implements CommentProperties {
  public override data!: CommentData;

  constructor(options?: CommentProperties, user?: string) {
    super(options, user, EntityType.Comment);
    this.update(options?.data);
  }

  public update(data?: CommentData): void {
    this.data = { ...data } as unknown as CommentData;
  }
}

export interface CordonedCommentProperties extends StorageProperties {
  data: CommentData;
  meta: Meta;
  id: string;
}

export class CordonedComment extends Storage implements CommentProperties {
  public override data!: CommentData;

  constructor(options?: CordonedCommentProperties, user?: string) {
    super(options, user, EntityType.Comment);
    this.update(options?.data);
  }

  public update(data?: CommentData): void {
    this.data = { ...data } as unknown as CommentData;
  }
}
