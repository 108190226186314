import { trigger, transition, style, animate } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { SlideOverPanelDefaultOptions } from './slide-over-panel';

export const defaultOptions: SlideOverPanelDefaultOptions = {
  overlapTrigger: false,
  backdropClass: 'cdk-overlay-transparent-backdrop',
  hasBackdrop: true,
};

const cbAnimation = trigger('comboboxpanel', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(.95)' }),
    animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'scale(1)' }),
    animate('100ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
  ]),
]);

@Component({
  selector: 'sidkik-slide-over-panel',
  templateUrl: './slide-over-panel.component.html',
  animations: [cbAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideOverPanelComponent implements OnInit, OnDestroy {
  @Input() overlapTrigger = defaultOptions.overlapTrigger;
  @Input() backdropClass = defaultOptions.backdropClass;
  @Input() hasBackdrop = defaultOptions.hasBackdrop;

  @Output() closed = new EventEmitter<void>();
  @Output() changed = new EventEmitter<any>();

  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;

  constructor() {}
  ngOnDestroy(): void {}
  ngOnInit(): void {}
}
