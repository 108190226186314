import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { filter, map, Observable, tap } from 'rxjs';
import { AuthzConfig } from '../../authz.config';
import { AuthzFacade } from '../../+state/authz.facade';
import { AuthzConfigToken } from '@sidkik/global';

@Injectable({
  providedIn: 'root',
})
export class MeGuard implements CanActivate, CanLoad {
  constructor(
    @Inject(AuthzConfigToken) private config: AuthzConfig,
    private authzFacade: AuthzFacade,
    private router: Router
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.meCheck();
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.meCheck();
  }

  private meCheck(): Observable<boolean> {
    return this.authzFacade.me$.pipe(
      filter((me) => me !== undefined),
      map(() => {
        return true;
      })
    );
  }
}
