<form *ngIf="hasConfig" class="form" [formGroup]="form" name="form">
  <div class="form-body">
    <div *ngFor="let section of (sections$ | async)" class="form-section">
      <div class="form-section-content">
        <ng-container *ngFor="let component of section.components" class="form-group" [ngSwitch]="true">
          <div class="form-group" [ngClass]="calculateColspan(component.attributes.colspan)"
            *ngSwitchCase="component.type == fcType.Input || component.type == fcType.Email || component.type == fcType.Name">
            <label [for]="component.attributes.name" class="input-label">{{component.attributes.label}}</label>
            <div class="tw-mt-1">
              <input [type]="component.attributes['inputtype']" [placeholder]="component.attributes['placeholder']"
                [formControlName]="component.attributes.name" [name]="component.attributes.name"
                [id]="component.attributes.name" class="input" [required]="component.attributes.required">
              <sidkik-input-error [touched]="form.controls[component.attributes.name].touched"
                [dirty]="form.controls[component.attributes.name].dirty"
                [errors]="form.controls[component.attributes.name].errors">
              </sidkik-input-error>
            </div>
          </div>
          <div class="tw-pt-4" [ngClass]="calculateColspan()" *ngSwitchCase="component.type == fcType.Divider">
            @if (component.attributes.label && component.attributes.label !== '') {
            <div class="tw-relative">
              <div class="tw-absolute tw-inset-0 tw-flex tw-items-center" aria-hidden="true">
                <div class="tw-w-full tw-border-t tw-border-gray-300"></div>
              </div>
              <div class="tw-relative tw-flex tw-justify-center">
                <span class="tw-bg-white tw-px-2 tw-text-sm tw-text-gray-500">{{component.attributes.label}}</span>
              </div>
            </div>
            }
            @else {
            <div class="tw-relative">
              <div class="tw-absolute tw-inset-0 tw-flex tw-items-center" aria-hidden="true">
                <div class="tw-w-full tw-border-t tw-border-gray-300"></div>
              </div>
            </div>
            }

          </div>
          <div class="tw-whitespace-pre-line -tw-mb-8"
            [ngClass]="calculateColspanAndTextSize(component.attributes.colspan, component.attributes.textSize)"
            *ngSwitchCase="component.type == fcType.Text" [innerHTML]="component.attributes.content">

          </div>
          <div class="form-group" [ngClass]="calculateColspan(component.attributes.colspan)"
            *ngSwitchCase="component.type == fcType.Select">
            <label [for]="component.attributes.name" class="input-label">{{component.attributes.label}}</label>
            <div class="tw-mt-1">
              <sidkik-combo-box [options]="component.options" [multiSelect]="false"
                [formControlName]="component.attributes.name">
              </sidkik-combo-box>
              <sidkik-input-error [touched]="form.controls[component.attributes.name].touched"
                [dirty]="form.controls[component.attributes.name].dirty"
                [errors]="form.controls[component.attributes.name].errors">
              </sidkik-input-error>
            </div>
          </div>
          <div class="form-group" [ngClass]="calculateColspan(component.attributes.colspan)"
            *ngSwitchCase="component.type == fcType.TextBox">
            <label [for]="component.attributes.name" class="input-label">{{component.attributes.label}}</label>
            <div class="tw-mt-1">
              <textarea rows="2" [formControlName]="component.attributes.name" name="caption" id="caption"
                class="input"></textarea>
              <sidkik-input-error [touched]="form.controls[component.attributes.name].touched"
                [dirty]="form.controls[component.attributes.name].dirty"
                [errors]="form.controls[component.attributes.name].errors">
              </sidkik-input-error>
            </div>
          </div>
          <div class="form-group" [ngClass]="calculateColspan(component.attributes.colspan)"
            *ngSwitchCase="component.type == fcType.Checkbox">
            <label [for]="component.attributes.name" class="input-label">&nbsp;</label>
            <div class="tw-mt-1">
              <div class="tw-flex tw-items-center">
                <input type="checkbox" [placeholder]="component.attributes['placeholder']"
                  [formControlName]="component.attributes.name" [name]="component.attributes.name"
                  [id]="component.attributes.name"
                  class="tw-mr-2 tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-primary-600 focus:tw-ring-primary-600"
                  [required]="component.attributes.required">
                <label [for]="component.attributes.name" class="input-label">{{component.attributes.label}}</label>
              </div>
              <sidkik-input-error [touched]="form.controls[component.attributes.name].touched"
                [dirty]="form.controls[component.attributes.name].dirty"
                [errors]="form.controls[component.attributes.name].errors">
              </sidkik-input-error>
            </div>
          </div>
          <div class="form-group" *ngSwitchCase="component.type == fcType.Button"
            [ngClass]="calculateColspan(component.attributes.colspan)">
            <label [for]="component.attributes.name" class="input-label">&nbsp;</label>
            <div class="tw-mt-1">
              <button type="submit" [ngClass]="component.attributes.decoration"
                (click)="onSubmit()">{{component.attributes['text']}}</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</form>
<div *ngIf="!hasConfig">Unable to load form:</div>