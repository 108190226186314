import { Directive, Input, HostBinding } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[sidkikBackground]',
})
export class BackgroundDirective {
  @Input() sidkikBackground = '';
  @HostBinding('style')
  get s() {
    return this.sidkikBackground;
  }
}
