import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { ProductProperties, SKUProperties } from '../catalog';
import { EntityType } from '@sidkik/global';

export interface AppointmentRequest {
  userId?: string;
  startTime?: number;
  duration?: number;
  userTimeZone: string;
}
export interface CartItemData {
  product: ProductProperties;
  appointmentRequest?: AppointmentRequest;
  sku: SKUProperties;
  quantity?: number;
  discountAmount?: number;
  discountApplication?: string;
}

export interface CartItemProperties extends StorageProperties {
  data: CartItemData;
  meta: Meta;
  id: string;
}

export class CartItem extends Storage implements CartItemProperties {
  public override data!: CartItemData;

  constructor(options?: CartItemProperties, user?: string) {
    super(options, user, EntityType.CartItem);
    if (options?.data) this.update(options.data);
  }

  public update(data: CartItemData): void {
    this.data = data;
  }
}
