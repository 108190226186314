import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';
import { AssetProperties } from './asset';

export interface PostMapEntry {
  id: string;
  title: string;
  slug: string;
  tags?: string[];
  publishDate?: number;
  excerpt?: string;
  featured?: boolean;
  video?: AssetProperties;
  image?: AssetProperties;
  accessLevelId?: string[];
  accessLevelName?: string[];
  grantedAccess?: boolean;
  isPublic?: boolean;
}

export interface PostMapData {
  entries: PostMapEntry[];
  permissions?: string[]; // list of access level ids that can view this map
}

export interface PostMapProperties extends StorageProperties {
  data: PostMapData;
  meta: Meta;
  id: string;
}

export class PostMap extends Storage implements PostMapProperties {
  public override data!: PostMapData;

  constructor(options?: PostMapProperties, user?: string) {
    super(options, user, EntityType.Map);
    this.update(options?.data);
  }

  public update(data?: PostMapData): void {
    this.data = { ...data } as unknown as PostMapData;
  }

  public addEntry(entry: PostMapEntry): void {
    if (!this.data.entries) {
      this.data.entries = [];
    }
    this.data.entries?.push(entry);
    this.updateMeta();
  }

  public removeEntry(entry: PostMapEntry): void {
    this.data.entries =
      this.data.entries?.filter((e) => e.id !== entry.id) ?? [];
    this.updateMeta();
  }

  public updateEntry(entry: PostMapEntry): void {
    this.removeEntry(entry);
    this.addEntry(entry);
    this.updateMeta();
  }

  public setAccess(): void {
    this.data.entries.forEach((e) => {
      if (e.accessLevelId?.some((id) => id === 'public')) {
        e.grantedAccess = true;
        e.isPublic = true;
        return;
      }
      if (e.accessLevelId?.some((r) => this.data.permissions?.includes(r))) {
        e.grantedAccess = true;
        return;
      }
      e.isPublic = false;
      e.grantedAccess = false;
    });
  }

  // get total number of entries that are published
  public getPublishedEntryCount(): number {
    return this.data.entries.filter((e) => {
      if (e.publishDate === undefined) {
        return false;
      }
      const adjustedDate = e.publishDate * 1000;
      if (adjustedDate < Date.now()) {
        return true;
      }
      return false;
    }).length;
  }

  // get entry count grouped by tag
  public getTagCounts(): { tag: string; count: number; link: string }[] {
    const counts: { [key: string]: number } = {};
    this.data.entries.forEach((entry) => {
      if (entry.tags) {
        entry.tags.forEach((tag) => {
          if (
            entry.publishDate &&
            entry.publishDate > 0 &&
            entry.publishDate * 1000 < Date.now()
          ) {
            counts[tag] = counts[tag] ? counts[tag] + 1 : 1;
          }
        });
      }
    });

    const tagCounts: { tag: string; count: number; link: string }[] = [];
    Object.keys(counts).forEach((tag) => {
      tagCounts.push({
        tag,
        count: counts[tag],
        link: tag.replace(/ /g, '-').toLowerCase(),
      });
    });

    return tagCounts.sort((a, b) => (a.tag < b.tag ? -1 : 1));
  }

  // get latest entries by date with limit on count
  public getLatestEntries(limit: number): PostMapEntry[] {
    return this.data.entries
      .filter((e) => {
        if (e.publishDate === undefined) {
          return false;
        }
        const adjustedDate = e.publishDate * 1000;
        if (adjustedDate < Date.now()) {
          return true;
        }
        return false;
      })
      .sort((a, b) => ((a.publishDate ?? 0) < (b.publishDate ?? 0) ? 1 : -1))
      .slice(0, limit);
  }

  // get all published entries by tag by date descending
  public getPublishedEntriesByTag(tagLink: string): PostMapEntry[] {
    return this.data.entries
      .filter((e) => {
        if (e.publishDate === undefined) {
          return false;
        }
        const adjustedDate = e.publishDate * 1000;
        if (adjustedDate < Date.now()) {
          return true;
        }
        return false;
      })
      .filter((e) =>
        e.tags?.some((t) => t.replace(/ /g, '-').toLowerCase() === tagLink)
      )
      .sort((a, b) => ((a.publishDate ?? 0) < (b.publishDate ?? 0) ? 1 : -1));
  }

  // get all published entries by date descending
  public getPublishedEntriesByDateDescending(): PostMapEntry[] {
    return this.data.entries
      .filter((e) => {
        if (e.publishDate === undefined) {
          return false;
        }
        const adjustedDate = e.publishDate * 1000;
        if (adjustedDate < Date.now()) {
          return true;
        }
        return false;
      })
      .sort((a, b) => ((a.publishDate ?? 0) < (b.publishDate ?? 0) ? 1 : -1));
  }

  // get featured entries with limit on count by date descending
  public getFeaturedEntries(limit: number): PostMapEntry[] {
    return this.data.entries
      .filter((e) => {
        if (e.publishDate === undefined) {
          return false;
        }
        const adjustedDate = e.publishDate * 1000;
        if (adjustedDate < Date.now()) {
          return true;
        }
        return false;
      })
      .filter((e) => e.featured)
      .sort((a, b) => ((a.publishDate ?? 0) < (b.publishDate ?? 0) ? 1 : -1))
      .slice(0, limit);
  }
}
