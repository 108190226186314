import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ButtonState } from '@sidkik/ui';
import { AuthProvider } from '../../services/auth-process.service';
// import { AuthProvider } from '../../_services/auth-process.service';

interface ForgotPasswordForm {
  email: FormControl<string>;
}

export interface ForgotPasswordAttemptEvent {
  email: string;
}

@Component({
  selector: 'sidkik-pure-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class PureForgotPasswordComponent implements OnInit {
  @Input() logoUrl: string | undefined = '';
  @Input() state: ButtonState | null = ButtonState.ready;
  // @Input() providers: AuthProvider[] | AuthProvider = AuthProvider.ALL; //  google, facebook, twitter, github as array or all as one single string
  @Input() registrationEnabled = true;
  @Input() messageOnAuthSuccess = '';
  @Input() messageOnAuthError = '';

  @Output() forgotClicked: EventEmitter<ForgotPasswordAttemptEvent> =
    new EventEmitter<ForgotPasswordAttemptEvent>();

  forgotForm!: FormGroup<ForgotPasswordForm>;
  authProviders = AuthProvider;
  constructor(private formBuilder: FormBuilder) {}

  /**
   * forgot handler for the forgot request
   */
  forgot() {
    this.forgotForm.markAllAsTouched();
    if (!this.forgotForm.valid) {
      return;
    }
    const event: ForgotPasswordAttemptEvent = {
      email: this.forgotForm.controls.email.value,
    };
    this.forgotClicked.emit(event);
  }

  ngOnInit(): void {
    this.forgotForm = this.formBuilder.nonNullable.group({
      email: ['', [Validators.required]],
    });
  }
}
