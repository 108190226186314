import { Meta } from '../meta';
import { StorageProperties, Storage, omitUndefinedProps } from '../storage';
import { CodeInjection } from './code';
import { MetaData } from './metadata';
import { EntityType } from '@sidkik/global';
import { AssetProperties } from './asset';
import { PostMapEntry } from './post-map';
import { ComboBoxOption } from '@sidkik/global';

export const publicAccessLevelId = 'public';
export const publicAccessLevelName = 'Public';

export interface PostData {
  title: string;
  slug: string;
  template?: string;
  content: string;
  tags?: string[];
  codeHeader?: CodeInjection;
  codeFooter?: CodeInjection;
  metadata?: MetaData;
  wordCount?: number;
  publishDate?: number;
  excerpt?: string;
  featured?: boolean;
  video?: AssetProperties;
  image?: AssetProperties;
  accessLevelId?: string[];
  accessLevelName?: string[];
  grantedAccess?: boolean;
  isPublic?: boolean;
}

export interface PostProperties extends StorageProperties {
  data: PostData;
  meta: Meta;
  id: string;
}

export class Post extends Storage implements PostProperties {
  public override data!: PostData;

  constructor(options?: PostProperties, user?: string) {
    super(options, user, EntityType.Post);
    this.update(options?.data);
  }

  public update(data?: PostData): void {
    this.data = { ...data } as unknown as PostData;
    this.data.content = this.data.content || '';
  }

  public setAccess(
    permissions: string[],
    accessLevels: ComboBoxOption[] = []
  ): void {
    if (this.data.accessLevelId?.length) {
      // map access level names to access level ids
      this.data.accessLevelName = this.data.accessLevelId
        .filter((id) => accessLevels.findIndex((r) => r.value === id) !== -1)
        .map((id) => accessLevels.find((r) => r.value === id)?.label ?? 'na');
    }

    if (this.data.accessLevelId?.some((id) => id === 'public')) {
      this.data.grantedAccess = true;
      this.data.isPublic = true;
      return;
    }
    if (this.data.accessLevelId?.some((r) => permissions?.includes(r))) {
      this.data.grantedAccess = true;
      return;
    }
    this.data.isPublic = false;
    this.data.grantedAccess = false;
  }

  public toMapEntry(): PostMapEntry {
    return omitUndefinedProps({
      id: this.id,
      title: this.data.title,
      slug: this.data.slug,
      tags: this.data.tags,
      publishDate: this.data.publishDate,
      excerpt: this.data.excerpt,
      featured: this.data.featured,
      video: this.data.video,
      image: this.data.image,
      accessLevelId: this.data.accessLevelId,
      accessLevelName: this.data.accessLevelName,
    });
  }
}
