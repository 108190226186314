import { InjectionToken } from '@angular/core';

export const BRANDING_CONFIG = new InjectionToken<BrandingConfig>(
  'branding.config'
);

export interface BrandingConfig {
  condensedLogo: string;
  fullLogo: string;
}
