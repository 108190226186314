import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { Integrations } from '../integrations';
import { EntityType } from '@sidkik/global';

export enum AffiliateSaleStatus {
  Waiting = 'waiting',
  Cancelled = 'cancelled',
  Completed = 'completed',
}

export interface AffiliateSaleData {
  amount: number;
  affiliateId: string;
  status: AffiliateSaleStatus;
  orderId: string;
  productId: string;
  paid: boolean;
  payoutId?: string;
  purchaseDate: number;
  expectedPayout?: number;
  expectedPayoutDate?: number;
  refundedAmount?: number;
  refundedDate?: number;
  statusReason?: string;
}

export interface AffiliateSaleProperties extends StorageProperties {
  data: AffiliateSaleData;
  meta: Meta;
  id: string;
  integrations: Integrations;
}

export class AffiliateSale extends Storage implements AffiliateSaleProperties {
  public override data!: AffiliateSaleData;
  constructor(options?: AffiliateSaleProperties, user?: string) {
    super(options, user, EntityType.Affiliate);
    this.update(options?.data);
  }

  public update(data?: AffiliateSaleData): void {
    this.data = { ...data } as unknown as AffiliateSaleData;
  }
}
