import { InjectionToken, TemplateRef } from '@angular/core';

export enum ToastType {
  'warning' = 'warning',
  'info' = 'info',
  'success' = 'success',
  'error' = 'error',
}

export type ToastDataTypes = ToastInfoData;

export class ToastData implements ToastDataType {
  type?: ToastType | undefined;
  template?: TemplateRef<any>;
  templateContext?: ToastDataTypes;
}

export interface ToastDataType {
  type?: ToastType;
  template?: TemplateRef<any>;
  templateContext?: ToastDataTypes;
}

export interface ToastInfoData {
  title?: string;
  subTitle?: string;
}

export interface ToastWarnData {
  title?: string;
  subTitle?: string;
}

export interface ToastErrorData {
  title?: string;
  subTitle?: string;
}

export function infoToast(title: string, subTitle: string): ToastData {
  return {
    type: ToastType.info,
    templateContext: {
      title,
      subTitle,
    },
  };
}

export function warnToast(title: string, subTitle: string): ToastData {
  return {
    type: ToastType.warning,
    templateContext: {
      title,
      subTitle,
    },
  };
}

export function errorToast(title: string, subTitle: string): ToastData {
  return {
    type: ToastType.error,
    templateContext: {
      title,
      subTitle,
    },
  };
}

export interface ToastConfig {
  position?: {
    top: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
}

export const defaultToastConfig: ToastConfig = {
  position: {
    top: 20,
    right: 20,
  },
  animation: {
    fadeOut: 2500,
    fadeIn: 300,
  },
};

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');
