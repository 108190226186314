import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export interface SubmittalData {
  formId: string;
  customerId?: string;
  email: string; // have to have this for associating with customer/segment
  payload: any;
}

export interface SubmittalProperties extends StorageProperties {
  data: SubmittalData;
  meta: Meta;
  id: string;
}

export class Submittal extends Storage implements SubmittalProperties {
  public override data!: SubmittalData;

  constructor(options?: SubmittalProperties, user?: string) {
    super(options, user, EntityType.Submittal);
    this.update(options?.data);
  }

  public update(data?: SubmittalData): void {
    this.data = { ...data } as unknown as SubmittalData;
  }
}
