import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ComboBoxPanel } from './combo-box-panel';
import {
  HorizontalConnectionPos,
  Overlay,
  OverlayRef,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { delay, merge, Observable, Subscription, take } from 'rxjs';
import { ComboBoxPanelComponent } from './combo-box-panel.component';

@Directive({
  selector: '[sidkikComboBoxTrigger],[sidkik-combo-box-trigger]',
  exportAs: 'comboBoxTrigger',
})
export class ComboBoxTriggerDirective implements OnDestroy, AfterViewInit {
  public isComboBoxOpen = false;
  private overlayRef!: OverlayRef;
  private comboBoxClosingActionsSub = Subscription.EMPTY;
  private comboBoxOutsideEventsActionsSub = Subscription.EMPTY;
  private cbpChangedSubscription!: Subscription;

  @Input('sidkikComboBoxTrigger') public comboBoxPanel!: ComboBoxPanel;
  @Output() closing = new EventEmitter<void>();
  @Output() opening = new EventEmitter<void>();
  @ViewChild(ComboBoxPanelComponent) cb!: ComboBoxPanelComponent;

  @HostListener('click', ['$event']) onClick(evt: any) {
    if (evt?.srcElement?.id !== 'ignore') {
      this.toggleComboBox();
    }
  }

  @HostListener('window:resize')
  public onWinResize() {
    this.syncWidth();
  }

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngAfterViewInit(): void {
    // closes on single selection
    if (!this.comboBoxPanel.multiSelect) {
      this.cbpChangedSubscription = this.comboBoxPanel.changed.subscribe(() => {
        if (this.isComboBoxOpen) {
          this.destroyComboBox();
        }
      });
    }
  }

  toggleComboBox(): void {
    this.isComboBoxOpen ? this.destroyComboBox() : this.openComboBox();
  }

  private syncWidth() {
    if (!this.overlayRef) {
      return;
    }

    const refRect = this.elementRef.nativeElement.getBoundingClientRect();
    this.overlayRef.updateSize({ width: refRect.width });
  }

  openComboBox(): void {
    const [originX, originFallbackX]: HorizontalConnectionPos[] =
      this.comboBoxPanel.xPosition === 'before'
        ? ['end', 'start']
        : ['start', 'end'];

    const [overlayY, overlayFallbackY]: VerticalConnectionPos[] =
      this.comboBoxPanel.yPosition === 'above'
        ? ['bottom', 'top']
        : ['top', 'bottom'];

    const [originY, originFallbackY] = [overlayY, overlayFallbackY];
    const [overlayX, overlayFallbackX] = [originX, originFallbackX];
    const offsetY = 36;

    this.isComboBoxOpen = true;

    this.overlayRef = this.overlay.create({
      width: this.elementRef.nativeElement.getBoundingClientRect().width,
      maxHeight: '50vh',
      hasBackdrop: this.comboBoxPanel.hasBackdrop,
      backdropClass: this.comboBoxPanel.backdropClass,
      panelClass: 'cdk-overlay-pane-absolute',
      scrollStrategy: this.overlay.scrollStrategies.reposition({
        autoClose: true,
      }),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          { originX, originY, overlayX, overlayY, offsetY },
          {
            originX: originFallbackX,
            originY,
            overlayX: overlayFallbackX,
            overlayY,
            offsetY,
          },
          {
            originX,
            originY: originFallbackY,
            overlayX,
            overlayY: overlayFallbackY,
            offsetY: -offsetY,
          },
          {
            originX: originFallbackX,
            originY: originFallbackY,
            overlayX: overlayFallbackX,
            overlayY: overlayFallbackY,
            offsetY: -offsetY,
          },
        ]),
    });

    const templatePortal = new TemplatePortal(
      this.comboBoxPanel.templateRef,
      this.viewContainerRef
    );
    this.overlayRef.attach(templatePortal);
    this.comboBoxPanel.isOpen = true;
    this.opening.emit();
    this.comboBoxOutsideEventsActionsSub = this.overlayRef
      .outsidePointerEvents()
      .pipe(delay(50)) // allow the toggle to fire before the mouse event if the mouseevent is from the trigger
      .subscribe(() => {
        this.destroyComboBox();
      });
    this.comboBoxClosingActionsSub = this.comboBoxClosingActions().subscribe(
      () => {
        this.destroyComboBox();
      }
    );
  }

  private comboBoxClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    const comboBoxClosed = this.comboBoxPanel.closed;

    return merge(backdropClick$, detachment$, comboBoxClosed);
  }

  private destroyComboBox(): void {
    if (this.comboBoxPanel) {
      this.comboBoxPanel.isOpen = false;
    }
    if (!this.overlayRef || !this.isComboBoxOpen) {
      return;
    }

    this.closing.emit();
    this.comboBoxClosingActionsSub.unsubscribe();
    this.isComboBoxOpen = false;
    this.overlayRef.detach();
  }

  ngOnDestroy(): void {
    this.comboBoxClosingActionsSub.unsubscribe();
    this.comboBoxOutsideEventsActionsSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    if (this.cbpChangedSubscription) {
      this.cbpChangedSubscription.unsubscribe();
    }
  }
}
