import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromDb from './+state/db.reducer';
import { DbEffects } from './+state/db.effects';
import { DBFacade } from './+state/db.facade';
import { GlobalModule } from '@sidkik/global';
import { StorageModule } from './storage.module';
import { DBGuard } from './guards/db.guard';

@NgModule({
  imports: [
    CommonModule,
    GlobalModule,
    StorageModule,
    StoreModule.forFeature(fromDb.DB_FEATURE_KEY, fromDb.dbReducer),
    EffectsModule.forFeature([DbEffects]),
  ],
  providers: [DBFacade, DBGuard],
})
export class DbModule {}
