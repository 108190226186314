import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  CanMatchFn,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { filter, map, Observable, } from 'rxjs';
import { AuthzFacade } from '../../+state/authz.facade';
import { AuthzConfigToken } from '@sidkik/global';


export const canMatchAuthenticated: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authFacade = inject(AuthzFacade);
  const config = inject(AuthzConfigToken);
  const router = inject(Router);

  // Preserve the query string
  const url = segments.map(segment => segment.path).join('/');
  return authFacade.loggedIn$.pipe(
    map((loggedIn) => {
      if (loggedIn) {
        return true;
      } else {
        if (config.authGuardFallbackURL) {
          return router.createUrlTree([`/${config.authGuardFallbackURL}`], {
            queryParams: { redirectUrl: url },
          });
        }
        return false;
      }
    })
  );
};

export const canMatchMe: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authFacade = inject(AuthzFacade);
  return authFacade.me$.pipe(
    filter((me) => me !== undefined),
    map(() => {
      return true;
    })
  );
}

export const canActivateMe: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authFacade = inject(AuthzFacade);
  return authFacade.me$.pipe(
    filter((me) => me !== undefined),
    map(() => {
      return true;
    })
  );
}


// Define the CanActivate function
export const canActivateAuthenticated: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authFacade = inject(AuthzFacade);
  const config = inject(AuthzConfigToken);
  const router = inject(Router);

  // Preserve the query string
  const redirectUrl = state.url;
  return authFacade.loggedIn$.pipe(
    map((loggedIn) => {
      if (loggedIn) {
        return true;
      } else {
        if (config.authGuardFallbackURL) {
          router.navigate([`/${config.authGuardFallbackURL}`], {
            queryParams: { redirectUrl },
          });
        }
        return false;
      }
    })
  );
};
