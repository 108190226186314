import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DropdownDefaultOptions, DropdownPanel } from './dropdown-panel';
import { DropdownPositionX, DropdownPositionY } from './dropdown-positions';

export const defaultOptions: DropdownDefaultOptions = {
  overlapTrigger: false,
  xPosition: 'after',
  yPosition: 'below',
  backdropClass: 'cdk-overlay-transparent-backdrop',
  hasBackdrop: false,
};

const ddpAnimation = trigger('dropdownpanel', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(.95)' }),
    animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'scale(1)' }),
    animate('100ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
  ]),
]);

@Component({
  selector: 'sidkik-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  // host: {
  //   class:
  //     'absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
  // },
  animations: [ddpAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements DropdownPanel {
  @Input() overlapTrigger = defaultOptions.overlapTrigger;
  @Input() xPosition: DropdownPositionX = defaultOptions.xPosition;
  @Input() yPosition: DropdownPositionY = defaultOptions.yPosition;
  @Input() backdropClass = defaultOptions.backdropClass;
  @Input() hasBackdrop = defaultOptions.hasBackdrop;

  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();

  // isMenu = false;

  // toggleMenu() {
  //   this.isMenu = !this.isMenu;
  // }
}
