import { Component, Input } from '@angular/core';

@Component({
  selector: 'sidkik-pure-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class PureUserInfoComponent {
  @Input() mobileLayout = false;
  @Input() userPhoto: string | null = '';
  @Input() userName: string | null = '';
  @Input() userEmail: string | null = '';
}
