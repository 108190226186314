<div class="tw-min-h-full tw-flex tw-flex-col tw-justify-center tw-py-12 sm:tw-px-6 lg:tw-px-8">
  <div class="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md">
    <img class="tw-mx-auto tw-h-12 tw-w-auto tw-cursor-pointer" [src]="logoUrl" alt="Workflow" [routerLink]="['/']">
    <h2 class="tw-mt-6 tw-text-center tw-text-3xl tw-tracking-tight tw-font-bold tw-text-gray-900">Request new
      password</h2>
  </div>

  <div class="tw-mt-8 sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-md">
    <div class="tw-bg-white tw-py-8 tw-px-4 tw-shadow sm:tw-rounded-lg sm:tw-px-10">
      <form class="tw-space-y-6" [formGroup]="forgotForm" name="resetForm" novalidate>
        <div>
          <label for="" class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"> Email Address
          </label>
          <div class="tw-mt-1">
            <input email id="email" formControlName="email" name="email" type="email" autocomplete="email" required
              class="input">
            <sidkik-input-error [touched]="forgotForm.controls.email.touched" [dirty]="forgotForm.controls.email.dirty"
              [errors]="forgotForm.controls.email.errors">
            </sidkik-input-error>
          </div>
        </div>

        <div>
          <sidkik-process-button (click)="forgot()" [state]="state" [fullWidth]="true"
            processingText="Requesting password...">
            Request password
          </sidkik-process-button>

        </div>
      </form>


    </div>
  </div>