import { CustomerType } from '../constants';
import { ComboBoxOption } from '../models';

export enum PreFilterField {
  FormId = 'form_id',
  CourseId = 'course_id',
  ChallengeId = 'challenge_id',
}

export enum FilterType {
  CustomerOrdersSum = 'customer_orders_sum',
  CustomerDoubleOptIn = 'customer_double_opt_in',
  CustomerSingleOptIn = 'customer_single_opt_in',
  CustomerIsConvertedSubscriber = 'customer_is_converted_subscriber',
  CustomerType = 'customer_type',
  CustomerTrialExpired = 'customer_trial_expired',
  CustomerTags = 'customer_tags',
  CustomerLists = 'customer_lists',
  CustomerUnsubscribes = 'customer_unsubscribes',
  CustomerDoNotContact = 'customer_do_not_contact',
  CustomerCreated = 'customer_created',
  CustomerCourseStartedSectionPercentage = 'customer_course_started_section_percentage',
  CustomerCourseCompletedSectionPercentage = 'customer_course_completed_section_percentage',
  CustomerCourseStartedCourses = 'customer_course_started_courses',
  CustomerCoursePermission = 'customer_course_permission',
  CustomerContentPermission = 'customer_content_permission',
  CustomerChallengePermission = 'customer_challenge_permission',
  CustomerTotalPurchases = 'customer_total_purchases',
  Products = 'products',
  CustomerFormSubmitted = 'customer_form_submitted',
  CustomerFormFieldChecked = 'customer_form_field_checked',
}

export enum QueryOperator {
  UnSelected = 'UnSelected',
  In = 'IN',
  InAll = 'IN ALL',
  NotIn = 'NOT IN',
  EQ = '=',
  NE = '!=',
  GT = '>',
  GE = '>=',
  LT = '<',
  LE = '<=',
  IsTrue = 'IS TRUE',
  IsFalse = 'IS FALSE',
  IsSubscribedToAny = 'IN',
  IsSubscribedToAll = 'IN ALL',
  IsNotSubscribedTo = 'NOT IN',
  OwnsAny = 'IN',
  OwnsAll = 'IN ALL',
  DoesNotOwn = 'NOT IN',
  EqualsAll = 'EQUALS ALL',
  EqualsAny = 'EQUALS ANY',
  EqualsNone = 'EQUALS NONE',
}

export enum QueryOperatorType {
  UnSelected = 'unSelected',
  In = 'in',
  InAll = 'inAll',
  NotIn = 'notIn',
  EQ = 'eq',
  NE = 'ne',
  GT = 'gt',
  GE = 'ge',
  LT = 'lt',
  LE = 'le',
  IsTrue = 'isTrue',
  IsFalse = 'isFalse',
  IsSubscribedToAny = 'isSubscribedToAny',
  IsSubscribedToAll = 'isSubscribedToAll',
  IsNotSubscribedTo = 'isNotSubscribedTo',
  HasAny = 'hasAny',
  HasAll = 'hasAll',
  DoesNotHave = 'doesNotHave',
  OwnsAny = 'ownsAny',
  OwnsAll = 'ownsAll',
  DoesNotOwn = 'doesNotOwn',
  SpentMoreThan = 'spentMoreThan',
  SpentLessThan = 'spentLessThan',
  StartedAll = 'startedAll',
  StartedAny = 'startedAny',
  StartedNone = 'startedNone',
  SubmittedAll = 'submittedAll',
  SubmittedAny = 'submittedAny',
  SubmittedNone = 'submittedNone',
  IsType = 'isType',
  IsNotType = 'isNotType',
  EqualsAllChecked = 'equalsAllChecked',
  EqualsAnyChecked = 'equalsAnyChecked',
  EqualsNoneChecked = 'equalsNoneChecked',
  Before = 'before',
  After = 'after',
}

export enum QueryValueType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  Bool = 'bool',
  Percentage = 'percentage',
  Currency = 'currency',
}

export const QueryOperatorComboBoxOptions = {
  [QueryOperatorType.UnSelected]: undefined,
  [QueryOperatorType.Before]: {
    label: 'Before',
    value: QueryOperator.LT,
  },
  [QueryOperatorType.After]: {
    label: 'After',
    value: QueryOperator.GT,
  },
  [QueryOperatorType.EqualsAllChecked]: {
    label: 'Has All Checked',
    value: QueryOperator.EqualsAll,
  },
  [QueryOperatorType.EqualsAnyChecked]: {
    label: 'Has Any Checked',
    value: QueryOperator.EqualsAny,
  },
  [QueryOperatorType.EqualsNoneChecked]: {
    label: 'Has None Checked',
    value: QueryOperator.EqualsNone,
  },
  [QueryOperatorType.IsType]: {
    label: 'Is Type',
    value: QueryOperator.In,
  },
  [QueryOperatorType.IsNotType]: {
    label: 'Is Not Type',
    value: QueryOperator.NotIn,
  },
  [QueryOperatorType.In]: {
    label: 'In',
    value: QueryOperator.In,
  },
  [QueryOperatorType.NotIn]: {
    label: 'Not In',
    value: QueryOperator.NotIn,
  },
  [QueryOperatorType.IsTrue]: {
    label: 'Is True',
    value: QueryOperator.IsTrue,
  },
  [QueryOperatorType.IsFalse]: {
    label: 'Is False',
    value: QueryOperator.IsFalse,
  },
  [QueryOperatorType.EQ]: {
    label: '=',
    value: QueryOperator.EQ,
  },
  [QueryOperatorType.NE]: {
    label: '!=',
    value: QueryOperator.NE,
  },
  [QueryOperatorType.GT]: {
    label: '>',
    value: QueryOperator.GT,
  },
  [QueryOperatorType.GE]: {
    label: '>=',
    value: QueryOperator.GE,
  },
  [QueryOperatorType.LT]: {
    label: '<',
    value: QueryOperator.LT,
  },
  [QueryOperatorType.LE]: {
    label: '<=',
    value: QueryOperator.LE,
  },
  [QueryOperatorType.IsSubscribedToAny]: {
    label: 'Is Subscribed To Any',
    value: QueryOperator.IsSubscribedToAny,
  },
  [QueryOperatorType.IsSubscribedToAll]: {
    label: 'Is Subscribed To all',
    value: QueryOperator.IsSubscribedToAll,
  },
  [QueryOperatorType.IsNotSubscribedTo]: {
    label: 'Is Not Subscribed To',
    value: QueryOperator.IsNotSubscribedTo,
  },
  [QueryOperatorType.HasAny]: {
    label: 'Has Any',
    value: QueryOperator.In,
  },
  [QueryOperatorType.HasAll]: {
    label: 'Has All',
    value: QueryOperator.InAll,
  },
  [QueryOperatorType.DoesNotHave]: {
    label: 'Does Not Have',
    value: QueryOperator.NotIn,
  },
  [QueryOperatorType.OwnsAny]: {
    label: 'Owns Any',
    value: QueryOperator.In,
  },
  [QueryOperatorType.OwnsAll]: {
    label: 'Owns All',
    value: QueryOperator.InAll,
  },
  [QueryOperatorType.DoesNotOwn]: {
    label: 'Does Not Own',
    value: QueryOperator.NotIn,
  },
  [QueryOperatorType.SpentMoreThan]: {
    label: 'Spent More Than',
    value: QueryOperator.GT,
  },
  [QueryOperatorType.SpentLessThan]: {
    label: 'Spent Less Than',
    value: QueryOperator.LT,
  },
  [QueryOperatorType.StartedAll]: {
    label: 'Started All',
    value: QueryOperator.InAll,
  },
  [QueryOperatorType.StartedAny]: {
    label: 'Started Any',
    value: QueryOperator.In,
  },
  [QueryOperatorType.StartedNone]: {
    label: 'Started None',
    value: QueryOperator.NotIn,
  },
  [QueryOperatorType.SubmittedAll]: {
    label: 'Submitted All',
    value: QueryOperator.InAll,
  },
  [QueryOperatorType.SubmittedAny]: {
    label: 'Submitted Any',
    value: QueryOperator.In,
  },
  [QueryOperatorType.SubmittedNone]: {
    label: 'Submitted None',
    value: QueryOperator.NotIn,
  },
};

export enum QueryLogicalOperator {
  And = 'AND',
  Or = 'OR',
}

export enum QueryEntityTarget {
  Customer = 'customer',
  Affiliate = 'affiliate',
  EventActionForMail = 'evt_action_for_mail',
  EventCompletedSection = 'evt_completed_section',
  EventMadePurchase = 'evt_made_purchase',
  EventStartedChallenge = 'evt_started_challenge',
  EventStartedSection = 'evt_started_section',
  EventStartedCourse = 'evt_started_course',
  EventUndoCompletedSection = 'evt_undo_completed_section',
  Form = 'form',
  JournalEntry = 'journal_entry',
  Order = 'order',
}

export enum QueryFilterLookups {
  CustomerType = 'CustomerType',
  Course = 'Course',
  Challenge = 'Challenge',
  Email = 'Email',
  Campaign = 'Campaign',
  CustomerTag = 'CustomerTag',
  Membership = 'Membership',
  List = 'CustomerList',
  Product = 'Product',
  Form = 'Form',
  FormFieldCheckbox = 'FormFieldCheckbox',
}

export interface QueryFilterDefinition {
  type: FilterType;
  label: string;
  valueType: QueryValueType;
  operators: ComboBoxOption[];
  target: QueryEntityTarget;
  lookup?: QueryFilterLookups;
  dynamicLookup?: QueryFilterLookups;
  prefilterField?: PreFilterField;
  prefilter: boolean;
}

export interface QueryFilter {
  target: string;
  filter: string;
  filterValues?: string[];
  operator: QueryOperator;
  values: string[];
  valueType?: QueryValueType;
  preFilter?: string;
}

export interface QueryCriteria {
  target: string;
  logicalOperator: QueryLogicalOperator;
  filters: QueryFilter[];
}

export interface Query {
  size?: number;
  token?: string;
  criteria: QueryCriteria[];
  logicalOperator: QueryLogicalOperator;
  idOnlyResults?: boolean;
  segmentId?: string;
  segmentName?: string;
  saveSegment?: boolean;
  deleteSegment?: boolean;
}

export const queryFilterDefinitions: Record<string, QueryFilterDefinition> = {
  [FilterType.CustomerFormFieldChecked]: {
    type: FilterType.CustomerFormFieldChecked,
    label: 'Form Field Checked',
    valueType: QueryValueType.String,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.EqualsAllChecked],
      QueryOperatorComboBoxOptions[QueryOperatorType.EqualsAnyChecked],
      QueryOperatorComboBoxOptions[QueryOperatorType.EqualsNoneChecked],
    ],
    target: QueryEntityTarget.Form,
    lookup: QueryFilterLookups.Form,
    dynamicLookup: QueryFilterLookups.FormFieldCheckbox,
    prefilterField: PreFilterField.FormId,
    prefilter: true,
  },
  [FilterType.CustomerFormSubmitted]: {
    type: FilterType.CustomerFormSubmitted,
    label: 'Form Submitted',
    valueType: QueryValueType.String,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.SubmittedAll],
      QueryOperatorComboBoxOptions[QueryOperatorType.SubmittedAny],
      QueryOperatorComboBoxOptions[QueryOperatorType.SubmittedNone],
    ],
    target: QueryEntityTarget.Form,
    lookup: QueryFilterLookups.Form,
    prefilter: false,
  },
  [FilterType.Products]: {
    type: FilterType.Products,
    label: 'Products',
    valueType: QueryValueType.String,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.OwnsAny],
      QueryOperatorComboBoxOptions[QueryOperatorType.OwnsAll],
      QueryOperatorComboBoxOptions[QueryOperatorType.DoesNotOwn],
    ],
    target: QueryEntityTarget.Order,
    lookup: QueryFilterLookups.Product,
    prefilter: false,
  },
  [FilterType.CustomerOrdersSum]: {
    type: FilterType.CustomerOrdersSum,
    label: 'Lifetime Value',
    valueType: QueryValueType.Currency,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.SpentMoreThan],
      QueryOperatorComboBoxOptions[QueryOperatorType.SpentLessThan],
    ],
    target: QueryEntityTarget.Order,
    prefilter: false,
  },

  [FilterType.CustomerDoubleOptIn]: {
    type: FilterType.CustomerDoubleOptIn,
    label: 'Double Opted In',
    valueType: QueryValueType.Bool,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.IsTrue],
      QueryOperatorComboBoxOptions[QueryOperatorType.IsFalse],
    ],
    target: QueryEntityTarget.Customer,
    prefilter: false,
  },

  [FilterType.CustomerSingleOptIn]: {
    type: FilterType.CustomerSingleOptIn,
    label: 'Single Opted In',
    valueType: QueryValueType.Bool,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.IsTrue],
      QueryOperatorComboBoxOptions[QueryOperatorType.IsFalse],
    ],
    target: QueryEntityTarget.Customer,
    prefilter: false,
  },
  [FilterType.CustomerIsConvertedSubscriber]: {
    type: FilterType.CustomerIsConvertedSubscriber,
    label: 'Is Converted Subscriber',
    valueType: QueryValueType.Bool,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.IsTrue],
      QueryOperatorComboBoxOptions[QueryOperatorType.IsFalse],
    ],
    target: QueryEntityTarget.Customer,
    prefilter: false,
  },
  [FilterType.CustomerType]: {
    type: FilterType.CustomerType,
    label: 'Customer Type',
    valueType: QueryValueType.String,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.IsType],
      QueryOperatorComboBoxOptions[QueryOperatorType.IsNotType],
    ],
    target: QueryEntityTarget.Customer,
    lookup: QueryFilterLookups.CustomerType,
    prefilter: false,
  },
  [FilterType.CustomerTrialExpired]: {
    type: FilterType.CustomerTrialExpired,
    label: 'Trial Expired',
    valueType: QueryValueType.Bool,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.IsTrue],
      QueryOperatorComboBoxOptions[QueryOperatorType.IsFalse],
    ],
    target: QueryEntityTarget.Customer,
    prefilter: false,
  },
  [FilterType.CustomerTags]: {
    type: FilterType.CustomerTags,
    label: 'Tags',
    valueType: QueryValueType.String,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.HasAll],
      QueryOperatorComboBoxOptions[QueryOperatorType.HasAny],
      QueryOperatorComboBoxOptions[QueryOperatorType.DoesNotHave],
    ],
    target: QueryEntityTarget.Customer,
    lookup: QueryFilterLookups.CustomerTag,
    prefilter: false,
  },
  [FilterType.CustomerLists]: {
    type: FilterType.CustomerLists,
    label: 'Lists',
    valueType: QueryValueType.String,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.IsSubscribedToAny],
      QueryOperatorComboBoxOptions[QueryOperatorType.IsSubscribedToAll],
      QueryOperatorComboBoxOptions[QueryOperatorType.IsNotSubscribedTo],
    ],
    target: QueryEntityTarget.Customer,
    lookup: QueryFilterLookups.List,
    prefilter: false,
  },
  [FilterType.CustomerUnsubscribes]: {
    type: FilterType.CustomerUnsubscribes,
    label: 'Unsubscribes',
    valueType: QueryValueType.String,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.HasAll],
      QueryOperatorComboBoxOptions[QueryOperatorType.HasAny],
      QueryOperatorComboBoxOptions[QueryOperatorType.DoesNotHave],
    ],
    target: QueryEntityTarget.Customer,
    lookup: QueryFilterLookups.List,
    prefilter: false,
  },
  [FilterType.CustomerDoNotContact]: {
    type: FilterType.CustomerDoNotContact,
    label: 'Do Not Contact',
    valueType: QueryValueType.Bool,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.IsTrue],
      QueryOperatorComboBoxOptions[QueryOperatorType.IsFalse],
    ],
    target: QueryEntityTarget.Customer,
    prefilter: false,
  },
  [FilterType.CustomerCreated]: {
    type: FilterType.CustomerCreated,
    label: 'Customer Created',
    valueType: QueryValueType.Date,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.After],
      QueryOperatorComboBoxOptions[QueryOperatorType.Before],
    ],
    target: QueryEntityTarget.Customer,
    prefilter: false,
  },
  [FilterType.CustomerCourseCompletedSectionPercentage]: {
    type: FilterType.CustomerCourseCompletedSectionPercentage,
    label: 'Course Completed Section Percentage',
    valueType: QueryValueType.Percentage,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.GT],
      QueryOperatorComboBoxOptions[QueryOperatorType.GE],
      QueryOperatorComboBoxOptions[QueryOperatorType.LT],
      QueryOperatorComboBoxOptions[QueryOperatorType.LE],
    ],
    target: QueryEntityTarget.EventCompletedSection,
    lookup: QueryFilterLookups.Course,
    prefilterField: PreFilterField.CourseId,
    prefilter: true,
  },
  [FilterType.CustomerCourseStartedSectionPercentage]: {
    type: FilterType.CustomerCourseStartedSectionPercentage,
    label: 'Course Started Section Percentage',
    valueType: QueryValueType.Percentage,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.GT],
      QueryOperatorComboBoxOptions[QueryOperatorType.GE],
      QueryOperatorComboBoxOptions[QueryOperatorType.LT],
      QueryOperatorComboBoxOptions[QueryOperatorType.LE],
    ],
    target: QueryEntityTarget.EventStartedSection,
    lookup: QueryFilterLookups.Course,
    prefilterField: PreFilterField.CourseId,
    prefilter: true,
  },
  [FilterType.CustomerCourseStartedCourses]: {
    type: FilterType.CustomerCourseStartedCourses,
    label: 'Course Started Courses',
    valueType: QueryValueType.String,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.StartedAll],
      QueryOperatorComboBoxOptions[QueryOperatorType.StartedAny],
      QueryOperatorComboBoxOptions[QueryOperatorType.StartedNone],
    ],
    target: QueryEntityTarget.EventStartedCourse,
    lookup: QueryFilterLookups.Course,
    prefilter: false,
  },
  [FilterType.CustomerCoursePermission]: {
    type: FilterType.CustomerCoursePermission,
    label: 'Course Access',
    valueType: QueryValueType.String,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.HasAll],
      QueryOperatorComboBoxOptions[QueryOperatorType.HasAny],
      QueryOperatorComboBoxOptions[QueryOperatorType.DoesNotHave],
    ],
    target: QueryEntityTarget.Customer,
    lookup: QueryFilterLookups.Course,
    prefilter: false,
  },
  [FilterType.CustomerContentPermission]: {
    type: FilterType.CustomerContentPermission,
    label: 'Membership Access',
    valueType: QueryValueType.String,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.HasAll],
      QueryOperatorComboBoxOptions[QueryOperatorType.HasAny],
      QueryOperatorComboBoxOptions[QueryOperatorType.DoesNotHave],
    ],
    target: QueryEntityTarget.Customer,
    lookup: QueryFilterLookups.Membership,
    prefilter: false,
  },
  [FilterType.CustomerChallengePermission]: {
    type: FilterType.CustomerChallengePermission,
    label: 'Challenge Access',
    valueType: QueryValueType.String,
    operators: [
      QueryOperatorComboBoxOptions[QueryOperatorType.HasAll],
      QueryOperatorComboBoxOptions[QueryOperatorType.HasAny],
      QueryOperatorComboBoxOptions[QueryOperatorType.DoesNotHave],
    ],
    target: QueryEntityTarget.Customer,
    lookup: QueryFilterLookups.Challenge,
    prefilter: false,
  },
};
