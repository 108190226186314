import { boolean } from 'boolean';
import { type LiqeQuery } from 'liqe';
import { parse, test } from 'liqe';
import { type logLevels, type LogWriter, type Message } from 'roarr';
import { getLogLevelName } from 'roarr';

declare global {
  var logger: Logger;
}

type Configuration = {
  logMethods?: LogMethods;
  storage?: Storage;
};

export const createLogMethods = (): LogMethods => {
  return {
    debug: console.debug.bind(console),
    error: console.error.bind(console),
    fatal: console.error.bind(console),
    info: console.info.bind(console),
    trace: console.debug.bind(console),
    warn: console.warn.bind(console),
  };
};

type LogLevelName = keyof typeof logLevels;

export type LogMethods = {
  [key in LogLevelName]: (...data: unknown[]) => void;
};

export type Storage = {
  getItem: (name: string) => string | null;
  setItem: (name: string, value: string) => void;
};

export type Logger = {
  debug: (namespace: string, ...args: any[]) => void;
  error: (namespace: string, ...args: any[]) => void;
  fatal: (namespace: string, ...args: any[]) => void;
  info: (namespace: string, ...args: any[]) => void;
  trace: (namespace: string, ...args: any[]) => void;
  warn: (namespace: string, ...args: any[]) => void;
};

const logLevelColors: {
  [key in LogLevelName]: { backgroundColor: string; color: string };
} = {
  debug: {
    backgroundColor: '#712bde',
    color: '#fff',
  },
  error: {
    backgroundColor: '#f05033',
    color: '#fff',
  },
  fatal: {
    backgroundColor: '#f05033',
    color: '#fff',
  },
  info: {
    backgroundColor: '#3174f1',
    color: '#fff',
  },
  trace: {
    backgroundColor: '#666',
    color: '#fff',
  },
  warn: {
    backgroundColor: '#f5a623',
    color: '#000',
  },
};

const namespaceColors: {
  [key in LogLevelName]: { color: string };
} = {
  debug: {
    color: '#8367d3',
  },
  error: {
    color: '#ff1a1a',
  },
  fatal: {
    color: '#ff1a1a',
  },
  info: {
    color: '#3291ff',
  },
  trace: {
    color: '#999',
  },
  warn: {
    color: '#f7b955',
  },
};

const findLiqeQuery = (logFilter: string): LiqeQuery | null => {
  return logFilter ? parse(logFilter) : null;
};

export const createLogWriter = (
  configuration: Configuration = {},
  logEnabled: any = false,
  logFilter: string = ''
): Logger => {
  const logMethods = configuration?.logMethods ?? createLogMethods();

  if (!boolean(logEnabled)) {
    return {
      debug: () => {},
      error: () => {},
      fatal: () => {},
      info: () => {},
      trace: () => {},
      warn: () => {},
    };
  }

  const liqeQuery = findLiqeQuery(logFilter);

  const logIt = (level: number, namespace: string, ...args: any[]) => {
    if (liqeQuery && !test(liqeQuery, namespace + ' ' + args.join(' '))) {
      return;
    }

    const logLevelName = getLogLevelName(Number(level));
    const logMethod = logMethods[logLevelName];

    const logColor = logLevelColors[logLevelName];
    const styles = `
      background-color: ${logColor.backgroundColor};
      color: ${logColor.color};
      font-weight: bold;
    `;
    const namespaceStyles = `
      color: ${namespaceColors[logLevelName].color};
    `;

    logMethod(
      `%c ${logLevelName} %c [${namespace}] %O`,
      styles,
      namespaceStyles,
      ...args
    );
  };

  return {
    info: (namespace: string, ...args: any[]) => {
      logIt(30, namespace, ...args);
    },
    debug: (namespace: string, ...args: any[]) => {
      logIt(20, namespace, ...args);
    },
    error: (namespace: string, ...args: any[]) => {
      logIt(50, namespace, ...args);
    },
    trace: (namespace: string, ...args: any[]) => {
      logIt(10, namespace, ...args);
    },
    fatal: (namespace: string, ...args: any[]) => {
      logIt(60, namespace, ...args);
    },
    warn: (namespace: string, ...args: any[]) => {
      logIt(40, namespace, ...args);
    },
  };
};

const INIT_ACTION = '@ngrx/store/init';

const repeat = (str: string, times: number) => new Array(times + 1).join(str);
const pad = (num: number, maxLength: number) =>
  repeat(`0`, maxLength - num.toString().length) + num;
const formatTime = (time: Date) =>
  `@ ${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(
    time.getSeconds(),
    2
  )}.${pad(time.getMilliseconds(), 3)}`;
const timer =
  typeof performance !== `undefined` && typeof performance.now === `function`
    ? performance
    : Date;

const getLogLevel = (level: any, action: any, payload: any, type: any) => {
  switch (typeof level) {
    case `object`:
      return typeof level[type] === `function`
        ? level[type](...payload)
        : level[type];
    case `function`:
      return level(action);
    default:
      return level === 'log' ? 'info' : level;
  }
};

const printBuffer = (options: any) => (logBuffer: any) => {
  const { actionTransformer, collapsed, colors, timestamp, duration, level } =
    options;
  logBuffer.forEach((logEntry: any, key: any) => {
    const { started, startedTime, action, error } = logEntry;
    const prevState = logEntry.prevState.nextState
      ? logEntry.prevState.nextState
      : '(Empty)';
    let { took, nextState } = logEntry;
    const nextEntry = logBuffer[key + 1];
    if (nextEntry) {
      nextState = nextEntry.prevState;
      took = nextEntry.started - started;
    }

    const formattedAction = actionTransformer(action);
    const isCollapsed =
      typeof collapsed === `function`
        ? collapsed(() => nextState, action)
        : collapsed;

    const formattedTime = formatTime(startedTime);
    const title = `action ${timestamp ? formattedTime : ``} ${
      formattedAction.type
    } ${duration ? `(in ${took.toFixed(2)} ms)` : ``}`;

    try {
      if (isCollapsed) {
        if (colors.title) (logger as any).groupCollapsed(`${title}`);
        else (logger as any).groupCollapsed(title);
      } else {
        if (colors.title) (logger as any).group(`${title}`);
        else (logger as any).group(title);
      }
    } catch (e) {
      logger.info('ngrx', title);
    }

    const prevStateLevel = getLogLevel(
      level,
      formattedAction,
      [prevState],
      `prevState`
    );
    const actionLevel = getLogLevel(
      level,
      formattedAction,
      [formattedAction],
      `action`
    );
    const errorLevel = getLogLevel(
      level,
      formattedAction,
      [error, prevState],
      `error`
    );
    const nextStateLevel = getLogLevel(
      level,
      formattedAction,
      [nextState],
      `nextState`
    );

    if (prevStateLevel) {
      if (colors.prevState) (logger as any)[prevStateLevel]('ngrx', prevState);
      else (logger as any)[prevStateLevel](`prev state`, prevState);
    }

    if (actionLevel) {
      if (colors.action) (logger as any)[actionLevel]('ngrx', formattedAction);
      else (logger as any)[actionLevel](`action`, formattedAction);
    }

    if (error && errorLevel) {
      if (colors.error) (logger as any)[errorLevel]('ngrx', error);
      else (logger as any)[errorLevel](`error`, error);
    }

    if (nextStateLevel) {
      if (colors.nextState) (logger as any)[nextStateLevel]('ngrx', nextState);
      else (logger as any)[nextStateLevel](`next state`, nextState);
    }

    try {
      (logger as any).groupEnd();
    } catch (e) {
      (logger as any).info(`—— log end ——`);
    }
  });
  logBuffer.length = 0;
};

const isAllowed = (action: any, filter: any) => {
  if (!filter) {
    return true;
  }
  if (filter.whitelist && filter.whitelist.length) {
    return filter.whitelist.indexOf(action.type) !== -1;
  }
  return filter.blacklist && filter.blacklist.indexOf(action.type) === -1;
};

export const storeLogger =
  (opts: LoggerOptions = {}) =>
  (reducer: Function) => {
    let log = {};
    const ua =
      typeof window !== 'undefined' && window.navigator.userAgent
        ? window.navigator.userAgent
        : '';
    let ms_ie = false;
    //fix for action display in IE
    const old_ie = ua.indexOf('MSIE ');
    const new_ie = ua.indexOf('Trident/');

    if (old_ie > -1 || new_ie > -1) {
      ms_ie = true;
    }

    let colors: LoggerColorsOption;
    if (ms_ie) {
      // Setting colors functions to null when it's an IE browser.
      colors = {
        title: null,
        prevState: null,
        action: null,
        nextState: null,
        error: null,
      };
    } else {
      colors = {
        title: null,
        prevState: () => '#9E9E9E',
        action: () => '#03A9F4',
        nextState: () => '#4CAF50',
        error: () => '#F20404',
      };
    }

    const defaults: LoggerOptions = {
      level: 'log',
      collapsed: false,
      duration: true,
      timestamp: true,
      stateTransformer: (state) => state,
      actionTransformer: (actn) => actn,
      filter: {
        whitelist: [],
        blacklist: [],
      },
      colors: colors,
    };

    const options = Object.assign({}, defaults, opts);
    const { stateTransformer } = options;
    if (!stateTransformer) return;
    const buffer = printBuffer(options);

    return function (state: any, action: any) {
      let preLog = {
        started: timer.now(),
        startedTime: new Date(),
        prevState: stateTransformer(log),
        action,
      };

      let nextState = reducer(state, action);

      let postLog = {
        took: timer.now() - preLog.started,
        nextState: stateTransformer(nextState),
      };
      log = Object.assign({}, preLog, postLog);
      //ignore init action fired by store and devtools
      if (action.type !== INIT_ACTION && isAllowed(action, options.filter)) {
        buffer([log]);
      }

      return nextState;
    };
  };

export interface LoggerOptions {
  /**
   * 'log' | 'console' | 'warn' | 'error' | 'info'. Default: 'log'
   */
  level?: any;
  /**
   * Should log group be collapsed? default: false
   */
  collapsed?: boolean;
  /**
   * Print duration with action? default: true
   */
  duration?: boolean;
  /**
   * Print timestamp with action? default: true
   */
  timestamp?: boolean;
  filter?: LoggerFilterOption;
  /**
   * Transform state before print default: state => state
   */
  stateTransformer?: (state: Object) => Object;
  /**
   * Transform action before print default: actn => actn
   */
  actionTransformer?: (actn: Object) => Object;
  colors?: LoggerColorsOption;
}

export interface LoggerFilterOption {
  /**
   * Only print actions included in this list - has priority over blacklist
   */
  whitelist?: string[];
  /**
   * Only print actions that are NOT included in this list
   */
  blacklist?: string[];
}

export interface LoggerColorsOption {
  title: ((action: Object) => string) | null;
  prevState: ((prevState: Object) => string) | null;
  action: ((action: Object) => string) | null;
  nextState: ((nextState: Object) => string) | null;
  error: ((error: any, prevState: Object) => string) | null;
}
