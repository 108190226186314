import { createAction, props } from '@ngrx/store';
import {
  loadMeFailureType,
  loadMeSuccessType,
  loadMeType,
  loginFailureType,
  loginSuccessType,
  loginType,
  logoutFailureType,
  logoutSuccessType,
  logoutType,
  noOperation,
  reloadTokenSuccessType,
  workerLoggedInType,
} from '@sidkik/global';
import { IdTokenResult } from '@angular/fire/auth';
import { User } from './authz.models';
import { CustomerProperties } from '@sidkik/db';

export const login = createAction(loginType);

export const loginSuccess = createAction(
  loginSuccessType,
  props<{ user: User; idToken: IdTokenResult; navigate: boolean }>()
);

export const loginFailure = createAction(
  loginFailureType,
  props<{ error: any }>()
);

export const logout = createAction(logoutType);

export const logoutSuccess = createAction(logoutSuccessType);

export const logoutFailure = createAction(
  logoutFailureType,
  props<{ error: any }>()
);

export const reloadTokenSuccess = createAction(
  reloadTokenSuccessType,
  props<{ user: User; idToken: IdTokenResult }>()
);

export const loadMe = createAction(loadMeType, props<{ attempt?: number }>());

export const loadMeSuccess = createAction(
  loadMeSuccessType,
  props<{ me: CustomerProperties }>()
);

export const loadMeFailure = createAction(
  loadMeFailureType,
  props<{ error: any; attempt?: number }>()
);

export const workerLoggedIn = createAction(
  workerLoggedInType,
  props<{ uid: string }>()
);

export const noop = createAction(noOperation);
