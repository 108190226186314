import { EventEmitter, OnDestroy, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { SortDirection } from './sort-direction';
import { SortState } from './sort-state';
import { SortComponent } from './sort.component';

@Directive({
  selector: '[sidkikSortable]',
  exportAs: 'sidkikSortable',
})
export class SortableDirective implements OnDestroy {
  sortChange: EventEmitter<SortState> = new EventEmitter();
  private sorters: Map<string, SortComponent> = new Map<
    string,
    SortComponent
  >();
  private sortersSubs: Subscription[] = [];
  direction: SortDirection = '';
  active!: string;

  ngOnDestroy() {
    this.sortersSubs.every((s) => s.unsubscribe());
  }

  register(sort: SortComponent) {
    this.sorters.set(sort.field, sort);
    this.sortersSubs.push(
      sort.sortChange.subscribe((evt) => {
        this.active = evt.field;
        this.direction = evt.direction;
        this.clearSorts(evt.field);
        this.sortChange.emit(evt);
      })
    );
  }

  clearSorts(exception: string) {
    for (const entry of this.sorters.values()) {
      if (entry.field !== exception) {
        entry.clearSort();
      }
    }
  }
}
