import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sidkik-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    // class: 'py-1',
    'attr.role': 'none',
  },
})
export class DropdownItemComponent {
  @Input() dividerTop = false;
  // @Input() overlapTrigger = defaultOptions.overlapTrigger;
  // @Input() xPosition: DropdownPositionX = defaultOptions.xPosition;
  // @Input() yPosition: DropdownPositionY = defaultOptions.yPosition;
  // @Input() backdropClass = defaultOptions.backdropClass;
  // @Input() hasBackdrop = defaultOptions.hasBackdrop;
  // @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
  // @Output() closed = new EventEmitter<void>();
  // isMenu = false;
  // toggleMenu() {
  //   this.isMenu = !this.isMenu;
  // }
}
