import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Route,
  UrlSegment,
  UrlTree,
  CanActivateFn,
  CanMatchFn,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DbService } from '../services/db.service';

@Injectable({
  providedIn: 'root',
})
export class DBGuard implements CanActivate {
  constructor(private dbService: DbService) { }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.dbService.ready$.pipe(filter((loaded) => loaded));
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.dbService.ready$.pipe(filter((loaded) => loaded));
  }
}


export const canActivateDBReady: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const dbService = inject(DbService);
  return dbService.ready$.pipe(filter((loaded) => loaded));
};

export const canMatchDBReady: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const dbService = inject(DbService);
  return dbService.ready$.pipe(filter((loaded) => loaded));
}