import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { OTLPExporterConfigBase } from '@opentelemetry/otlp-exporter-base';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { Resource } from '@opentelemetry/resources';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import * as otel from '@opentelemetry/api';

import { filter, take, tap } from 'rxjs/operators';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { ROOT_CONTEXT } from '@opentelemetry/api';
import { SpanType } from '../constants/telemetry/spans';
import { GlobalFacade } from '../+state/global.facade';
import { APP_CONFIG, AppConfig } from '../models/config';

export interface HTTPTraceHeader {
  traceparent?: string;
  tracestate?: string;
}

@Injectable({ providedIn: 'root' })
export class TraceService {
  // hold spans in map to avoid passing around from component to component
  map = new Map<string, otel.Span>();
  httpTraceContext = new W3CTraceContextPropagator();
  provider!: WebTracerProvider;
  tracer!: otel.Tracer;
  userId = 'nobody'; // set to nobody until authentication

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject('APP_VERSION') private appVersion: string,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private globalFacade: GlobalFacade
  ) {
    this.initTracing();
    // set user
    this.globalFacade.userId$
      .pipe(
        filter((userId) => userId !== null && userId !== undefined),
        take(1),
        tap((userId) =>
          logger.debug(
            'global:trace.service',
            'setting user for tracing',
            userId
          )
        )
      )
      .subscribe((userId: string) => (this.userId = userId));
  }

  initTracing() {
    if (isPlatformServer(this.platformId)) return;

    this.provider = new WebTracerProvider({
      resource: new Resource({
        // [SemanticResourceAttributes.HOST_NAME]: this.document.location.host,
        [SemanticResourceAttributes.SERVICE_NAME]:
          this.appConfig?.telemetry?.trace?.serviceName,
        'sidkik.tenant': this.appConfig?.firebase?.projectId,
        'service.version': this.appVersion,
        'service.instance.id': this.appVersion,
      }),
    });
    const apiUrl = (this.appConfig.api.endpoint as string)
      .replace('0/api', '0/otlp')
      .replace('us/api', 'us/otlp');
    logger.debug('global:trace.service', 'initializing tracing', apiUrl);
    const collectorExporterConfig: OTLPExporterConfigBase = {
      headers: {}, // needed to push from beacon - which fails with * CORS origins to xhr - headers forces xhr
      url: apiUrl,
    };
    const exporter = new OTLPTraceExporter(collectorExporterConfig);
    this.provider.addSpanProcessor(
      new BatchSpanProcessor(exporter, {
        // The maximum queue size. After the size is reached spans are dropped.
        maxQueueSize: 100,
        // The maximum batch size of every export. It must be smaller or equal to maxQueueSize.
        maxExportBatchSize: 10,
        // The interval between two consecutive exports
        scheduledDelayMillis: 500,
        // How long the export can run before it is cancelled
        exportTimeoutMillis: 30000,
      })
    );
    this.provider.register({
      // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
      contextManager: new ZoneContextManager(),
    });
    this.tracer = otel.trace.getTracer('sidkik');
  }

  startSpan(spanType: SpanType, attributes?: any) {
    if (isPlatformServer(this.platformId)) return;
    let span = this.tracer.startSpan(spanType.name, { kind: spanType.kind });
    logger.trace('global:trace.service:startSpan', spanType, span);
    span = this.setAttributes(span, spanType, attributes);
    this.map.set(spanType.name, span);
  }

  private setAttributes(
    span: otel.Span,
    spanType: SpanType,
    extras?: any
  ): otel.Span {
    if (isPlatformServer(this.platformId)) return {} as otel.Span;
    return span.setAttributes({
      ...{
        'sidkik.user': this.userId,
        'sidkik.tenant': this.appConfig?.firebase?.projectId,
        'enduser.id': this.userId,
        'service.name':
          spanType.service || this.appConfig?.telemetry?.trace?.serviceName,
        'service.version': this.appVersion,
        'service.instance.id': this.appVersion,
      },
      ...extras,
    });
  }

  startChildSpan(spanType: SpanType, parentSpanType: SpanType) {
    if (isPlatformServer(this.platformId)) return;
    const parentSpan = this.map.get(parentSpanType.name);
    if (parentSpan) {
      logger.trace(
        'global:trace.service:startChildSpan',
        'start child span with parent span',
        spanType,
        parentSpanType
      );

      const ctx = otel.trace.setSpan(otel.context.active(), parentSpan);
      const childSpan = this.tracer.startSpan(spanType.name, undefined, ctx);
      this.setAttributes(childSpan, spanType);
      this.map.set(spanType.name, childSpan);
    } else {
      logger.trace(
        'global:trace.service:startChildSpan',
        'start child span without parent span',
        spanType,
        parentSpanType
      );

      this.startSpan(spanType);
    }
  }

  endSpan(spanType: SpanType, err?: Error) {
    if (isPlatformServer(this.platformId)) return;
    logger.trace('global:trace.service:endSpan', 'ending span', spanType);
    const span = this.map.get(spanType.name);
    if (span) {
      if (err) {
        span.recordException(err);
        span.setStatus({
          code: otel.SpanStatusCode.ERROR,
          message: err.message,
        });
      }
      span.end();
    }
  }

  getHTTPHeaderPropagators(spanType: SpanType): HTTPTraceHeader {
    const carrier: HTTPTraceHeader = { traceparent: '', tracestate: '' };
    const span = this.map.get(spanType.name);
    if (span) {
      this.httpTraceContext.inject(
        otel.trace.setSpanContext(ROOT_CONTEXT, span.spanContext()),
        carrier,
        otel.defaultTextMapSetter
      );
    }
    return carrier;
  }

  getDocumentTrace(spanType: SpanType): {
    parent?: string;
    state?: string;
  } {
    const httpHeader = this.getHTTPHeaderPropagators(spanType);
    return {
      parent: httpHeader.traceparent,
      state: httpHeader.tracestate,
    };
  }
}
