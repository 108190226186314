import { DateTime } from 'luxon';
import { ComboBoxOption } from './ui';

export interface TimeSlot {
  start: string;
  end: string;
}

export enum VirtualBookingLocationVendor {
  Zoom = 'zoom',
  Google = 'google',
}

export const virtualBookingLocations: ComboBoxOption[] = [
  { label: 'None', value: 'none', description: 'No virtual location' },
  {
    label: 'Zoom',
    value: VirtualBookingLocationVendor.Zoom,
    description:
      'Zoom video conferencing. Meeting links will be automatically generated.',
  },
  // { label: 'Google Meet', value: 'google' },
];

const timezones = [
  { name: 'Africa/Abidjan', offset: 'UTC+0', dst: false },
  { name: 'Africa/Accra', offset: 'UTC+0', dst: false },
  { name: 'Africa/Addis_Ababa', offset: 'UTC+3', dst: false },
  { name: 'Africa/Algiers', offset: 'UTC+1', dst: false },
  { name: 'Africa/Asmara', offset: 'UTC+3', dst: false },
  { name: 'Africa/Bamako', offset: 'UTC+0', dst: false },
  { name: 'Africa/Bangui', offset: 'UTC+1', dst: false },
  { name: 'Africa/Banjul', offset: 'UTC+0', dst: false },
  { name: 'Africa/Bissau', offset: 'UTC+0', dst: false },
  { name: 'Africa/Blantyre', offset: 'UTC+2', dst: false },
  { name: 'Africa/Brazzaville', offset: 'UTC+1', dst: false },
  { name: 'Africa/Bujumbura', offset: 'UTC+2', dst: false },
  { name: 'Africa/Cairo', offset: 'UTC+2', dst: true },
  { name: 'Africa/Casablanca', offset: 'UTC+1', dst: true },
  { name: 'Africa/Ceuta', offset: 'UTC+1', dst: true },
  { name: 'Africa/Conakry', offset: 'UTC+0', dst: false },
  { name: 'Africa/Dakar', offset: 'UTC+0', dst: false },
  { name: 'Africa/Dar_es_Salaam', offset: 'UTC+3', dst: false },
  { name: 'Africa/Djibouti', offset: 'UTC+3', dst: false },
  { name: 'Africa/Douala', offset: 'UTC+1', dst: false },
  { name: 'Africa/El_Aaiun', offset: 'UTC+1', dst: false },
  { name: 'Africa/Freetown', offset: 'UTC+0', dst: false },
  { name: 'Africa/Gaborone', offset: 'UTC+2', dst: false },
  { name: 'Africa/Harare', offset: 'UTC+2', dst: false },
  { name: 'Africa/Johannesburg', offset: 'UTC+2', dst: false },
  { name: 'Africa/Juba', offset: 'UTC+3', dst: false },
  { name: 'Africa/Kampala', offset: 'UTC+3', dst: false },
  { name: 'Africa/Khartoum', offset: 'UTC+2', dst: false },
  { name: 'Africa/Kigali', offset: 'UTC+2', dst: false },
  { name: 'Africa/Kinshasa', offset: 'UTC+1', dst: false },
  { name: 'Africa/Lagos', offset: 'UTC+1', dst: false },
  { name: 'Africa/Libreville', offset: 'UTC+1', dst: false },
  { name: 'Africa/Lome', offset: 'UTC+0', dst: false },
  { name: 'Africa/Luanda', offset: 'UTC+1', dst: false },
  { name: 'Africa/Lubumbashi', offset: 'UTC+2', dst: false },
  { name: 'Africa/Lusaka', offset: 'UTC+2', dst: false },
  { name: 'Africa/Malabo', offset: 'UTC+1', dst: false },
  { name: 'Africa/Maputo', offset: 'UTC+2', dst: false },
  { name: 'Africa/Maseru', offset: 'UTC+2', dst: false },
  { name: 'Africa/Mbabane', offset: 'UTC+2', dst: false },
  { name: 'Africa/Mogadishu', offset: 'UTC+3', dst: false },
  { name: 'Africa/Monrovia', offset: 'UTC+0', dst: false },
  { name: 'Africa/Nairobi', offset: 'UTC+3', dst: false },
  { name: 'Africa/Ndjamena', offset: 'UTC+1', dst: false },
  { name: 'Africa/Niamey', offset: 'UTC+1', dst: false },
  { name: 'Africa/Nouakchott', offset: 'UTC+0', dst: false },
  { name: 'Africa/Ouagadougou', offset: 'UTC+0', dst: false },
  { name: 'Africa/Porto-Novo', offset: 'UTC+1', dst: false },
  { name: 'Africa/Sao_Tome', offset: 'UTC+0', dst: false },
  { name: 'Africa/Tripoli', offset: 'UTC+2', dst: true },
  { name: 'Africa/Tunis', offset: 'UTC+1', dst: true },
  { name: 'Africa/Windhoek', offset: 'UTC+2', dst: true },
  { name: 'America/Adak', offset: 'UTC-10', dst: true },
  { name: 'America/Anchorage', offset: 'UTC-9', dst: true },
  { name: 'America/Anguilla', offset: 'UTC-4', dst: false },
  { name: 'America/Antigua', offset: 'UTC-4', dst: false },
  { name: 'America/Araguaina', offset: 'UTC-3', dst: true },
  { name: 'America/Argentina/Buenos_Aires', offset: 'UTC-3', dst: false },
  { name: 'America/Argentina/Catamarca', offset: 'UTC-3', dst: false },
  { name: 'America/Argentina/Cordoba', offset: 'UTC-3', dst: false },
  { name: 'America/Argentina/Jujuy', offset: 'UTC-3', dst: false },
  { name: 'America/Argentina/La_Rioja', offset: 'UTC-3', dst: false },
  { name: 'America/Argentina/Mendoza', offset: 'UTC-3', dst: false },
  { name: 'America/Argentina/Rio_Gallegos', offset: 'UTC-3', dst: false },
  { name: 'America/Argentina/Salta', offset: 'UTC-3', dst: false },
  { name: 'America/Argentina/San_Juan', offset: 'UTC-3', dst: false },
  { name: 'America/Argentina/San_Luis', offset: 'UTC-3', dst: false },
  { name: 'America/Argentina/Tucuman', offset: 'UTC-3', dst: false },
  { name: 'America/Argentina/Ushuaia', offset: 'UTC-3', dst: false },
  { name: 'America/Aruba', offset: 'UTC-4', dst: false },
  { name: 'America/Asuncion', offset: 'UTC-4', dst: true },
  { name: 'America/Atikokan', offset: 'UTC-5', dst: false },
  { name: 'America/Bahia', offset: 'UTC-3', dst: true },
  { name: 'America/Bahia_Banderas', offset: 'UTC-6', dst: true },
  { name: 'America/Barbados', offset: 'UTC-4', dst: false },
  { name: 'America/Belem', offset: 'UTC-3', dst: false },
  { name: 'America/Belize', offset: 'UTC-6', dst: false },
  { name: 'America/Blanc-Sablon', offset: 'UTC-4', dst: false },
  { name: 'America/Boa_Vista', offset: 'UTC-4', dst: false },
  { name: 'America/Bogota', offset: 'UTC-5', dst: false },
  { name: 'America/Boise', offset: 'UTC-7', dst: true },
  { name: 'America/Cambridge_Bay', offset: 'UTC-7', dst: true },
  { name: 'America/Campo_Grande', offset: 'UTC-4', dst: true },
  { name: 'America/Cancun', offset: 'UTC-5', dst: true },
  { name: 'America/Caracas', offset: 'UTC-4', dst: false },
  { name: 'America/Cayenne', offset: 'UTC-3', dst: false },
  { name: 'America/Cayman', offset: 'UTC-5', dst: false },
  { name: 'America/Chicago', offset: 'UTC-6', dst: true },
  { name: 'America/Chihuahua', offset: 'UTC-7', dst: true },
  { name: 'America/Costa_Rica', offset: 'UTC-6', dst: false },
  { name: 'America/Creston', offset: 'UTC-7', dst: false },
  { name: 'America/Cuiaba', offset: 'UTC-4', dst: true },
  { name: 'America/Curacao', offset: 'UTC-4', dst: false },
  { name: 'America/Danmarkshavn', offset: 'UTC+0', dst: false },
  { name: 'America/Dawson', offset: 'UTC-8', dst: true },
  { name: 'America/Dawson_Creek', offset: 'UTC-7', dst: false },
  { name: 'America/Denver', offset: 'UTC-6', dst: true },
  { name: 'America/Detroit', offset: 'UTC-4', dst: true },
  { name: 'America/Dominica', offset: 'UTC-4', dst: false },
  { name: 'America/Edmonton', offset: 'UTC-6', dst: false },
  { name: 'America/Eirunepe', offset: 'UTC-5', dst: false },
  { name: 'America/El_Salvador', offset: 'UTC-6', dst: false },
  { name: 'America/Fort_Nelson', offset: 'UTC-7', dst: false },
  { name: 'America/Fortaleza', offset: 'UTC-3', dst: true },
  { name: 'America/Glace_Bay', offset: 'UTC-3', dst: true },
  { name: 'America/Godthab', offset: 'UTC-2', dst: true },
  { name: 'America/Goose_Bay', offset: 'UTC-3', dst: true },
  { name: 'America/Grand_Turk', offset: 'UTC-4', dst: true },
  { name: 'America/Grenada', offset: 'UTC-4', dst: false },
  { name: 'America/Guadeloupe', offset: 'UTC-4', dst: false },
  { name: 'America/Guatemala', offset: 'UTC-6', dst: false },
  { name: 'America/Guayaquil', offset: 'UTC-5', dst: false },
  { name: 'America/Guyana', offset: 'UTC-4', dst: false },
  { name: 'America/Halifax', offset: 'UTC-3', dst: true },
  { name: 'America/Havana', offset: 'UTC-4', dst: true },
  { name: 'America/Hermosillo', offset: 'UTC-7', dst: false },
  { name: 'America/Indiana/Indianapolis', offset: 'UTC-4', dst: true },
  { name: 'America/Indiana/Knox', offset: 'UTC-5', dst: true },
  { name: 'America/Indiana/Marengo', offset: 'UTC-4', dst: true },
  { name: 'America/Indiana/Petersburg', offset: 'UTC-4', dst: true },
  { name: 'America/Indiana/Tell_City', offset: 'UTC-5', dst: true },
  { name: 'America/Indiana/Vevay', offset: 'UTC-4', dst: true },
  { name: 'America/Indiana/Vincennes', offset: 'UTC-4', dst: true },
  { name: 'America/Indiana/Winamac', offset: 'UTC-4', dst: true },
  { name: 'America/Inuvik', offset: 'UTC-6', dst: true },
  { name: 'America/Iqaluit', offset: 'UTC-4', dst: true },
  { name: 'America/Jamaica', offset: 'UTC-5', dst: false },
  { name: 'America/Juneau', offset: 'UTC-8', dst: true },
  { name: 'America/Kentucky/Louisville', offset: 'UTC-4', dst: true },
  { name: 'America/Kentucky/Monticello', offset: 'UTC-4', dst: true },
  { name: 'America/Kralendijk', offset: 'UTC-4', dst: false },
  { name: 'America/La_Paz', offset: 'UTC-4', dst: false },
  { name: 'America/Lima', offset: 'UTC-5', dst: false },
  { name: 'America/Los_Angeles', offset: 'UTC-7', dst: true },
  { name: 'America/Lower_Princes', offset: 'UTC-4', dst: false },
  { name: 'America/Maceio', offset: 'UTC-3', dst: true },
  { name: 'America/Managua', offset: 'UTC-6', dst: false },
  { name: 'America/Manaus', offset: 'UTC-4', dst: true },
  { name: 'America/Marigot', offset: 'UTC-4', dst: false },
  { name: 'America/Martinique', offset: 'UTC-4', dst: false },
  { name: 'America/Matamoros', offset: 'UTC-5', dst: true },
  { name: 'America/Mazatlan', offset: 'UTC-6', dst: true },
  { name: 'America/Menominee', offset: 'UTC-5', dst: true },
  { name: 'America/Merida', offset: 'UTC-5', dst: true },
  { name: 'America/Metlakatla', offset: 'UTC-8', dst: true },
  { name: 'America/Mexico_City', offset: 'UTC-5', dst: true },
  { name: 'America/Miquelon', offset: 'UTC-2', dst: true },
  { name: 'America/Moncton', offset: 'UTC-3', dst: true },
  { name: 'America/Monterrey', offset: 'UTC-5', dst: true },
  { name: 'America/Montevideo', offset: 'UTC-3', dst: true },
  { name: 'America/Montserrat', offset: 'UTC-4', dst: false },
  { name: 'America/Nassau', offset: 'UTC-4', dst: true },
  { name: 'America/New_York', offset: 'UTC-4', dst: true },
  { name: 'America/Nipigon', offset: 'UTC-4', dst: true },
  { name: 'America/Nome', offset: 'UTC-8', dst: true },
  { name: 'America/Noronha', offset: 'UTC-2', dst: true },
  { name: 'America/North_Dakota/Beulah', offset: 'UTC-5', dst: true },
  { name: 'America/North_Dakota/Center', offset: 'UTC-5', dst: true },
  { name: 'America/North_Dakota/New_Salem', offset: 'UTC-5', dst: true },
  { name: 'America/Nunak', offset: 'UTC-6', dst: true },
  { name: 'America/Ojinaga', offset: 'UTC-6', dst: true },
  { name: 'America/Panama', offset: 'UTC-5', dst: false },
  { name: 'America/Pangnirtung', offset: 'UTC-4', dst: true },
  { name: 'America/Paramaribo', offset: 'UTC-3', dst: false },
  { name: 'America/Phoenix', offset: 'UTC-7', dst: false },
  { name: 'America/Port-au-Prince', offset: 'UTC-4', dst: false },
  { name: 'America/Port_of_Spain', offset: 'UTC-4', dst: false },
  { name: 'America/Porto_Velho', offset: 'UTC-4', dst: false },
  { name: 'America/Puerto_Rico', offset: 'UTC-4', dst: false },
  { name: 'America/Punta_Arenas', offset: 'UTC-3', dst: true },
  { name: 'America/Rainy_River', offset: 'UTC-5', dst: true },
  { name: 'America/Rankin_Inlet', offset: 'UTC-5', dst: true },
  { name: 'America/Recife', offset: 'UTC-3', dst: true },
  { name: 'America/Regina', offset: 'UTC-6', dst: false },
  { name: 'America/Resolute', offset: 'UTC-6', dst: true },
  { name: 'America/Rio_Branco', offset: 'UTC-5', dst: false },
  { name: 'America/Santarem', offset: 'UTC-3', dst: false },
  { name: 'America/Santiago', offset: 'UTC-3', dst: true },
  { name: 'America/Santo_Domingo', offset: 'UTC-4', dst: false },
  { name: 'America/Sao_Paulo', offset: 'UTC-3', dst: true },
  { name: 'America/Scoresbysund', offset: 'UTC-1', dst: true },
  { name: 'America/Sitka', offset: 'UTC-8', dst: true },
  { name: 'America/St_Barthelemy', offset: 'UTC-4', dst: false },
  { name: 'America/St_Johns', offset: 'UTC-2:30', dst: true },
  { name: 'America/St_Kitts', offset: 'UTC-4', dst: false },
  { name: 'America/St_Lucia', offset: 'UTC-4', dst: false },
  { name: 'America/St_Thomas', offset: 'UTC-4', dst: false },
  { name: 'America/St_Vincent', offset: 'UTC-4', dst: false },
  { name: 'America/Swift_Current', offset: 'UTC-6', dst: false },
  { name: 'America/Tegucigalpa', offset: 'UTC-6', dst: false },
  { name: 'America/Thule', offset: 'UTC-3', dst: true },
  { name: 'America/Thunder_Bay', offset: 'UTC-4', dst: true },
  { name: 'America/Tijuana', offset: 'UTC-7', dst: true },
  { name: 'America/Toronto', offset: 'UTC-4', dst: true },
  { name: 'America/Tortola', offset: 'UTC-4', dst: false },
  { name: 'America/Vancouver', offset: 'UTC-7', dst: true },
  { name: 'America/Whitehorse', offset: 'UTC-7', dst: true },
  { name: 'America/Winnipeg', offset: 'UTC-5', dst: true },
  { name: 'America/Yakutat', offset: 'UTC-8', dst: true },
  { name: 'America/Yellowknife', offset: 'UTC-6', dst: true },
  { name: 'Antarctica/Casey', offset: 'UTC+8', dst: false },
  { name: 'Antarctica/Davis', offset: 'UTC+7', dst: false },
  { name: 'Antarctica/DumontDUrville', offset: 'UTC+10', dst: false },
  { name: 'Antarctica/Macquarie', offset: 'UTC+11', dst: false },
  { name: 'Antarctica/Mawson', offset: 'UTC+5', dst: false },
  { name: 'Antarctica/McMurdo', offset: 'UTC+12', dst: true },
  { name: 'Antarctica/Palmer', offset: 'UTC-3', dst: true },
  { name: 'Antarctica/Rothera', offset: 'UTC-3', dst: false },
  { name: 'Antarctica/Syowa', offset: 'UTC+3', dst: false },
  { name: 'Antarctica/Troll', offset: 'UTC+2', dst: true },
  { name: 'Antarctica/Vostok', offset: 'UTC+6', dst: false },
  { name: 'Arctic/Longyearbyen', offset: 'UTC+2', dst: true },
  { name: 'Asia/Aden', offset: 'UTC+3', dst: false },
  { name: 'Asia/Almaty', offset: 'UTC+6', dst: false },
  { name: 'Asia/Amman', offset: 'UTC+3', dst: true },
  { name: 'Asia/Anadyr', offset: 'UTC+12', dst: true },
  { name: 'Asia/Aqtau', offset: 'UTC+5', dst: false },
  { name: 'Asia/Aqtobe', offset: 'UTC+5', dst: false },
  { name: 'Asia/Ashgabat', offset: 'UTC+5', dst: false },
  { name: 'Asia/Atyrau', offset: 'UTC+5', dst: false },
  { name: 'Asia/Baghdad', offset: 'UTC+3', dst: false },
  { name: 'Asia/Bahrain', offset: 'UTC+3', dst: false },
  { name: 'Asia/Baku', offset: 'UTC+4', dst: false },
  { name: 'Asia/Bangkok', offset: 'UTC+7', dst: false },
  { name: 'Asia/Barnaul', offset: 'UTC+7', dst: false },
  { name: 'Asia/Beirut', offset: 'UTC+3', dst: true },
  { name: 'Asia/Bishkek', offset: 'UTC+6', dst: false },
  { name: 'Asia/Brunei', offset: 'UTC+8', dst: false },
  { name: 'Asia/Chita', offset: 'UTC+9', dst: false },
  { name: 'Asia/Choibalsan', offset: 'UTC+8', dst: false },
  { name: 'Asia/Colombo', offset: 'UTC+5:30', dst: false },
  { name: 'Asia/Damascus', offset: 'UTC+3', dst: true },
  { name: 'Asia/Dhaka', offset: 'UTC+6', dst: false },
  { name: 'Asia/Dili', offset: 'UTC+9', dst: false },
  { name: 'Asia/Dubai', offset: 'UTC+4', dst: false },
  { name: 'Asia/Dushanbe', offset: 'UTC+5', dst: false },
  { name: 'Asia/Famagusta', offset: 'UTC+3', dst: true },
  { name: 'Asia/Gaza', offset: 'UTC+3', dst: true },
  { name: 'Asia/Hebron', offset: 'UTC+3', dst: true },
  { name: 'Asia/Ho_Chi_Minh', offset: 'UTC+7', dst: false },
  { name: 'Asia/Hong_Kong', offset: 'UTC+8', dst: false },
  { name: 'Asia/Hovd', offset: 'UTC+7', dst: false },
  { name: 'Asia/Irkutsk', offset: 'UTC+8', dst: false },
  { name: 'Asia/Jakarta', offset: 'UTC+7', dst: false },
  { name: 'Asia/Jayapura', offset: 'UTC+9', dst: false },
  { name: 'Asia/Jerusalem', offset: 'UTC+3', dst: true },
  { name: 'Asia/Kabul', offset: 'UTC+4:30', dst: false },
  { name: 'Asia/Kamchatka', offset: 'UTC+12', dst: true },
  { name: 'Asia/Karachi', offset: 'UTC+5', dst: false },
  { name: 'Asia/Kathmandu', offset: 'UTC+5:45', dst: false },
  { name: 'Asia/Khandyga', offset: 'UTC+9', dst: false },
  { name: 'Asia/Kolkata', offset: 'UTC+5:30', dst: false },
  { name: 'Asia/Krasnoyarsk', offset: 'UTC+7', dst: false },
  { name: 'Asia/Kuala_Lumpur', offset: 'UTC+8', dst: false },
  { name: 'Asia/Kuching', offset: 'UTC+8', dst: false },
  { name: 'Asia/Kuwait', offset: 'UTC+3', dst: false },
  { name: 'Asia/Macau', offset: 'UTC+8', dst: false },
  { name: 'Asia/Magadan', offset: 'UTC+11', dst: true },
  { name: 'Asia/Makassar', offset: 'UTC+8', dst: false },
  { name: 'Asia/Manila', offset: 'UTC+8', dst: false },
  { name: 'Asia/Muscat', offset: 'UTC+4', dst: false },
  { name: 'Asia/Nicosia', offset: 'UTC+3', dst: true },
  { name: 'Asia/Novokuznetsk', offset: 'UTC+7', dst: false },
  { name: 'Asia/Novosibirsk', offset: 'UTC+7', dst: false },
  { name: 'Asia/Omsk', offset: 'UTC+6', dst: false },
  { name: 'Asia/Oral', offset: 'UTC+5', dst: false },
  { name: 'Asia/Phnom_Penh', offset: 'UTC+7', dst: false },
  { name: 'Asia/Pontianak', offset: 'UTC+7', dst: false },
  { name: 'Asia/Pyongyang', offset: 'UTC+8:30', dst: false },
  { name: 'Asia/Qatar', offset: 'UTC+3', dst: false },
  { name: 'Asia/Qostanay', offset: 'UTC+6', dst: false },
  { name: 'Asia/Qyzylorda', offset: 'UTC+6', dst: false },
  { name: 'Asia/Riyadh', offset: 'UTC+3', dst: false },
  { name: 'Asia/Sakhalin', offset: 'UTC+11', dst: false },
  { name: 'Asia/Samarkand', offset: 'UTC+5', dst: false },
  { name: 'Asia/Seoul', offset: 'UTC+9', dst: false },
  { name: 'Asia/Shanghai', offset: 'UTC+8', dst: false },
  { name: 'Asia/Singapore', offset: 'UTC+8', dst: false },
  { name: 'Asia/Srednekolymsk', offset: 'UTC+11', dst: false },
  { name: 'Asia/Taipei', offset: 'UTC+8', dst: false },
  { name: 'Asia/Tashkent', offset: 'UTC+5', dst: false },
  { name: 'Asia/Tbilisi', offset: 'UTC+4', dst: false },
  { name: 'Asia/Tehran', offset: 'UTC+4:30', dst: true },
  { name: 'Asia/Thimphu', offset: 'UTC+6', dst: false },
  { name: 'Asia/Tokyo', offset: 'UTC+9', dst: false },
  { name: 'Asia/Tomsk', offset: 'UTC+7', dst: false },
  { name: 'Asia/Ulaanbaatar', offset: 'UTC+8', dst: false },
  { name: 'Asia/Urumqi', offset: 'UTC+6', dst: false },
  { name: 'Asia/Ust-Nera', offset: 'UTC+10', dst: false },
  { name: 'Asia/Vientiane', offset: 'UTC+7', dst: false },
  { name: 'Asia/Vladivostok', offset: 'UTC+10', dst: false },
  { name: 'Asia/Yakutsk', offset: 'UTC+9', dst: false },
  { name: 'Asia/Yangon', offset: 'UTC+6:30', dst: false },
  { name: 'Asia/Yekaterinburg', offset: 'UTC+5', dst: false },
  { name: 'Asia/Yerevan', offset: 'UTC+4', dst: false },
  { name: 'Atlantic/Azores', offset: 'UTC-1', dst: true },
  { name: 'Atlantic/Bermuda', offset: 'UTC-3', dst: true },
  { name: 'Atlantic/Canary', offset: 'UTC+1', dst: true },
  { name: 'Atlantic/Cape_Verde', offset: 'UTC-1', dst: false },
  { name: 'Atlantic/Faroe', offset: 'UTC+0', dst: false },
  { name: 'Atlantic/Madeira', offset: 'UTC+1', dst: true },
  { name: 'Atlantic/Reykjavik', offset: 'UTC+0', dst: false },
  { name: 'Atlantic/South_Georgia', offset: 'UTC-2', dst: false },
  { name: 'Atlantic/St_Helena', offset: 'UTC+0', dst: false },
  { name: 'Atlantic/Stanley', offset: 'UTC-3', dst: true },
  { name: 'Australia/Adelaide', offset: 'UTC+9:30', dst: true },
  { name: 'Australia/Brisbane', offset: 'UTC+10', dst: false },
  { name: 'Australia/Broken_Hill', offset: 'UTC+9:30', dst: true },
  { name: 'Australia/Currie', offset: 'UTC+10', dst: true },
  { name: 'Australia/Darwin', offset: 'UTC+9:30', dst: false },
  { name: 'Australia/Eucla', offset: 'UTC+8:45', dst: false },
  { name: 'Australia/Hobart', offset: 'UTC+10', dst: true },
  { name: 'Australia/Lindeman', offset: 'UTC+10', dst: false },
  { name: 'Australia/Lord_Howe', offset: 'UTC+10:30', dst: true },
  { name: 'Australia/Melbourne', offset: 'UTC+10', dst: true },
  { name: 'Australia/Perth', offset: 'UTC+8', dst: false },
  { name: 'Australia/Sydney', offset: 'UTC+10', dst: true },
  { name: 'Europe/Amsterdam', offset: 'UTC+2', dst: true },
  { name: 'Europe/Andorra', offset: 'UTC+2', dst: true },
  { name: 'Europe/Astrakhan', offset: 'UTC+4', dst: false },
  { name: 'Europe/Athens', offset: 'UTC+3', dst: true },
  { name: 'Europe/Belgrade', offset: 'UTC+2', dst: true },
  { name: 'Europe/Berlin', offset: 'UTC+2', dst: true },
  { name: 'Europe/Bratislava', offset: 'UTC+2', dst: true },
  { name: 'Europe/Brussels', offset: 'UTC+2', dst: true },
  { name: 'Europe/Bucharest', offset: 'UTC+3', dst: true },
  { name: 'Europe/Budapest', offset: 'UTC+2', dst: true },
  { name: 'Europe/Busingen', offset: 'UTC+2', dst: true },
  { name: 'Europe/Chisinau', offset: 'UTC+3', dst: true },
  { name: 'Europe/Copenhagen', offset: 'UTC+2', dst: true },
  { name: 'Europe/Dublin', offset: 'UTC+1', dst: true },
  { name: 'Europe/Gibraltar', offset: 'UTC+2', dst: true },
  { name: 'Europe/Guernsey', offset: 'UTC+1', dst: true },
  { name: 'Europe/Helsinki', offset: 'UTC+3', dst: true },
  { name: 'Europe/Isle_of_Man', offset: 'UTC+1', dst: true },
  { name: 'Europe/Istanbul', offset: 'UTC+3', dst: true },
  { name: 'Europe/Jersey', offset: 'UTC+1', dst: true },
  { name: 'Europe/Kaliningrad', offset: 'UTC+2', dst: false },
  { name: 'Europe/Kiev', offset: 'UTC+3', dst: true },
  { name: 'Europe/Kirov', offset: 'UTC+3', dst: false },
  { name: 'Europe/Lisbon', offset: 'UTC+1', dst: true },
  { name: 'Europe/Ljubljana', offset: 'UTC+2', dst: true },
  { name: 'Europe/London', offset: 'UTC+1', dst: true },
  { name: 'Europe/Luxembourg', offset: 'UTC+2', dst: true },
  { name: 'Europe/Madrid', offset: 'UTC+2', dst: true },
  { name: 'Europe/Malta', offset: 'UTC+2', dst: true },
  { name: 'Europe/Mariehamn', offset: 'UTC+3', dst: true },
  { name: 'Europe/Minsk', offset: 'UTC+3', dst: false },
  { name: 'Europe/Monaco', offset: 'UTC+2', dst: true },
  { name: 'Europe/Moscow', offset: 'UTC+3', dst: false },
  { name: 'Europe/Oslo', offset: 'UTC+2', dst: true },
  { name: 'Europe/Paris', offset: 'UTC+2', dst: true },
  { name: 'Europe/Podgorica', offset: 'UTC+2', dst: true },
  { name: 'Europe/Prague', offset: 'UTC+2', dst: true },
  { name: 'Europe/Riga', offset: 'UTC+3', dst: true },
  { name: 'Europe/Rome', offset: 'UTC+2', dst: true },
  { name: 'Europe/Samara', offset: 'UTC+4', dst: false },
  { name: 'Europe/San_Marino', offset: 'UTC+2', dst: true },
  { name: 'Europe/Sarajevo', offset: 'UTC+2', dst: true },
  { name: 'Europe/Saratov', offset: 'UTC+4', dst: false },
  { name: 'Europe/Simferopol', offset: 'UTC+3', dst: true },
  { name: 'Europe/Skopje', offset: 'UTC+2', dst: true },
  { name: 'Europe/Sofia', offset: 'UTC+3', dst: true },
  { name: 'Europe/Stockholm', offset: 'UTC+2', dst: true },
  { name: 'Europe/Tallinn', offset: 'UTC+3', dst: true },
  { name: 'Europe/Tirane', offset: 'UTC+2', dst: true },
  { name: 'Europe/Ulyanovsk', offset: 'UTC+4', dst: false },
  { name: 'Europe/Uzhgorod', offset: 'UTC+3', dst: true },
  { name: 'Europe/Vaduz', offset: 'UTC+2', dst: true },
  { name: 'Europe/Vatican', offset: 'UTC+2', dst: true },
  { name: 'Europe/Vienna', offset: 'UTC+2', dst: true },
  { name: 'Europe/Vilnius', offset: 'UTC+3', dst: true },
  { name: 'Europe/Volgograd', offset: 'UTC+3', dst: false },
  { name: 'Europe/Warsaw', offset: 'UTC+2', dst: true },
  { name: 'Europe/Zagreb', offset: 'UTC+2', dst: true },
  { name: 'Europe/Zaporozhye', offset: 'UTC+3', dst: true },
  { name: 'Europe/Zurich', offset: 'UTC+2', dst: true },
  { name: 'Indian/Antananarivo', offset: 'UTC+3', dst: false },
  { name: 'Indian/Chagos', offset: 'UTC+6', dst: false },
  { name: 'Indian/Christmas', offset: 'UTC+7', dst: false },
  { name: 'Indian/Cocos', offset: 'UTC+6:30', dst: false },
  { name: 'Indian/Comoro', offset: 'UTC+3', dst: false },
  { name: 'Indian/Kerguelen', offset: 'UTC+5', dst: false },
  { name: 'Indian/Mahe', offset: 'UTC+4', dst: false },
  { name: 'Indian/Maldives', offset: 'UTC+5', dst: false },
  { name: 'Indian/Mauritius', offset: 'UTC+4', dst: true },
  { name: 'Indian/Mayotte', offset: 'UTC+3', dst: false },
  { name: 'Indian/Reunion', offset: 'UTC+4', dst: false },
  { name: 'Pacific/Apia', offset: 'UTC+13', dst: true },
  { name: 'Pacific/Auckland', offset: 'UTC+12', dst: true },
  { name: 'Pacific/Bougainville', offset: 'UTC+11', dst: false },
  { name: 'Pacific/Chatham', offset: 'UTC+12:45', dst: true },
  { name: 'Pacific/Chuuk', offset: 'UTC+10', dst: false },
  { name: 'Pacific/Easter', offset: 'UTC-5', dst: true },
  { name: 'Pacific/Efate', offset: 'UTC+11', dst: false },
  { name: 'Pacific/Enderbury', offset: 'UTC+13', dst: false },
  { name: 'Pacific/Fakaofo', offset: 'UTC+13', dst: false },
  { name: 'Pacific/Fiji', offset: 'UTC+12', dst: true },
  { name: 'Pacific/Funafuti', offset: 'UTC+12', dst: false },
  { name: 'Pacific/Galapagos', offset: 'UTC-6', dst: false },
  { name: 'Pacific/Gambier', offset: 'UTC-9', dst: false },
  { name: 'Pacific/Guadalcanal', offset: 'UTC+11', dst: false },
  { name: 'Pacific/Guam', offset: 'UTC+10', dst: false },
  { name: 'Pacific/Honolulu', offset: 'UTC-10', dst: false },
  { name: 'Pacific/Kiritimati', offset: 'UTC+14', dst: false },
  { name: 'Pacific/Kosrae', offset: 'UTC+11', dst: false },
  { name: 'Pacific/Kwajalein', offset: 'UTC+12', dst: false },
  { name: 'Pacific/Majuro', offset: 'UTC+12', dst: false },
  { name: 'Pacific/Marquesas', offset: 'UTC-9:30', dst: false },
  { name: 'Pacific/Midway', offset: 'UTC-11', dst: false },
  { name: 'Pacific/Nauru', offset: 'UTC+12', dst: false },
  { name: 'Pacific/Niue', offset: 'UTC-11', dst: false },
  { name: 'Pacific/Norfolk', offset: 'UTC+11', dst: false },
  { name: 'Pacific/Noumea', offset: 'UTC+11', dst: false },
  { name: 'Pacific/Pago_Pago', offset: 'UTC-11', dst: false },
  { name: 'Pacific/Palau', offset: 'UTC+9', dst: false },
  { name: 'Pacific/Pitcairn', offset: 'UTC-8', dst: false },
  { name: 'Pacific/Pohnpei', offset: 'UTC+11', dst: false },
  { name: 'Pacific/Port_Moresby', offset: 'UTC+10', dst: false },
  { name: 'Pacific/Rarotonga', offset: 'UTC-10', dst: false },
  { name: 'Pacific/Saipan', offset: 'UTC+10', dst: false },
  { name: 'Pacific/Tahiti', offset: 'UTC-10', dst: false },
  { name: 'Pacific/Tarawa', offset: 'UTC+12', dst: false },
  { name: 'Pacific/Tongatapu', offset: 'UTC+13', dst: false },
  { name: 'Pacific/Wake', offset: 'UTC+12', dst: false },
  { name: 'Pacific/Wallis', offset: 'UTC+12', dst: false },
];

const createTimeZoneComboBoxOptions = () => {
  const options: ComboBoxOption[] = [];
  timezones.forEach((tz) => {
    const now = DateTime.now().setZone(tz.name);
    // Get the time zone abbreviation
    const abbreviation = now.toFormat('ZZZZ');
    options.push({
      label: tz.name.replace(/_/g, ' ') + ' (' + abbreviation + ')',
      value: tz.name,
    });
  });
  return options;
};

export const TimeZoneComboBoxOptions: ComboBoxOption[] =
  createTimeZoneComboBoxOptions();
