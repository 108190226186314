import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as LayoutSelectors from './client-layout.selectors';

@Injectable()
export class ClientLayoutFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */

  breadcrumbTrail$ = this.store.pipe(
    select(LayoutSelectors.getGlobalBreadcrumbTrail)
  );
  user$ = this.store.pipe(select(LayoutSelectors.getAuthzUser));
  cartItems$ = this.store.pipe(select(LayoutSelectors.getCartItems));

  constructor(private readonly store: Store) {}
}
