import { BaseEventPayload } from './base';

export enum ChallengeEvent {
  StartedChallenge = 'started_challenge',
  CompletedChallenge = 'completed_challenge',
  AddedChallengeActivity = 'added_challenge_activity',
  EditedChallengeActivity = 'edited_challenge_activity',
  RemovedChallengeActivity = 'removed_challenge_activity',
}

export interface StartedChallenge extends BaseEventPayload {
  payload: {
    challengeId: string;
    numberOfActivities: number;
    challengeProgressId: string;
  };
}

export interface CompletedChallenge extends BaseEventPayload {
  payload: {
    challengeId: string;
    challengeProgressId: string;
  };
}

export interface AddedChallengeActivity extends BaseEventPayload {
  payload: {
    challengeId: string;
    challengeProgressId: string;
    activityId: string;
    when: number;
    sentiments: ChallengeSentiments[];
    numberOfActivities: number;
    percentageCompleted: number;
  };
}

export interface EditedChallengeActivity extends BaseEventPayload {
  payload: {
    challengeId: string;
    challengeProgressId: string;
    activityId: string;
    when: number;
    sentiments: ChallengeSentiments[];
    numberOfActivities: number;
    percentageCompleted: number;
  };
}

export interface RemovedChallengeActivity extends BaseEventPayload {
  payload: {
    challengeId: string;
    challengeProgressId: string;
    activityId: string;
    numberOfActivities: number;
    percentageCompleted: number;
  };
}

export interface ChallengeSentiments {
  id: string;
  offset: number;
}
