import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalFacade } from '../+state/global.facade';
import { NavigationType } from '../constants/';

export interface TrailItem {
  name: string;
  location?: string;
}

export const urlPartToTrailMap = new Map<string, TrailItem>([
  ['auth', { name: 'Authentication', location: '/auth' }],
  ['community', { name: 'Community', location: '/community' }],
  ['people', { name: 'People', location: '/people' }],
  ['customers', { name: 'Customers' }],
  ['integrations', { name: 'Integrations', location: '/integrations' }],
  ['analytics', { name: 'Analytics', location: '/analytics' }],
  ['library', { name: 'Library', location: '/library' }],
  ['pages', { name: 'Pages' }],
  ['spaces', { name: 'Spaces' }],
  ['courses', { name: 'Courses' }],
  ['quickbooks', { name: 'Quickbooks' }],
  ['vimeo', { name: 'Vimeo' }],
  ['stripe', { name: 'Stripe' }],
  ['active-campaign', { name: 'ActiveCampaign' }],
  ['my-activities', { name: 'My Activities' }],
  ['config', { name: 'Config' }],
  ['assets', { name: 'Assets' }],
  ['edit', { name: 'Edit' }],
  ['list', { name: 'All' }],
]);

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router, private globalFacade: GlobalFacade) {
    this.init();
  }

  init() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((ne) => {
        const d = ne as NavigationEnd;
        switch (true) {
          case d.urlAfterRedirects.startsWith('auth'):
          case d.urlAfterRedirects.startsWith('community'):
          case d.urlAfterRedirects.startsWith('posts'):
          case d.urlAfterRedirects.startsWith('my-activities'):
          case d.urlAfterRedirects.startsWith('shop'):
          case d.urlAfterRedirects.startsWith('my-account'):
            this.globalFacade.setHeaderNavigation(NavigationType.Full);
            this.globalFacade.setFooterNavigation(NavigationType.Full);
            break;
          default:
        }
      });
  }
}
