import { Injectable } from '@angular/core';
import { RouterState } from '@angular/router';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { filter, map, tap } from 'rxjs';
import { GlobalFacade } from './global.facade';

@Injectable()
export class GlobalEffects {
  private affiliateCodeReceived = false;
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        filter(() => !this.affiliateCodeReceived), // only run once
        filter((action: RouterNavigationAction) => {
          const routerState = action.payload.routerState;
          return routerState.root.queryParams['a_code'] !== undefined;
        }),
        map(
          (action: RouterNavigationAction) =>
            action.payload.routerState.root.queryParams['a_code']
        ),
        tap((affiliateCode) => {
          this.affiliateCodeReceived = true;
          this.globalFacade.insertAffiliateCodeCookie(affiliateCode);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private globalFacade: GlobalFacade
  ) {}
}
