import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { Integrations } from '../integrations';
import { EntityType } from '@sidkik/global';

export enum StripeAffiliateStatus {
  Started = 'started',
  Completed = 'completed',
}

export enum AffiliateApprovalStatus {
  Approved = 'approved',
  Denied = 'denied',
  Pending = 'pending',
}

export interface AffiliateData {
  email?: string;
  displayName?: string;
  approval?: AffiliateApprovalStatus;
  code: string;
  customerId: string;
  authorizedProducts: string[];
  acceptedTerms: boolean;
  termsVersion: number;
  acceptedTermsAt: number;
  goodStanding: boolean;
  standingReason: string;
  stripeStatus?: StripeAffiliateStatus;
  payoutPercentage?: number;
  recurringPeriodMonthly?: number;
  recurringPeriodYearly?: number;
  revenueGenerated?: number;
  earnedCommission?: number;
  pendingCommission?: number;
  paidCommission?: number;
  productsSold?: number;
}

export interface AffiliateProperties extends StorageProperties {
  data: AffiliateData;
  meta: Meta;
  id: string;
  integrations: Integrations;
}

export class Affiliate extends Storage implements AffiliateProperties {
  public override data!: AffiliateData;
  constructor(options?: AffiliateProperties, user?: string) {
    super(options, user, EntityType.Affiliate);
    this.update(options?.data);
  }

  public update(data?: AffiliateData): void {
    this.data = { ...data } as unknown as AffiliateData;
  }
}
