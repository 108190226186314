import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
  addMapFailureType,
  addMapSuccessType,
  addMapType,
  loadMapsAndSelectMapFailureType,
  loadMapsAndSelectMapSuccessType,
  loadMapsAndSelectMapType,
  loadMapsFailureType,
  loadMapsSuccessType,
  loadMapsType,
  selectMapType,
  updateMapFailureType,
  updateMapSuccessType,
  updateMapType,
  noOperation,
} from '@sidkik/global';

import {
  MapEntry,
  MapProperties,
  PostMap,
  PostMapProperties,
} from '@sidkik/db';

export const noop = createAction(noOperation);

export const loadMaps = createAction(loadMapsType);
export const loadMapsAndSelectMap = createAction(
  loadMapsAndSelectMapType,
  props<{ id: string }>()
);

export const loadMapsSuccess = createAction(
  loadMapsSuccessType,
  props<{ maps: MapProperties[] }>()
);

export const loadMapsAndSelectMapSuccess = createAction(
  loadMapsAndSelectMapSuccessType,
  props<{ maps: MapProperties[]; id: string }>()
);

export const selectMap = createAction(selectMapType, props<{ id: string }>());

export const loadMapsFailure = createAction(
  loadMapsFailureType,
  props<{ error: any }>()
);

export const loadMapsAndSelectMapFailure = createAction(
  loadMapsAndSelectMapFailureType,
  props<{ error: any; id: string }>()
);

export const addMap = createAction(
  addMapType,
  props<{ map: MapProperties; entry: MapEntry }>()
);

export const addMapSuccess = createAction(
  addMapSuccessType,
  props<{ map: MapProperties; entry: MapEntry }>()
);

export const addMapFailure = createAction(
  addMapFailureType,
  props<{ error: any }>()
);

export const updateMap = createAction(
  updateMapType,
  props<{ map: MapProperties; entry: MapEntry }>()
);

export const updateMapSuccess = createAction(
  updateMapSuccessType,
  props<{ map: MapProperties; entry: MapEntry }>()
);

export const updateMapFailure = createAction(
  updateMapFailureType,
  props<{ error: any }>()
);
