import { InjectionToken } from '@angular/core';

export const AUTHZ_CONFIG = new InjectionToken<AuthzConfig>('authz.config');
export const SIDKIK_TENANT = new InjectionToken<string>('sidkik.tenant');
export const SIDKIK_AUTH_TENANT = new InjectionToken<string>(
  'sidkik.auth.tenant'
);
export const SIDKIK_PROJECT = new InjectionToken<string>('sidkik.project');

export interface AuthzConfig {
  thirdPartyProvidersAllowed: boolean;
  authGuardFallbackURL?: string;
  authGuardLoggedInURL?: string;
}

export const AuthzConfigToken = new InjectionToken<AuthzConfig>(
  'AuthzConfigToken'
);
