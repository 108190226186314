import { createAction, props } from '@ngrx/store';
import { addBreadcrumbType } from '../constants/actions/global';
import { TrailItem } from '../services/breadcrumbs.service';
import { NavigationType } from '../constants';

export const addBreadcrumb = createAction(
  addBreadcrumbType,
  props<{ items: TrailItem[] }>()
);

export const setHeaderNavigation = createAction(
  '[global] set header navigation',
  props<{ navigationType: NavigationType }>()
);

export const setFooterNavigation = createAction(
  '[global] set footer navigation',
  props<{ navigationType: NavigationType }>()
);
