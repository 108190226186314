import { Storage } from './storage';

export class StorageFactory {
  static getFactory<T extends Storage>(
    c: new (options: any, user: string) => T,
    options?: any,
    user = ''
  ): T {
    return new c(options, user) as T;
  }
}
