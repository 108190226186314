import { Asset, AssetProperties } from '../library';
import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';

export interface SpacePermissions {
  challenges: string[];
  courses: string[];
  content: string[];
  platforms: string[];
}

export interface SpaceData {
  name: string;
  byline: string;
  active: boolean;
  image?: AssetProperties;
  permissions: SpacePermissions;
  isPublic: boolean;
}

export interface SpaceProperties extends StorageProperties {
  data: SpaceData;
  meta: Meta;
  id: string;
}

export class Space extends Storage implements SpaceProperties {
  public override data!: SpaceData;

  constructor(options?: SpaceProperties, user?: string) {
    super(options, user, EntityType.Space);
    this.update(options?.data);
  }

  public update(data?: SpaceData): void {
    this.data = { ...data } as unknown as SpaceData;
  }
}
