/** Space */
export const loadSpacesType = '[community/space] load spaces';
export const loadSpacesAndSelectSpaceType =
  '[community/space] load spaces and select space';
export const loadSpacesSuccessType = '[community/space] load spaces success';
export const loadSpacesAndSelectSpaceSuccessType =
  '[community/space] load spaces and select space success';
export const loadSpacesFailureType = '[community/space] load spaces failure';
export const loadSpacesAndSelectSpaceFailureType =
  '[community/space] load spaces and select space failure';

export const selectSpaceType = '[community/space] select space';

export const addSpaceType = '[community/space] add space';
export const addSpaceSuccessType = '[community/space] add space success';
export const addSpaceFailureType = '[community/space] add space failure';

export const updateSpaceType = '[community/space] update space';
export const updateSpaceSuccessType = '[community/space] update space success';
export const updateSpaceFailureType = '[community/space] update space failure';

/** Thread */
export const addLocalApplauseForThreadType =
  '[community/thread] add local applause';
export const loadThreadsType = '[community/thread] load threads';
export const loadThreadsAndSelectThreadType =
  '[community/thread] load threads and select thread';
export const loadThreadsSuccessType = '[community/thread] load threads success';
export const loadThreadsAndSelectThreadSuccessType =
  '[community/thread] load threads and select thread success';
export const loadThreadsFailureType = '[community/thread] load threads failure';
export const loadThreadsAndSelectThreadFailureType =
  '[community/thread] load threads and select thread failure';
export const loadCordonedThreadsType =
  '[community/thread] load cordoned threads';
export const loadCordonedThreadsSuccessType =
  '[community/thread] load cordoned threads success';
export const loadCordonedThreadsFailureType =
  '[community/thread] load cordoned threads failure';

export const selectThreadType = '[community/thread] select thread';

export const addThreadType = '[community/thread] add thread';
export const addThreadSuccessType = '[community/thread] add thread success';
export const addThreadFailureType = '[community/thread] add thread failure';

export const updateThreadType = '[community/thread] update thread';
export const updateThreadSuccessType =
  '[community/thread] update thread success';
export const updateThreadFailureType =
  '[community/thread] update thread failure';
export const resetThreadListType = '[community/thread] reset thread list';

/** Draft Thread */
export const loadDraftThreadsType =
  '[community/draft-thread] load draft-threads';
export const loadDraftThreadsAndSelectDraftThreadType =
  '[community/draft-thread] load draft-threads and select draft-thread';
export const loadDraftThreadsSuccessType =
  '[community/draft-thread] load draft-threads success';
export const loadDraftThreadsAndSelectDraftThreadSuccessType =
  '[community/draft-thread] load draft-threads and select draft-thread success';
export const loadDraftThreadsFailureType =
  '[community/draft-thread] load draft-threads failure';
export const loadDraftThreadsAndSelectDraftThreadFailureType =
  '[community/draft-thread] load draft-threads and select draft-thread failure';

export const selectDraftThreadType =
  '[community/draft-thread] select draft-thread';

export const addDraftThreadType = '[community/draft-thread] add draft-thread';
export const addDraftThreadSuccessType =
  '[community/draft-thread] add draft-thread success';
export const addDraftThreadFailureType =
  '[community/draft-thread] add draft-thread failure';

export const updateDraftThreadType =
  '[community/draft-thread] update draft-thread';
export const updateLocalDraftThreadType =
  '[community/draft-thread] update local draft-thread';
export const updateDraftThreadSuccessType =
  '[community/draft-thread] update draft-thread success';
export const updateDraftThreadFailureType =
  '[community/draft-thread] update draft-thread failure';
export const resetDraftThreadListType =
  '[community/draft-thread] reset draft-thread list';
export const publishRequestedType =
  '[community/draft-thread] publish requested';
export const deleteDraftThreadType =
  '[community/draft-thread] delete draft-thread';
export const deleteDraftThreadSuccessType =
  '[community/draft-thread] delete draft-thread success';
export const deleteDraftThreadFailureType =
  '[community/draft-thread] delete draft-thread failure';

/** Comment */
export const addLocalApplauseForCommentType =
  '[community/comment] add local applause';
export const loadCommentsType = '[community/comment] load comments';
export const loadCommentsAndSelectCommentType =
  '[community/comment] load comments and select comment';
export const loadCommentsSuccessType =
  '[community/comment] load comments success';
export const loadCommentsAndSelectCommentSuccessType =
  '[community/comment] load comments and select comment success';
export const loadCommentsFailureType =
  '[community/comment] load comments failure';
export const loadCommentsAndSelectCommentFailureType =
  '[community/comment] load comments and select comment failure';
export const loadCordonedCommentsType =
  '[community/comment] load cordoned comments';
export const loadCordonedCommentsSuccessType =
  '[community/comment] load cordoned comments success';
export const loadCordonedCommentsFailureType =
  '[community/comment] load cordoned comments failure';

export const selectCommentType = '[community/comment] select comment';

export const addCommentType = '[community/comment] add comment';
export const addCommentSuccessType = '[community/comment] add comment success';
export const addCommentFailureType = '[community/comment] add comment failure';

export const deleteCommentType = '[community/comment] delete comment';

export const updateCommentType = '[community/comment] update comment';
export const updateCommentSuccessType =
  '[community/comment] update comment success';
export const updateCommentFailureType =
  '[community/comment] update comment failure';
export const resetCommentListType = '[community/comment] reset comment list';

/** UserAsset */

export const loadUserAssetsType = '[community/asset] load assets';
export const loadUserAssetsSuccessType =
  '[community/asset] load assets success';
export const loadUserAssetsFailureType =
  '[community/asset] load assets failure';

export const reloadUserAssetType = '[community/asset] reload asset';
export const reloadUserAssetSuccessType =
  '[community/asset] reload asset success';
export const reloadUserAssetFailureType =
  '[community/asset] reload asset failure';

export const addUserAssetType = '[community/asset] add asset';
export const addUserAssetSuccessType = '[community/asset] add asset success';
export const addUserAssetFailureType = '[community/asset] add asset failure';

export const updateUserAssetType = '[community/asset] update asset';
export const updateUserAssetProgressType =
  '[community/asset] update asset progress';
export const updateUserAssetSuccessType =
  '[community/asset] update asset success';
export const updateUserAssetFailureType =
  '[community/asset] update asset failure';

export const deleteUserAssetType = '[community/asset] delete asset';
export const deleteUserAssetSuccessType =
  '[community/asset] delete asset success';
export const deleteUserAssetFailureType =
  '[community/asset] delete asset failure';
