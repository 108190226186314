import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { Integrations } from '../integrations';
import { AffiliatePayoutType, EntityType } from '@sidkik/global';

export enum AffiliatePayoutStatus {
  Pending = 'pending',
  Paid = 'paid',
  Processing = 'processing',
}

export interface AffiliatePayoutData {
  amount: number;
  affiliateId: string;
  status: AffiliatePayoutStatus;
  affiliateSaleId: string;
  payoutType?: AffiliatePayoutType;
}

export interface AffiliatePayoutProperties extends StorageProperties {
  data: AffiliatePayoutData;
  meta: Meta;
  id: string;
  integrations: Integrations;
}

export class AffiliatePayout
  extends Storage
  implements AffiliatePayoutProperties
{
  public override data!: AffiliatePayoutData;
  constructor(options?: AffiliatePayoutProperties, user?: string) {
    super(options, user, EntityType.Affiliate);
    this.update(options?.data);
  }

  public update(data?: AffiliatePayoutData): void {
    this.data = { ...data } as unknown as AffiliatePayoutData;
  }
}
